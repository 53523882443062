import {
  Text,
  TextKind,
  TextElement,
  TableBodyCell,
  TableRow,
  Tooltip,
  OpenAxis,
  ButtonKind,
  IconName,
  ButtonIconPosition,
  IconDirection,
  Button,
  Pill,
  PillColor,
} from 'design-system/components';
import { PolicyRequirementType, ScreeningStatus } from 'design-system/data';

import ScreeningStatusPill from 'components/ScreeningStatusPill';
import { Requirement } from '../data/types';
import styles from '../sku-requirements.module.scss';
import { createRef, useEffect } from 'react';

const SKURequirementsRow = ({
  requirement,
  onClick,
  modalOpen,
  isActive,
}: {
  requirement: Requirement;
  onClick: () => void;
  modalOpen: boolean;
  isActive: boolean;
}) => {
  const buttonRef = createRef<HTMLButtonElement>();
  const isAttestable =
    requirement.requirementType !== PolicyRequirementType.Brand &&
    (requirement.screeningStatus === ScreeningStatus.NeedsAttention ||
      requirement.screeningStatus === ScreeningStatus.NeedsInformation);

  const ctaText = isAttestable ? 'Attest' : 'Review';

  useEffect(() => {
    if (isActive && !modalOpen) {
      buttonRef?.current?.focus();
    } else {
      buttonRef?.current?.blur();
    }
  }, [buttonRef, isActive, modalOpen]);

  return (
    <TableRow onClick={onClick} className={isActive ? styles.active : ''}>
      <TableBodyCell>
        <Tooltip
          openAxis={OpenAxis.Y}
          triggerElement={
            <Text kind={TextKind.TextSMMedium} element={TextElement.P}>
              {requirement.name}
            </Text>
          }
        >
          {requirement.name}
        </Tooltip>
      </TableBodyCell>
      <TableBodyCell>
        <Tooltip
          openAxis={OpenAxis.Y}
          triggerElement={
            <Pill color={PillColor.Default}>
              {requirement.retailerPolicyRequirementGroupName}
            </Pill>
          }
        >
          {requirement.retailerPolicyRequirementGroupName}
        </Tooltip>
      </TableBodyCell>
      <TableBodyCell>
        <ScreeningStatusPill withDot status={requirement.screeningStatus} />
      </TableBodyCell>
      <TableBodyCell>
        <div className={styles['cta-container']}>
          <Button
            kind={isAttestable ? ButtonKind.Alternate : ButtonKind.Tertiary}
            iconName={IconName.ArrowV2}
            iconPosition={ButtonIconPosition.Right}
            iconDirection={IconDirection.Right}
            onClick={onClick}
            refProp={buttonRef}
          >
            {ctaText}
          </Button>
        </div>
      </TableBodyCell>
    </TableRow>
  );
};
export default SKURequirementsRow;
