import {
  BrandViewHeader,
  PhosphorIconWeight,
  PhosphorIcon,
  Text,
  TextKind,
  TextElement,
  DropdownRadioMenu,
} from 'design-system/components';
import { useHistory } from 'react-router-dom';
import { UrlParam, Color } from 'design-system/data';
import { PATHS } from 'constants/index';
import { FormInput } from 'views/FDARegistration/shared/components/Form';
import { useStatefulQueryParams } from 'design-system/utils';

import styles from './header-styles.module.scss';

interface HeaderProps {
  policyName: string;
}

const SORT_OPTIONS = {
  'product_name__asc': 'SKU name (A to Z)',
  'product_name__desc': 'SKU name (Z to A)',
  'sku_id__asc': 'SKU ID (ascending)',
  'sku_id__desc': 'SKU ID (descending)',
};

const RetailerBrandEligibilityHeader = ({ policyName }: HeaderProps) => {
  const breadcrumbItems = [
    {
      text: 'Eligibility',
      link: PATHS.retailerBrandEligibility,
    },
    {
      text: policyName,
      link: '',
    },
  ];

  const history = useHistory();
  const params = new URLSearchParams(history.location.search);

  const [queryParams, setQueryParams] = useStatefulQueryParams({
    [UrlParam.Direction]: params.get(UrlParam.Direction) || '',
    [UrlParam.Sort]: params.get(UrlParam.Sort) || '',
    [UrlParam.ProductName]: params.get(UrlParam.ProductName) || '',
  });

  const search = queryParams[UrlParam.ProductName];
  const setSearch = (value: string) => {
    setQueryParams({ [UrlParam.ProductName]: value });
  };

  const activeSort =
    queryParams[UrlParam.Sort] && queryParams[UrlParam.Direction]
      ? `${queryParams[UrlParam.Sort]}__${queryParams[UrlParam.Direction]}`
      : 'product_name__asc';
  const setActiveSort = (value: string) => {
    const [criteria, direction] = value.split('__');
    setQueryParams({
      [UrlParam.Sort]: criteria,
      [UrlParam.Direction]: direction,
    });
  };

  return (
    <>
      <BrandViewHeader
        title={policyName}
        subTitle={`View the eligibility status of your SKUs ${
          policyName ? `for ${policyName} ` : ''
        }here.`}
        showExportButton={false}
        showFilterButton={false}
        breadcrumbsItems={breadcrumbItems}
      />
      <div className={styles['options-row']}>
        <FormInput
          type="text"
          // @ts-ignore
          value={search}
          // @ts-ignore
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search"
          // @ts-ignore
          icon={
            <PhosphorIcon
              iconName="MagnifyingGlass"
              size="1.5rem"
              weight={PhosphorIconWeight.Light}
              color={Color.Neutral600.value}
            />
          }
        />
        <div className={styles['filters-wrapper']}>
          <DropdownRadioMenu
            fromLeft={true}
            options={SORT_OPTIONS}
            reverse
            activeValue={activeSort}
            setActiveValue={setActiveSort}
            triggerElement={
              <div className={styles['trigger-container']}>
                <PhosphorIcon iconName="ArrowsDownUp" size={20} />
                <Text
                  kind={TextKind.TextSMSemibold}
                  element={TextElement.P}
                  color={Color.Neutral600}
                  className={styles.nowrap}
                >
                  {activeSort
                    ? `Sort by: ${
                        SORT_OPTIONS[activeSort as keyof typeof SORT_OPTIONS]
                      }`
                    : 'Sort by'}
                </Text>
              </div>
            }
          />
        </div>
      </div>
    </>
  );
};

export default RetailerBrandEligibilityHeader;
