import { useContext, createContext, useMemo } from 'react';
import { useApp } from 'context/AppContext';

export function RetailerProvider(props) {
  const { children } = props;
  const { user } = useApp();

  const retailerID = user.organization.in_orgable_id;
  const retailerName = user.organization.name;

  const context = useMemo(
    () => ({
      retailerID,
      retailerName,
    }),
    [retailerID, retailerName]
  );

  return (
    <RetailerContext.Provider value={context}>
      {children}
    </RetailerContext.Provider>
  );
}

const RetailerContext = createContext(null);
export const useRetailerContext = () => {
  const ctx = useContext(RetailerContext);
  if (!ctx) {
    throw new Error('Must be used with a RetailerProvider');
  }
  return ctx;
};
