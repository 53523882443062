import { UrlParam } from 'design-system/data';
import { appendParameter, convertSnakeToCamelCase } from 'design-system/utils';
import useQueryParams from 'hooks/useQueryParams';
import { useApp } from 'context/AppContext';

export function setUrlSearchParameters({
  currentPage,
  itemsPerPage,
  status,
  history,
  activeSort,
}) {
  const params = new URLSearchParams();
  appendParameter(params, UrlParam.Sort, activeSort);
  appendParameter(params, UrlParam.Page, currentPage);
  appendParameter(params, UrlParam.Items, itemsPerPage);
  appendParameter(params, UrlParam.Status, status);
  history.push({ search: params?.toString() });
}

export function useRetailerRequirements(
  retailerPolicyRequirementGroupId,
  policyRequirementId,
  consumerBrandId
) {
  const params = useQueryParams(Object.values(UrlParam));
  const { useApi } = useApp();
  const url = `/api/v4/retailer_policy_requirement_groups/${retailerPolicyRequirementGroupId}/requirements`;
  const { data, loading, error } = useApi(url, {
    enabled: !!retailerPolicyRequirementGroupId && !!consumerBrandId,
    param: {
      ...params._asValues(),
      policy_requirement_id: policyRequirementId,
      consumer_brand_id: consumerBrandId,
      items: 10,
    },
  });
  return {
    data: convertSnakeToCamelCase(data),
    loading,
    error,
  };
}
