import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './table-row.module.scss';

function TableRow(props) {
  const className =
    cn(props.className, props.onClick && styles.clickable) || undefined;
  return (
    <tr className={className} onClick={props.onClick}>
      {props.children}
    </tr>
  );
}

TableRow.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default TableRow;
