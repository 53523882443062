import { useRef, useEffect, useState, useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  CloudinaryImg,
  IconName,
  IconDirection,
  Button,
  ButtonKind,
} from 'design-system/components';
import styles from './carousel.module.scss';
import fallbackImage from 'assets/images/MPFallbackImage.jpg';

const Carousel = forwardRef(
  ({ images, onImageClick, cols, selectedIndex, btnClassOverride }, ref) => {
    const [currentIdx, setCurrentIdx] = useState(selectedIndex || 0);

    const initCarouselRef = useRef(null);
    const carouselRef = ref || initCarouselRef;
    const isScrolling = useRef(null);

    let noScrollbar = false;
    if (cols === 1) noScrollbar = true;
    const lastImageSelected = currentIdx === images.length - 1 && noScrollbar;
    const firstImageSelected = currentIdx < 1 && noScrollbar;

    const scrollRight = () => {
      if (lastImageSelected) return;
      if (carouselRef.current && !isScrolling.current) {
        isScrolling.current = true;
        setCurrentIdx(currentIdx + 1);
        carouselRef.current.scrollLeft += carouselRef.current.offsetWidth;
      }
      setTimeout(() => {
        isScrolling.current = false;
      }, 300);
    };

    const scrollLeft = () => {
      if (firstImageSelected) return;
      if (carouselRef.current && !isScrolling.current) {
        isScrolling.current = true;
        setCurrentIdx(currentIdx - 1);
        carouselRef.current.scrollLeft -= carouselRef.current.offsetWidth;
      }
      setTimeout(() => {
        isScrolling.current = false;
      }, 300);
    };

    const handleAutoScroll = (idx) => {
      if (!carouselRef.current) return;
      const selectedElement = carouselRef.current.children[idx];
      if (!selectedElement) return;
      const scrollPosition =
        selectedElement.offsetLeft - carouselRef.current.offsetLeft;
      carouselRef.current.scrollLeft = scrollPosition;
    };

    useEffect(() => {
      if (selectedIndex >= 0) {
        if (noScrollbar) setCurrentIdx(selectedIndex);
        handleAutoScroll(selectedIndex);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carouselRef, selectedIndex]);

    const handleBulletClick = (idx) => {
      setCurrentIdx(idx);
      handleAutoScroll(idx);
    };

    const MemoizedImages = useMemo(() => {
      return images.map((image, idx) => {
        return (
          <div
            key={`${idx}: ${image.src}`}
            className={`${onImageClick && styles['clickable-image']} ${
              styles['image-wrapper']
            }`}
            style={{ flex: `0 0 ${100 / cols}%` }}
            onClick={onImageClick ? () => onImageClick(idx) : null}
          >
            <CloudinaryImg
              src={image.src}
              alt={image.alt}
              fallbackElement={<img src={fallbackImage} alt="fallback" />}
            />
          </div>
        );
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [images]);

    const handleKeyDown = (event) => {
      if (
        !carouselRef.current ||
        carouselRef.current !== document.activeElement
      ) {
        return;
      }

      switch (event.keyCode) {
        case 37: // Left arrow key
          scrollLeft();
          break;
        case 39: // Right arrow key
          scrollRight();
          break;
        default:
          break;
      }
    };

    carouselRef.current?.addEventListener('keydown', handleKeyDown);

    return (
      <div className={styles['carousel-wrapper']}>
        <Button
          className={
            btnClassOverride
              ? btnClassOverride
              : `${styles.button} ${
                  noScrollbar ? styles['single-col-btn-style'] : ''
                }`
          }
          style={firstImageSelected ? { visibility: 'hidden' } : {}}
          iconSize={12}
          kind={ButtonKind.Alternate}
          iconName={IconName.Chevron}
          iconDirection={IconDirection.Left}
          onClick={scrollLeft}
        />
        <div
          className={`${styles.carousel} ${
            noScrollbar && styles['no-scrollbar']
          }`}
          ref={carouselRef}
          tabIndex={0}
        >
          {MemoizedImages}
        </div>
        <Button
          className={
            btnClassOverride
              ? btnClassOverride
              : `${styles.button} ${
                  noScrollbar ? styles['single-col-btn-style'] : ''
                }`
          }
          style={lastImageSelected ? { visibility: 'hidden' } : {}}
          iconSize={12}
          kind={ButtonKind.Alternate}
          iconName={IconName.Chevron}
          iconDirection={IconDirection.Right}
          onClick={scrollRight}
        />
        {noScrollbar && images.length > 1 && (
          <div className={styles['bullets-wrapper']}>
            {images.map((_, idx) => (
              <div
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleBulletClick(idx);
                  }
                }}
                onClick={() => handleBulletClick(idx)}
                className={`${styles['bullet']} ${
                  idx === currentIdx ? styles['active-bullet'] : ''
                }`}
              />
            ))}
          </div>
        )}
      </div>
    );
  }
);

Carousel.propTypes = {
  images: PropTypes.array,
  onImageClick: PropTypes.func,
  cols: PropTypes.number,
  selectedIndex: PropTypes.number,
};

Carousel.defaultProps = {
  images: [],
  cols: 3,
};

export default Carousel;
