import { useCallback } from 'react';
import fetcher from 'services/api/fetcher';
import { sentry } from 'utils/sentry';

const useHubSpot = () => {
  const loadVerifiedWidget = useCallback(
    async ({ email, firstName, lastName }) => {
      try {
        const { token } = await fetcher('api/v4/hubspot/verify_user', {
          method: 'POST',
          body: { email, first_name: firstName, last_name: lastName },
        });
        window.hsConversationsSettings = {
          identificationEmail: email,
          identificationToken: token,
        };
        window.HubSpotConversations?.widget.load();
      } catch (e) {
        sentry.captureException(e);
      }
    },
    []
  );

  return {
    loadVerifiedWidget,
  };
};

export default useHubSpot;
