import { ScreeningStatus } from 'design-system/data';

export const tabStatuses = [
  '', // All
  ScreeningStatus.Pass,
  `${ScreeningStatus.Fail},${ScreeningStatus.Rejected}`,
  `${ScreeningStatus.NeedsInformation},${ScreeningStatus.Updating}`,
  ScreeningStatus.Pending,
  ScreeningStatus.NotApplicable,
];
