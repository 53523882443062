import { useState, useEffect, useCallback } from 'react';
import cn from 'classnames';
import {
  Breadcrumbs,
  Button,
  ButtonKind,
  EditableTitle,
  FilterMenu,
  IconName,
  Pill,
  PillColor,
  Text,
  TextElement,
  TextKind,
} from 'design-system/components';
import { Color } from 'design-system/data';
import PropTypes from 'prop-types';
import { BannerColor } from './constants.js';
import styles from './brand-view-header.module.scss';

function BrandViewHeader({
  title,
  subTitle,
  showExportButton,
  exportButtonText,
  showFilterButton,
  onFilterClick,
  filterItems = [],
  onExportClick,
  breadcrumbsItems,
  headerCta,
  headerCtaText,
  headerCtaColor,
  children,
  editableTitle,
  onTitleChange,
  placeholderTitle,
  bannerColor,
  bannerText,
  headerActions,
}) {
  const makeFilterStates = useCallback(
    () =>
      filterItems.reduce(
        (acc, retailer) => ({ ...acc, [retailer.name]: true }),
        {}
      ),
    [filterItems]
  );
  const [filterStates, setFilterStates] = useState(makeFilterStates);

  useEffect(() => {
    if (!filterItems.length) return;
    setFilterStates(makeFilterStates);
  }, [makeFilterStates, filterItems]);

  const filterData = filterItems.map((retailer) => {
    return {
      label: retailer.name,
      value: retailer.name,
      quantity: retailer.quantity,
      active: filterStates[retailer.name],
    };
  });

  const handleFilterSelection = (selection) => {
    let newFilterStates;
    if (selection) {
      newFilterStates = {
        ...filterStates,
        [selection]: !filterStates[selection],
      };
    } else {
      newFilterStates = makeFilterStates();
    }
    setFilterStates(newFilterStates);
    onFilterClick(newFilterStates);
  };

  return (
    <div>
      {breadcrumbsItems.length ? (
        <div className={styles.breadcrumbs}>
          <Breadcrumbs items={breadcrumbsItems} />
        </div>
      ) : null}
      {bannerText && (
        <div
          className={cn([
            styles.banner,
            styles[`banner-${bannerColor || BannerColor.Purple}`],
          ])}
        >
          <Text
            kind={TextKind.TextMDBold}
            element={TextElement.P}
            data-cy="brand-view-header-banner"
          >
            {bannerText}
          </Text>
        </div>
      )}
      <div className={styles.header}>
        <div>
          <div className={styles['title-block']}>
            {editableTitle ? (
              <EditableTitle
                placeholder={placeholderTitle}
                title={title}
                onTitleChange={onTitleChange}
              />
            ) : (
              <Text
                kind={TextKind.DisplaySMMedium}
                element={TextElement.H1}
                color={Color.Black}
              >
                {title}
              </Text>
            )}
            {headerActions}
            {headerCta && !headerActions && (
              <Button
                data-cy="header-cta-button"
                kind={ButtonKind.Pill}
                onClick={headerCta}
              >
                {headerCtaText}
              </Button>
            )}
            {headerCtaText && !headerCta && !headerActions && (
              <Pill
                dataCy="header-cta-text"
                color={headerCtaColor || PillColor.Blue}
              >
                {headerCtaText}
              </Pill>
            )}
          </div>
          {subTitle && (
            <Text
              kind={TextKind.TextSM}
              element={TextElement.P}
              color={Color.Black}
              className={styles['sub-title']}
            >
              {subTitle}
            </Text>
          )}
        </div>
        <div className={styles.buttons}>
          {showExportButton && (
            <Button
              kind={ButtonKind.Secondary}
              iconName={IconName.Download}
              onClick={onExportClick}
            >
              {exportButtonText}
            </Button>
          )}
          {showFilterButton && (
            <FilterMenu
              title="Retailers"
              onClick={handleFilterSelection}
              filters={filterData}
            />
          )}
          {children}
        </div>
      </div>
    </div>
  );
}

BrandViewHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  showExportButton: PropTypes.bool,
  exportButtonText: PropTypes.string,
  showFilterButton: PropTypes.bool,
  onFilterClick: PropTypes.func,
  onExportClick: PropTypes.func,
  breadcrumbsItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    })
  ),
  filterItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      quantity: PropTypes.number,
    })
  ),
  headerCta: PropTypes.func,
  headerCtaText: PropTypes.string,
  headerCtaColor: PropTypes.string,
  editableTitle: PropTypes.bool,
  onTitleChange: PropTypes.func,
  placeholderTitle: PropTypes.string,
  bannerText: PropTypes.string,
  bannerColor: PropTypes.string,
  children: PropTypes.node,
  headerActions: PropTypes.node,
};

BrandViewHeader.defaultProps = {
  showExportButton: true,
  exportButtonText: 'Export Results',
  showFilterButton: true,
  onFilterClick: () => {},
  onExportClick: () => {},
  breadcrumbsItems: [],
  editableTitle: false,
  onTitleChange: () => {},
  children: null,
  headerActions: null,
};

export default BrandViewHeader;
