import { useCallback, useEffect, useState } from 'react';
import { bool, func, object } from 'prop-types';
import { isEmpty } from 'lodash';
import cn from 'classnames';

import {
  Button,
  ButtonKind,
  Radio,
  RadioGroup,
  Text,
  TextElement,
  TextKind,
} from 'design-system/components';
import { Color } from 'design-system/data';

import ScreeningStatusPill from 'components/ScreeningStatusPill';
import { AttestationNoteType } from 'components/ScreeningModals/constants';
import withLineBreaks from 'utils/withLineBreaks';
import {
  getAttestationOptions,
  AttestationActionType,
} from 'views/Brands/RetailerBrandRequirementSummary/constants';

import styles from '../../cta-modals.module.scss';

function Attestation({
  onChange,
  condition,
  triggerValidation,
  onValidate,
  isRetailer,
  submitRetailerResponse,
  canAttest,
  setHasChanged,
}) {
  const { conditionId, actionDetail, policyRequirementId } = condition;
  const [errorObject, setError] = useState({
    noteError: null,
    responseError: null,
  });
  const initialResponseValue = condition?.attestationProof?.responseValue;
  const initialNoteValue = condition?.attestationProof?.note;
  const [attestationResponse, setAttestationResponse] =
    useState(initialResponseValue);
  const [attestationNotes, setAttestationNotes] = useState(initialNoteValue);
  const attestationOptions = getAttestationOptions(
    condition?.actionDetail?.attestationType
  );

  const actionType = actionDetail?.actionType;
  const showNotes =
    actionDetail?.noteType === AttestationNoteType.Optional ||
    actionDetail?.noteType === AttestationNoteType.Required;

  const retailerResponse = condition?.retailerResponse;

  // setup responses for submit if already present.
  useEffect(() => {
    if (initialNoteValue || initialResponseValue) {
      onChange(
        conditionId,
        AttestationActionType.Attestation,
        {
          value: initialResponseValue,
          noteValue: initialNoteValue,
          conditionType: actionType,
        },
        policyRequirementId
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (value, type) => {
    setHasChanged();
    switch (type) {
      case 'attestation':
        setAttestationResponse(value);
        return onChange(
          condition?.conditionId,
          AttestationActionType.Attestation,
          {
            value,
            noteValue: attestationNotes,
            conditionType: actionType,
          },
          condition?.policyRequirementId
        );
      case 'note':
        setAttestationNotes(value);
        return onChange(
          condition?.conditionId,
          AttestationActionType.Attestation,
          {
            value: attestationResponse,
            noteValue: value,
            conditionType: actionType,
          },
          condition?.policyRequirementId
        );
      default:
        return;
    }
  };

  const validate = useCallback(() => {
    let errors = {};
    if (actionDetail?.noteType === 'required') {
      // Prev logic for this doesn't appear to work as expected. Awaiting results of UAT.
      // const isPassingValue = attestationResponse === actionDetail?.passingValue;
      if (!attestationNotes) {
        errors['noteError'] = 'You must enter a note.';
      }
    }

    if (!attestationResponse) {
      errors['responseError'] = 'You must choose a value.';
    }
    setError(errors);
    const isValid = isEmpty(errors);
    return isValid;
  }, [
    actionDetail?.noteType,
    attestationResponse,
    attestationNotes,
    // actionDetail?.passingValue,
  ]);

  useEffect(() => {
    if (triggerValidation) {
      const isValid = validate();
      onValidate(conditionId, isValid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerValidation]);

  /// Retailer Specific Logic
  const isPendingRetailerAction = condition?.isPendingRetailerAction;
  const [retailerResponseInput, setRetailerResponseInput] = useState(
    retailerResponse?.notes
  );
  const [retailerNoteError, setRetailerNoteError] = useState(null);

  const handleRetailerResponseSubmit = (isAccepted) => {
    if (!retailerResponseInput) {
      setRetailerNoteError('You must enter a note.');
    } else {
      setRetailerNoteError(null);
      submitRetailerResponse.call(
        conditionId,
        condition?.attestationProof?.id,
        isAccepted,
        retailerResponseInput
      );
    }
  };

  return (
    <>
      <Text
        kind={TextKind.TextSMBold}
        element={TextElement.P}
        className={styles['section-header-text']}
      >
        Attestation
      </Text>
      <RadioGroup
        onChange={(val) => handleChange(val, 'attestation')}
        name="attestation-response"
        value={attestationResponse}
        disabled={!canAttest || isRetailer}
        hasError={!!errorObject?.responseError}
        errorMessage={errorObject?.responseError}
      >
        {attestationOptions?.map((option, i) => {
          if (option.value === null) {
            return null;
          }
          return <Radio value={option.value} label={option.display} key={i} />;
        })}
      </RadioGroup>
      {showNotes && (
        <div className={styles['notes-section']}>
          {actionDetail?.noteDescription && (
            <div className={styles['note-description']}>
              <Text
                kind={TextKind.TextSMBold}
                element={TextElement.P}
                className={styles['section-header-text']}
              >
                In the box below, please add:
              </Text>
              <Text
                kind={TextKind.TextMD}
                element={TextElement.P}
                className={styles['section-header-text']}
              >
                {withLineBreaks(actionDetail?.noteDescription)}
              </Text>
            </div>
          )}
          <Text
            kind={TextKind.TextSMBold}
            element={TextElement.P}
            className={styles['section-header-text']}
          >
            Notes
          </Text>
          <textarea
            className={cn([
              styles['textarea'],
              errorObject?.noteError && styles['textarea--error'],
            ])}
            rows={5}
            placeholder="Add notes..."
            value={attestationNotes}
            onChange={(e) => handleChange(e.target.value, 'note')}
            readOnly={!canAttest || isRetailer}
          />
          {errorObject?.noteError && (
            <Text
              kind={TextKind.TextSM}
              element={TextElement.P}
              color={Color.Red600}
            >
              {errorObject?.noteError}
            </Text>
          )}
        </div>
      )}
      {/* Retailer Response Section */}
      {(isRetailer || retailerResponse?.notes) && (
        <div
          className={cn([styles['notes-section'], styles['retailer-response']])}
        >
          <Text
            kind={TextKind.TextSMBold}
            element={TextElement.P}
            className={styles['section-header-text']}
          >
            Retailer Response{' '}
            {isRetailer && isPendingRetailerAction && '(Required)'}{' '}
            {retailerResponse?.status && (
              <ScreeningStatusPill status={retailerResponse.status} />
            )}
          </Text>
          {(isPendingRetailerAction || retailerResponse?.notes) && (
            <textarea
              className={styles['textarea']}
              placeholder="Retailer response..."
              value={retailerResponseInput}
              readOnly={!isRetailer || !isPendingRetailerAction}
              onChange={(e) => setRetailerResponseInput(e.target.value)}
            />
          )}
          {retailerNoteError && (
            <Text
              kind={TextKind.TextSM}
              element={TextElement.P}
              color={Color.Red600}
            >
              Note is required
            </Text>
          )}
          {isRetailer && !isPendingRetailerAction && (
            <Text
              kind={TextKind.TextSM}
              element={TextElement.P}
              color={Color.Gray600}
            >
              No retailer response needed at this time.
            </Text>
          )}
          {isRetailer && isPendingRetailerAction && (
            <div className={styles['action-buttons']}>
              <Button
                kind={ButtonKind.Error}
                onClick={() => handleRetailerResponseSubmit(false)}
                disabled={submitRetailerResponse.loading}
              >
                {submitRetailerResponse.loading ? 'Submitting…' : 'Reject'}
              </Button>
              <Button
                kind={ButtonKind.Success}
                onClick={() => handleRetailerResponseSubmit(true)}
                disabled={submitRetailerResponse.loading}
              >
                {submitRetailerResponse.loading ? 'Submitting…' : 'Accept'}
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
}

Attestation.propTypes = {
  onChange: func.isRequired,
  condition: object,
  triggerValidation: bool,
  onValidate: func,
  canAttest: bool,
  setHasChanged: func,
};

export default Attestation;
