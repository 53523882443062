import { useParams } from 'react-router';

import { useApp } from 'context/AppContext';
import useSKUEligibilityResults from './hooks/useSKUEligibilityResults';
import Header from './components/Header';
import Table from './components/Table';
import Disclaimer from 'views/Brands/shared/Disclaimer';

const SKULevelStatusPageView = () => {
  const { policy_id } = useParams();
  const { retailerBrand, user } = useApp();
  const retailerId = retailerBrand?.retailer?.id;
  const brandId = user?.organization?.in_orgable_id;
  const response = useSKUEligibilityResults(retailerId, brandId, policy_id);
  return (
    <main>
      <Header policyName={response?.data?.retailerPolicyName} />
      <Table data={response} />
      {retailerBrand?.retailer?.disclaimer && (
        <Disclaimer text={retailerBrand?.retailer?.disclaimer || ''} />
      )}
    </main>
  );
};

export default SKULevelStatusPageView;
