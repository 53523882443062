import { YourStatusProvider } from './context';
import YourStatusView from './view';

function RetailerBrands() {
  return (
    <YourStatusProvider>
      <YourStatusView />
    </YourStatusProvider>
  );
}

export default RetailerBrands;
