export const ButtonKind = {
  Primary: 'primary',
  Secondary: 'secondary',
  Tertiary: 'tertiary',
  Alternate: 'alternate',
  Pill: 'pill',
  Success: 'success',
  Error: 'error',
  Link: 'link',
  Close: 'close',
};

export const ButtonIconPosition = {
  Left: 'left',
  Right: 'right',
};
