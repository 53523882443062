import useApi from 'services/api/useApi';

interface EmbedUrlResponse {
  has_dash: boolean;
  embed_url: string | null;
}

interface MetabaseDashboardData {
  hasDash: boolean;
  embedUrl: string | null;
  loading: boolean;
}

export const useMetabaseDashboardData = (
  dashboardType: string,
  retailerId: number,
  hasAccess: boolean
): MetabaseDashboardData => {
  const endpoint = `api/v4/retailers/${retailerId}/metabase_embed_url`;
  const response = useApi(endpoint, {
    enabled: Boolean(retailerId && dashboardType && hasAccess),
    param: {
      dashboard_type: dashboardType,
    },
  });

  const data = response.data as EmbedUrlResponse | undefined;
  const loading = response.loading;

  return {
    hasDash: data?.has_dash ?? false,
    embedUrl: data?.embed_url ?? null,
    loading,
  };
};
