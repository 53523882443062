import { useCallback, useEffect, useState } from 'react';
import { bool, func, object } from 'prop-types';

import { Text, TextKind, TextElement } from 'design-system/components';
import { Color } from 'design-system/data';

import styles from '../../cta-modals.module.scss';
import {
  AttestationActionType,
  RequirementActionContentType,
} from 'views/Brands/RetailerBrandRequirementSummary/constants';

function AddPercentage({
  condition,
  onChange,
  triggerValidation,
  onValidate,
  canAttest,
  setHasChanged,
}) {
  const { policyRequirementId, isRequired } = condition;
  const [error, setError] = useState(null);
  const [percentageResponse, setPercentageResponse] = useState(
    condition?.attestationProof?.responseValue || null
  );

  // setup response for submit if already present.
  useEffect(() => {
    if (percentageResponse) {
      onChange(
        condition?.conditionId,
        AttestationActionType.IngredientPercent,
        {
          value: parseFloat(percentageResponse),
          noteValue: null,
          conditionType: RequirementActionContentType.Percentage,
        },
        condition?.policyRequirementId
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (value) => {
    setPercentageResponse(value);
    setHasChanged();

    return onChange(
      condition?.conditionId,
      AttestationActionType.IngredientPercent,
      {
        value: parseFloat(value),
        noteValue: null,
        conditionType: RequirementActionContentType.Percentage,
      },
      condition?.policyRequirementId
    );
  };

  const validate = useCallback(() => {
    if (isRequired && !percentageResponse) {
      setError('You must provide a value.');
      return false;
    }
    setError(null);
    return true;
  }, [percentageResponse, isRequired]);

  useEffect(() => {
    if (triggerValidation) {
      const isValid = validate();
      onValidate(policyRequirementId, isValid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerValidation]);

  return (
    <>
      <Text
        kind={TextKind.TextSMBold}
        element={TextElement.P}
        className={styles['function-header']}
      >
        Percentage *
      </Text>
      <div className={styles['input-container']}>
        <input
          placeholder="Enter a percentage"
          className={styles.input}
          type="number"
          step="0.01"
          onChange={(e) => handleChange(e.target.value)}
          readOnly={!canAttest}
        />
      </div>
      {error && (
        <Text
          kind={TextKind.TextSM}
          element={TextElement.P}
          color={Color.Orange600}
        >
          {error}
        </Text>
      )}
    </>
  );
}

AddPercentage.propTypes = {
  condition: object.isRequired,
  onChange: func.isRequired,
  triggerValidation: bool,
  onValidate: func,
  canAttest: bool,
};

export default AddPercentage;
