import { createRef, useEffect } from 'react';
import {
  Button,
  ButtonKind,
  PhosphorIcon,
  TableBodyCell,
  TableRow,
} from 'design-system/components';
import ScreeningStatusPill from 'components/ScreeningStatusPill';
import styles from './retailerBrandRequirementSummary.module.scss';
import { ScreeningStatus } from 'design-system/data';

const RequirementSummaryRow = ({
  productScreeningResult,
  onClick,
  isActive,
  modalOpen,
}) => {
  const isAttestable =
    productScreeningResult.screeningStatus === ScreeningStatus.NeedsAttention ||
    productScreeningResult.screeningStatus === ScreeningStatus.NeedsInformation;

  const buttonRef = createRef();

  useEffect(() => {
    if (isActive && !modalOpen) {
      buttonRef?.current?.focus();
    } else {
      buttonRef?.current?.blur();
    }
  }, [buttonRef, isActive, modalOpen]);

  return (
    <TableRow
      onClick={
        productScreeningResult.hasAttestationConditions &&
        (() => onClick(productScreeningResult.consumerProductId))
      }
      className={isActive ? styles.active : ''}
    >
      <TableBodyCell>
        {productScreeningResult.consumerProductName}
      </TableBodyCell>
      <TableBodyCell>
        <ScreeningStatusPill
          withDot
          status={productScreeningResult.screeningStatus}
        />
      </TableBodyCell>
      <TableBodyCell>
        {productScreeningResult.hasAttestationConditions && (
          <Button
            refProp={buttonRef}
            kind={isAttestable ? ButtonKind.Alternate : ButtonKind.Tertiary}
            onClick={() => onClick(productScreeningResult.consumerProductId)}
          >
            <div className={styles.cta}>
              {isAttestable ? 'Attest' : 'Review'}
              <span className={styles['icon-container']}>
                <PhosphorIcon iconName="ArrowRight" size={18} />
              </span>
            </div>
          </Button>
        )}
      </TableBodyCell>
    </TableRow>
  );
};

export default RequirementSummaryRow;
