import React from 'react';
import { NoContent } from 'design-system/components';
import styles from './metabase-dashboard.module.scss';

interface MetabaseDashboardProps {
  embedUrl?: string | null;
  loading?: boolean;
}

const MetabaseDashboard: React.FC<MetabaseDashboardProps> = ({
  embedUrl,
  loading,
}) => {
  if (loading) {
    return <NoContent loading />;
  }

  return (
    <div id="metabase-container" className={styles.container as string}>
      {embedUrl && (
        <iframe
          src={embedUrl}
          frameBorder="0"
          width="100%"
          height="600"
          allowTransparency
        />
      )}
    </div>
  );
};

export default MetabaseDashboard;
