import { RetailerProvider } from '../context';
import RetailerDashboard from '../components/RetailerDashboard';

function RetailerDashboardView() {
  return (
    <RetailerProvider>
      <RetailerDashboard />
    </RetailerProvider>
  );
}

export default RetailerDashboardView;
