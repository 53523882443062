import { useMemo, useEffect, createRef } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  ButtonKind,
  Link,
  LinkKind,
  LinkVariant,
  Pill,
  PillColor,
  PhosphorIcon,
  TableBodyCell,
  TableRow,
  IconName,
  ButtonIconPosition,
  IconDirection,
  Tooltip,
  OpenAxis,
} from 'design-system/components';
import { ScreeningStatus, PolicyRequirementType } from 'design-system/data';
import ScreeningStatusPill from 'components/ScreeningStatusPill';
import styles from '../policyRequirements.module.scss';

const PolicyRequirementRow = ({
  requirement,
  url,
  onClick,
  modalOpen,
  isActive,
}) => {
  const history = useHistory();
  const isBrandRequirement =
    requirement.requirementType === PolicyRequirementType.Brand;
  const isAttestable =
    requirement.screeningStatus === ScreeningStatus.NeedsAttention ||
    requirement.screeningStatus === ScreeningStatus.NeedsInformation;

  const buttonRef = createRef();

  useEffect(() => {
    if (isActive && !modalOpen) {
      buttonRef?.current?.focus();
    } else {
      buttonRef?.current?.blur();
    }
  }, [buttonRef, isActive, modalOpen]);

  const getScreeningStatusPillValue = () => {
    if (requirement.isPendingRetailerAction) return ScreeningStatus.Pending;
    return requirement.screeningStatus;
  };

  const ctaData = {
    isBrandRequirement,
    to: url,
    // Attest | Review | Attest for SKUs | Review SKUs
    text: `${isAttestable ? 'Attest' : 'Review'} ${
      !isBrandRequirement ? `${isAttestable ? 'for' : ''} SKUs` : ''
    }`,
  };

  const tableRowOnClick = ctaData.isBrandRequirement
    ? onClick
    : () => history.push(ctaData.to);

  const maximumPillCharacters = 40;
  const truncatedPillText = useMemo(() => {
    const text = requirement.category;
    if (typeof text !== 'string') return text;
    return text?.length > maximumPillCharacters
      ? `${text.slice(0, maximumPillCharacters)}...`
      : text;
  }, [requirement.category]);

  return (
    <TableRow
      onClick={tableRowOnClick}
      className={isActive ? styles.active : ''}
    >
      <TableBodyCell>{requirement.name}</TableBodyCell>
      <TableBodyCell>
        <Tooltip
          openAxis={OpenAxis.Y}
          triggerElement={
            <Pill color={PillColor.Default}>{truncatedPillText}</Pill>
          }
          disabled={requirement.category?.length <= maximumPillCharacters}
        >
          {requirement.category}
        </Tooltip>
      </TableBodyCell>
      <TableBodyCell>
        <ScreeningStatusPill withDot status={getScreeningStatusPillValue()} />
      </TableBodyCell>
      <TableBodyCell>
        <div className={styles['cta-container']}>
          {ctaData.isBrandRequirement ? (
            <Button
              kind={isAttestable ? ButtonKind.Alternate : ButtonKind.Tertiary}
              iconName={IconName.ArrowV2}
              iconPosition={ButtonIconPosition.Right}
              iconDirection={IconDirection.Right}
              onClick={onClick}
              refProp={buttonRef}
            >
              {ctaData.text}
            </Button>
          ) : (
            <Link
              kind={LinkKind.Internal}
              variant={
                isAttestable ? LinkVariant.Alternate : LinkVariant.Tertiary
              }
              to={ctaData.to}
            >
              <div className={styles.cta}>
                {ctaData.text}
                <span className={styles['icon-container']}>
                  <PhosphorIcon iconName="ArrowRight" size={18} />
                </span>
              </div>
            </Link>
          )}
        </div>
      </TableBodyCell>
    </TableRow>
  );
};

export default PolicyRequirementRow;
