import { useContext, createContext, useMemo } from 'react';
import { useApp } from 'context/AppContext';
import useQueryParams from 'hooks/useQueryParams';
import { convertSnakeToCamelCase } from 'design-system/utils';
import { UrlParam } from 'design-system/data';

export function RetailerProductsProvider(props) {
  const { children } = props;
  const { useApi, user } = useApp();

  const params = useQueryParams(Object.values(UrlParam));
  const brandIDs = useQueryParams(UrlParam.ConsumerBrandIds);
  const productIDs = useQueryParams(UrlParam.ConsumerProductIds);

  const retailerID = user.organization.in_orgable_id;

  const buildParams = (searchParams) => {
    let params = { ...searchParams._asValues() };
    if (brandIDs.value) {
      params = { ...params, [UrlParam.ConsumerBrandIds]: brandIDs };
    }
    if (productIDs.value) {
      params = { ...params, [UrlParam.ConsumerProductIds]: brandIDs };
    }
    return params;
  };

  const { data, loading, error } = useApi(
    `/api/v4/retailers/${retailerID}/retailer_consumer_products`,
    {
      param: {
        ...buildParams(params),
      },
    }
  );

  const {
    data: filtbrandFiltersData,
    loading: brandFiltersLoading,
    error: brandFiltersError,
  } = useApi('api/v4/consumer_brands', {
    param: {
      retailer_id: retailerID,
    },
  });

  const productData = useMemo(() => {
    return loading || error ? data : convertSnakeToCamelCase(data);
  }, [data, loading, error]);

  const brandFilterData = useMemo(() => {
    return brandFiltersLoading || brandFiltersError
      ? filtbrandFiltersData
      : convertSnakeToCamelCase(filtbrandFiltersData);
  }, [filtbrandFiltersData, brandFiltersLoading, brandFiltersError]);

  const context = useMemo(
    () => ({
      data: productData,
      loading,
      error,
      retailerID,
      brandFilterData,
      brandFiltersLoading,
      brandFiltersError,
    }),
    [
      productData,
      loading,
      error,
      retailerID,
      brandFilterData,
      brandFiltersLoading,
      brandFiltersError,
    ]
  );

  return (
    <RetailerProductsContext.Provider value={context}>
      {children}
    </RetailerProductsContext.Provider>
  );
}

const RetailerProductsContext = createContext(null);
export const useRetailerProductsContext = () => {
  const ctx = useContext(RetailerProductsContext);

  if (!ctx) {
    throw new Error('Must be used with a RetailerProductsProvider');
  }
  return ctx;
};
