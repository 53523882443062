import fetcher from 'services/api/fetcher';
import useAsyncCall from 'services/api/useAsyncCall';
import { FlavorOptions, FragranceOptions } from './utils';

export default function useSubmitNewTextProduct(options) {
  return useAsyncCall(async function submitNewTextProduct(param) {
    const {
      consumer_product_name,
      product_application_id,
      ingredient_names,
      product_use_ids,
      product_form_id,
      product_claim_ids,
      application_times,
      fragrance = null,
      flavor = null,
      skuId,
    } = param;
    let body = {
      consumer_product_name,
      product_application_id,
      ingredient_names,
      product_use_ids,
      product_form_id,
      product_claim_ids,
      application_time: application_times,
      sku_id: skuId,
    };

    // Add flavor and fragrance only if they are not null
    if (flavor !== null) {
      body.flavor = FlavorOptions[flavor];
    }
    if (fragrance !== null) {
      body.fragrance = FragranceOptions[fragrance];
    }

    if (options.isLegacySephora) {
      body = {
        ...body,
        brand_line: param.brandLine,
        product_category: param.productCategory,
        countries: param.countries,
        launch_dates: param.launchDates,
        parent_company: param.parentCompany,
        sales_channel: param.salesChannel,
        share_with_sephora: param.shareWithSephora,
      };
    }

    if (options.isSephoraRetailerBrand) {
      body = {
        ...body,
        countries: param.countries,
        launch_dates: param.launchDates,
      };
    }

    const response = await fetcher('/api/product_design/v1/consumer_products', {
      method: 'post',
      body,
    });

    return response;
  }, options);
}
