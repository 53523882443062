import {
  createContext,
  useContext,
  useMemo,
  useEffect,
  useState,
  useCallback,
} from 'react';

import { convertSnakeToCamelCase } from 'design-system/utils/case';
import { useApp } from 'context/AppContext';
import { UrlParam } from 'design-system/data';
import useQueryParams from 'hooks/useQueryParams';
import fetcher from 'services/api/fetcher';
import { useAddProductDialog } from 'components/ConsumerProductDialogs/AddProductDialog';
import { enqueueSnackbar } from 'notistack';
import { useLoading } from 'context/Loading';
import Dialog from 'components/Dialog';
import {
  Button,
  ButtonKind,
  InputV2,
  Text,
  TextKind,
  TextElement,
} from 'design-system/components';
import { usePaywallContext } from './PaywallContext';
import styles from './edit-dialog.module.scss';
import { isSelectedRetailerInLegacyPortal } from 'views/Brands/PortalSelection/util';

const YourProductsContext = createContext(null);

export const useYourProductContext = () => {
  const ctx = useContext(YourProductsContext);
  if (!ctx) {
    throw new Error('Must be used with a YourProductsContext');
  }
  return ctx;
};

function YourProductsProvider({ children }) {
  const {
    user,
    useApi,
    selectedRetailerId: retailerId,
    hasRetailerBrandResponseFinished,
    retailerBrand,
  } = useApp();

  const brandID = user.organization.in_orgable_id;
  const params = useQueryParams(Object.values(UrlParam));
  const [tableCounts, setTableCounts] = useState({});
  const [loading, setLoading] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [errors, setErrors] = useState({ product_name: '', sku_id: '' });
  const [hasProductNameError, setHasProductNameError] = useState(false);
  const [hasSkuNumberError, setHasSkuNumberError] = useState(false);

  const [editProductInformation, setEditProductInformation] = useState({
    id: '',
    isBrandUploadedProduct: false,
    name: '',
    skuId: '',
  });

  const clearEditProductInformation = () => {
    setEditProductInformation({
      id: '',
      isBrandUploadedProduct: false,
      name: '',
      skuId: '',
    });
  };

  const buildPayloadFromForm = (formData) => {
    let payload = {};
    payload['product_name'] = formData.get('product_name');
    payload['sku_id'] = formData.get('sku_id');
    return payload;
  };

  const hasErrors = Object.values(errors).some((errorMsg) => errorMsg);

  const validateSku = (value) => {
    let errorMessage = '';
    if (value.length > 60) {
      errorMessage = 'SKU number cannot exceed 60 characters';
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      ['sku_id']: errorMessage,
    }));
    setHasSkuNumberError(errorMessage.length > 0);
  };

  const validateProductName = (value) => {
    let errorMessage = '';
    if (!value.length) {
      errorMessage = 'The product name field is required';
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      ['product_name']: errorMessage,
    }));
    setHasProductNameError(errorMessage.length > 0);
  };

  const {
    Dialog: AddProductDialog,
    openDialog: openAddProductDialog,
    closeDialog,
  } = useAddProductDialog();
  const { unlocked, enrollment_type, max_product_count, bulk_upload_included } =
    retailerBrand || {};
  const { openPaywall } = usePaywallContext();
  const isSelectedRetailerInLegacy = isSelectedRetailerInLegacyPortal(
    retailerId,
    user
  );

  const isSelectedRetailerInV2 =
    Boolean(retailerId) && !isSelectedRetailerInLegacy;

  const enabled = useMemo(() => {
    if (!hasRetailerBrandResponseFinished) return false;
    return isSelectedRetailerInV2
      ? Boolean(brandID && retailerId)
      : Boolean(brandID);
  }, [
    brandID,
    retailerId,
    hasRetailerBrandResponseFinished,
    isSelectedRetailerInV2,
  ]);

  // migrate to only use endpoint with retailerId
  const consumerProductsEndpoint = isSelectedRetailerInV2
    ? `api/v4/retailers/${retailerId}/brands/${brandID}/consumer_products`
    : `api/v4/brands/${brandID}/consumer_products`;

  const products = useApi(consumerProductsEndpoint, {
    enabled,
    param: {
      ...params._asValues(),
      ...(Boolean(isSelectedRetailerInLegacy) && {
        retailer_id: retailerId,
      }),
      ...(isSelectedRetailerInV2 && {
        with_policy_results: false,
      }),
    },
  });

  useEffect(() => {
    if (products.data?.consumer_product_type_counts) {
      setTableCounts({
        all: products.data.consumer_product_type_counts.total,
        brand_uploaded:
          products.data.consumer_product_type_counts.brand_uploaded,
        retailer_ingested:
          products.data.consumer_product_type_counts.retailer_ingested,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products.data?.consumer_product_type_counts?.total]); // Lets only reset these when the counts change, not every change of products (switching tabs)

  const onProductCreateSuccess = useCallback(
    async (product, skuId) => {
      try {
        await fetcher(
          `/api/product_design/v1/consumer_products/${product.id}/publish_to_retailer`,
          {
            body: { retailer_id: retailerId, ...(skuId && { sku_id: skuId }) },
            method: 'post',
          }
        );
      } catch (error) {
        enqueueSnackbar('Product created but failed to publish to retailer', {
          variant: 'error',
        });
      } finally {
        closeDialog();
        products.refetch();
      }
    },
    [products, retailerId, closeDialog]
  );

  const shouldOpenPaywall = useMemo(() => {
    if (
      user.hasFF('upload_skus') &&
      enrollment_type === 'brand subscription' &&
      (!unlocked ||
        (max_product_count && tableCounts?.all >= max_product_count))
    ) {
      return true;
    }
    return false;
  }, [enrollment_type, unlocked, max_product_count, user, tableCounts?.all]);

  const deleteProduct = useCallback(
    async (id, isBrandUploadedProduct) => {
      if (!isBrandUploadedProduct) return;

      try {
        setLoading(true);
        const url = `/api/product_design/v1/consumer_products/${id}`;
        await fetcher(url, { method: 'delete' });
        enqueueSnackbar('SKU deleted', { variant: 'success' });
        await products.refetch({ soft: true });
      } catch (error) {
        enqueueSnackbar(`Failed to delete SKU: ${error}`, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [products]
  );

  useLoading(loading);

  const handleCancel = () => {
    setEditOpen(false);
    setErrors({ product_name: '', sku_id: '' });
    setHasSkuNumberError(false);
    setHasProductNameError(false);
  };

  const handleEditSubmit = async (event) => {
    if (!editProductInformation.isBrandUploadedProduct) return;
    try {
      setLoading(true);
      event.preventDefault();
      const formData = new FormData(event.target);
      const payload = buildPayloadFromForm(formData);

      const productUrl = `/api/product_design/v1/consumer_products/${editProductInformation.id}`;

      await fetcher(productUrl, {
        method: 'put',
        body: payload,
      });

      setEditOpen(false);
      clearEditProductInformation();
      products.refetch();
      enqueueSnackbar('SKU successfully updated', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(`Failed to update product: ${error}`, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const context = useMemo(
    () => ({
      openAddProductDialog: () =>
        shouldOpenPaywall ? openPaywall() : openAddProductDialog(),
      products: convertSnakeToCamelCase(products.data),
      loading: products.loading,
      error: products.error,
      tableCounts,
      deleteProduct,
      setEditOpen,
      setEditProductInformation,
      shouldOpenPaywall,
      bulk_upload_included,
    }),
    [
      products,
      tableCounts,
      openAddProductDialog,
      deleteProduct,
      setEditProductInformation,
      shouldOpenPaywall,
      openPaywall,
      bulk_upload_included,
    ]
  );

  return (
    <YourProductsContext.Provider value={context}>
      <AddProductDialog
        isProduct
        allowSkuInput={isSelectedRetailerInV2}
        onSuccess={(product, skuId) => onProductCreateSuccess(product, skuId)}
      />
      <Dialog open={editOpen} onClose={() => setEditOpen(false)}>
        <form onSubmit={(event) => handleEditSubmit(event)}>
          <Text kind={TextKind.DisplayLGMedium} element={TextElement.H2}>
            Edit "{editProductInformation.name}"
          </Text>
          <div className={styles['input-wrapper']}>
            <InputV2
              name="product_name"
              type="text"
              defaultValue={editProductInformation.name}
              label="Product Name:"
              hasError={hasProductNameError}
              errorMessage={errors['product_name']}
              handleInputChange={(event) =>
                validateProductName(event.target.value)
              }
              endIconName={hasProductNameError ? 'WarningCircle' : null}
            />
          </div>
          <div className={styles['input-wrapper']}>
            <InputV2
              name="sku_id"
              type="text"
              defaultValue={editProductInformation.skuId ?? ''}
              label="SKU Number:"
              hasError={hasSkuNumberError}
              errorMessage={errors['sku_id']}
              handleInputChange={(event) => validateSku(event.target.value)}
              endIconName={hasSkuNumberError ? 'WarningCircle' : null}
            />
          </div>

          <div className={styles['buttons-wrapper']}>
            <Button
              kind={ButtonKind.Secondary}
              onClick={() => handleCancel()}
              type="button"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              kind={ButtonKind.Primary}
              disabled={hasErrors}
            >
              Submit
            </Button>
          </div>
        </form>
      </Dialog>
      {children}
    </YourProductsContext.Provider>
  );
}

export default YourProductsProvider;
