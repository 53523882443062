import { Breadcrumbs, Button } from 'design-system/components';

import PageHeader from 'views/FDARegistration/shared/components/PageHeader';
import styles from './your-products.module.scss';
import { PATHS } from 'constants/index';

const baseLink = PATHS.retailerBrandProducts;
const breadcrumbsItems = [
  {
    text: 'SKUs',
    link: baseLink,
  },
  {
    text: 'Upload SKU',
    link: '.',
  },
];

function CreateProductContainer() {
  return (
    <div className={styles.root}>
      <PageHeader
        title="Upload a SKU"
        subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        breadcrumbs={<Breadcrumbs items={breadcrumbsItems} />}
        actions={
          <>
            <Button kind="tertiary" style={{ padding: '10px 16px' }}>
              Cancel
            </Button>
            <Button style={{ padding: '10px 16px' }}>Save</Button>
          </>
        }
      />
    </div>
  );
}

export default CreateProductContainer;
