import { createContext, useContext, useMemo } from 'react';
import { useApp } from 'context/AppContext';
import { convertSnakeToCamelCase } from 'design-system/utils/case';
import { isSelectedRetailerInLegacyPortal } from 'views/Brands/PortalSelection/util';

const DocumentsContext = createContext(null);

export const useDocumentContext = () => {
  const ctx = useContext(DocumentsContext);
  if (!ctx) {
    throw new Error('Must be used with a DocumentsProvider');
  }
  return ctx;
};

function DocumentsProvider({ children }) {
  const { useApi, user, selectedRetailerId, hasRetailerBrandResponseFinished } =
    useApp();
  const brandID = user.organization.in_orgable_id;
  // migrate to only use endpoint with retailerId
  const isSelectedRetailerInLegacy = isSelectedRetailerInLegacyPortal(
    selectedRetailerId,
    user
  );
  const enabled = useMemo(() => {
    return isSelectedRetailerInLegacy
      ? Boolean(
          brandID && selectedRetailerId && hasRetailerBrandResponseFinished
        )
      : Boolean(brandID && hasRetailerBrandResponseFinished);
  }, [
    isSelectedRetailerInLegacy,
    brandID,
    selectedRetailerId,
    hasRetailerBrandResponseFinished,
  ]);

  const endpoint = `/api/v4/brands/${brandID}/documents`;
  const documents = useApi(endpoint, {
    enabled,
    param: {
      ...(Boolean(selectedRetailerId) && {
        retailer_id: selectedRetailerId,
      }),
    },
  });

  const context = useMemo(() => {
    const processedDocuments = convertSnakeToCamelCase(documents?.data) || {
      brandPolicyConditionDocuments: [],
      productPolicyConditionDocuments: [],
      packagePolicyConditionDocuments: [],
    };
    const {
      brandPolicyConditionDocuments,
      productPolicyConditionDocuments,
      packagePolicyConditionDocuments,
    } = processedDocuments;
    return {
      documents: [
        ...brandPolicyConditionDocuments,
        ...productPolicyConditionDocuments,
        ...packagePolicyConditionDocuments,
      ],
      loading: documents.loading,
      refetchDocuments: documents.refetch,
      error: documents.error,
    };
  }, [documents]);

  return (
    <DocumentsContext.Provider value={context}>
      {children}
    </DocumentsContext.Provider>
  );
}

export default DocumentsProvider;
