import React from 'react';
import {
  Link,
  LinkKind,
  LinkVariant,
  PhosphorIcon,
  PhosphorIconWeight,
  Text,
  TextElement,
  TextKind,
} from 'design-system/components';

import { NextStepsV2Props } from './types';
import styles from './next-steps-cta.module.scss';
import { BrandColors } from 'design-system/data';

export const NextStepsCTA: React.FC<NextStepsV2Props> = ({
  cardHeading,
  phosphorIconName,
  descriptiveText,
  ctaButtonText,
  redirectPath,
  linkKind,
}) => {
  return (
    <div className={styles['pillar-dashboard-card']}>
      <div className={styles['pillar-dashboard-content']}>
        <div className={styles['pillar-dashboard-header']}>
          <div className={styles['policy-agreement-icon']}>
            {' '}
            <PhosphorIcon
              iconName={phosphorIconName}
              weight={PhosphorIconWeight.Regular}
              size={32}
              color={BrandColors.Gray600}
            />
          </div>
          <Text
            kind={TextKind.Display2XSMedium}
            element={TextElement.H3}
            color={BrandColors.Gray900}
          >
            {cardHeading}
          </Text>
        </div>
        <div>
          {' '}
          <Text
            kind={TextKind.TextMD}
            element={TextElement.P}
            color={BrandColors.Gray600}
          >
            {descriptiveText}
          </Text>
        </div>
      </div>
      <div className={styles['pillar-dashboard-cta']}>
        {linkKind == LinkKind.Internal && (
          <Link
            kind={LinkKind.Internal}
            variant={LinkVariant.Primary}
            to={redirectPath}
          >
            <span className={styles.cta}>
              {ctaButtonText}
              <span className={styles['icon-container']}>
                <PhosphorIcon iconName="ArrowRight" size={20} />
              </span>
            </span>
          </Link>
        )}
        {linkKind == LinkKind.External && (
          <Link
            kind={LinkKind.External}
            variant={LinkVariant.Primary}
            href={redirectPath}
          >
            <span className={styles.cta}>
              {ctaButtonText}
              <span className={styles['icon-container']}>
                <PhosphorIcon iconName="ArrowRight" size={20} />
              </span>
            </span>
          </Link>
        )}
      </div>
    </div>
  );
};

export default NextStepsCTA;
