import { TextElement, TextKind } from './constants';
import styles from './text.module.scss';
import propTypes from 'prop-types';
import { Color } from 'design-system/data';
import classNames from 'classnames';

/**
 * @param {{ [x: string]: any; children?: any; kind?: any; element?: any; color?: any; className?: any; }} props
 */
function Text(props) {
  const { kind, element, color, className, ...otherProps } = props;
  const Element = element;
  const classes = classNames(styles[kind], color && color.name, className);

  return Object.values(TextKind).includes(kind) &&
    Object.values(TextElement).includes(element) ? (
    <Element className={classes} {...otherProps}>
      {props.children}
    </Element>
  ) : null;
}

Text.propTypes = {
  kind: propTypes.oneOf(Object.values(TextKind)).isRequired,
  element: propTypes.oneOf(Object.values(TextElement)).isRequired,
  className: propTypes.string,
  [`data-cy`]: propTypes.string,
  id: propTypes.string,
  color: propTypes.oneOf(Object.values(Color)),
  children: propTypes.any,
};

export default Text;
