import {
  Avatar,
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Pill,
  PillColor,
  PhosphorIcon,
} from 'design-system/components';
import { determineInitials } from 'design-system/utils';
import styles from './eligibility-status-table.module.scss';
import { useEligibilityContext } from './EligibilityContext';
import { PolicyType, EligibilityContextType } from './EligibilityContext';
import ScreeningStatusPill from 'components/ScreeningStatusPill';

type PolicyTableRowProps = {
  policy: PolicyType;
};

function DocumentsPageEligibilityStatusTable() {
  const { containedPolicies, formulationPolicies } =
    useEligibilityContext() as EligibilityContextType;

  return (
    <div className={styles['ulta-dashboard-table']}>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell>Pillar</TableHeadCell>
            <TableHeadCell>Signed</TableHeadCell>
            <TableHeadCell>Status</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formulationPolicies?.map((policy: PolicyType) => {
            return <PolicyTableRow policy={policy} />;
          })}
          {containedPolicies.map((policy: PolicyType) => {
            return <PolicyTableRow policy={policy} />;
          })}
        </TableBody>
      </Table>
    </div>
  );
}

export default DocumentsPageEligibilityStatusTable;

const PolicyTableRow = ({ policy }: PolicyTableRowProps) => {
  return (
    <TableRow key={policy.id}>
      <TableBodyCell>
        <div className={styles['avatar-cell']}>
          <Avatar
            initials={determineInitials(policy.name)}
            src={policy.imagePath}
          />
          {policy.name}
        </div>
      </TableBodyCell>
      <TableBodyCell>
        {policy.isEnrolled ? (
          <Pill color={PillColor.Success}>
            <PhosphorIcon size={12} iconName="PencilLine" />
            Signed
          </Pill>
        ) : (
          <Pill color={PillColor.ErrorColor}>
            <PhosphorIcon size={12} iconName="X" />
            Not signed
          </Pill>
        )}
      </TableBodyCell>
      <TableBodyCell>
        <ScreeningStatusPill status={policy.status} />
      </TableBodyCell>
    </TableRow>
  );
};
