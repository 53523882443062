import { useRef } from 'react';
import {
  Button,
  ButtonKind,
  Text,
  TextElement,
  TextKind,
  Toast,
  ToastKind,
} from 'design-system/components';
import styles from './copy-links.module.scss';
import propTypes from 'prop-types';
import { handleKeyboardEvent, KeyboardCode } from 'design-system/utils';

function CopyLinks(props) {
  const toastRef = useRef();

  function copyToClipboard(urlToCopy) {
    navigator.clipboard.writeText(urlToCopy);
    toastRef.current.launchToast();
  }

  return (
    <>
      <Text kind={TextKind.TextSMBold} element={TextElement.Span}>
        {props.label}
      </Text>
      <div className={styles.container}>
        {props.data &&
          props.data.map((item, index) => (
            <div key={`item-${index}`} className={styles['item-container']}>
              <div className={styles.item}>
                <Text kind={TextKind.TextMD} element={TextElement.Span}>
                  {item.stringToDisplay}
                </Text>
                <Button
                  kind={ButtonKind.Alternate}
                  onClick={() => copyToClipboard(item.stringToCopy)}
                  onKeyDown={(event) => {
                    handleKeyboardEvent(
                      event,
                      [KeyboardCode.Enter],
                      copyToClipboard,
                      item.stringToCopy
                    );
                  }}
                >
                  {props.ctaLabel}
                </Button>
              </div>
              {index + 1 !== props.data.length && (
                <span className={styles.divider} />
              )}
            </div>
          ))}
      </div>
      <Toast ref={toastRef} kind={ToastKind.Success} title="Success">
        <Text kind={TextKind.TextMD} element={TextElement.P}>
          {props.successMessage}
        </Text>
      </Toast>
    </>
  );
}

CopyLinks.propTypes = {
  label: propTypes.string.isRequired,
  data: propTypes.arrayOf(
    propTypes.shape({
      stringToDisplay: propTypes.string.isRequired,
      stringToCopy: propTypes.string.isRequired,
    })
  ).isRequired,
  ctaLabel: propTypes.string.isRequired,
  successMessage: propTypes.string.isRequired,
};

export default CopyLinks;
