import { useState, useRef } from 'react';
import {
  Icon,
  IconName,
  Text,
  TextElement,
  TextKind,
  Button,
  ButtonKind,
} from 'design-system/components';
import styles from './create-user-via-email.module.scss';
import propTypes from 'prop-types';
import { handleKeyboardEvent, KeyboardCode } from 'design-system/utils';
import { Color } from 'design-system/data';
import { InputContainerClass } from './constants';

function CreateUserViaEmail(props) {
  const inputRef = useRef();
  const [inputValue, setInputValue] = useState('');
  const [inputContainerClass, setInputContainerClass] = useState(
    InputContainerClass.Default
  );
  const [hasError, setHasError] = useState(false);
  const inputID = 'email-address';

  function validateEmailAddress() {
    const emailRegEx = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (inputValue.match(emailRegEx)) {
      setHasError(false);
      setInputContainerClass(InputContainerClass.Default);
      props.onSubmit(inputValue);
      inputRef.current.value = '';
    } else {
      setHasError(true);
      setInputContainerClass(InputContainerClass.Error);
    }
    setInputValue('');
  }

  return (
    <div className={styles.container}>
      <label htmlFor={inputID}>{props.label}</label>
      <div className={styles[inputContainerClass]}>
        <input
          className={styles.input}
          ref={inputRef}
          type="text"
          name={inputID}
          id={inputID}
          aria-describedby="error"
          placeholder="Enter an email..."
          onChange={(event) => setInputValue(event.target.value)}
        ></input>
        <Button
          kind={ButtonKind.Alternate}
          onClick={validateEmailAddress}
          onKeyDown={(event) => {
            handleKeyboardEvent(
              event,
              [KeyboardCode.Enter],
              validateEmailAddress
            );
          }}
        >
          Create account
        </Button>
      </div>
      {hasError && (
        <div
          className={styles.error}
          role="alert"
          id="error"
          aria-live="polite"
        >
          <Icon name={IconName.Alert} size={16} color={Color.Orange800} />
          <Text
            kind={TextKind.TextSM}
            element={TextElement.Span}
            color={Color.Orange800}
          >
            Please enter a valid email address
          </Text>
        </div>
      )}
    </div>
  );
}

CreateUserViaEmail.propTypes = {
  label: propTypes.string.isRequired,
  onSubmit: propTypes.func.isRequired,
};

export default CreateUserViaEmail;
