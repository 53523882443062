export const UrlParam = {
  Sort: 'sort',
  Direction: 'direction',
  Page: 'page',
  Items: 'items',
  Status: 'status',
  ConsumerBrandIds: 'consumer_brand_ids',
  ConsumerProductIds: 'consumer_product_ids',
  ConsumerProductIdsArray: 'consumer_product_ids',
  FormulationPolicyIds: 'formulation_policy_ids',
  ConsumerProductType: 'consumer_product_type',
  SkuId: 'sku_ids',
  Term: 'term',
  ProductName: 'product_name',
  PillarIds: 'pillar',
};

export const RequestMethod = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
  PATCH: 'PATCH',
};
