import propTypes from 'prop-types';
import {
  Tooltip,
  Icon,
  IconName,
  OpenAxis,
  Text,
  TextElement,
  TextKind,
} from 'design-system/components';
import { Color } from 'design-system/data';

function ToolTips({ tooltipMessage }) {
  return (
    <Tooltip
      openAxis={OpenAxis.X}
      triggerElement={
        <Icon name={IconName.Info} size={12} color={Color.Blue600} />
      }
    >
      <Text element={TextElement.P} kind={TextKind.TextMD}>
        {tooltipMessage}
      </Text>
    </Tooltip>
  );
}

ToolTips.propTypes = {
  tooltipMessage: propTypes.string.isRequired,
};

export default ToolTips;
