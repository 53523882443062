import {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import { enqueueSnackbar } from 'notistack';
import * as Sentry from '@sentry/react';

import Dialog from 'components/Dialog';
import useFetcher from 'services/api/useFetcher';
import { useApp } from 'context/AppContext';
import { SkuUploadSteps } from '../components/SkuUploadSteps';
import getCsvValidationConfig from '../upload-validations/csvValidationConfig';
import { validateCSV } from '../upload-validations/utils';
import { useYourProductContext } from './index';
import { usePaywallContext } from './PaywallContext';

const UploadSkuContext = createContext(null);

export const useUploadSkuContext = () => {
  const ctx = useContext(UploadSkuContext);
  if (!ctx) {
    throw new Error('Must be used within an UploadSkuContext');
  }
  return ctx;
};

export const UploadSkuProvider = ({ children }) => {
  const [currentProcessingFiles, setCurrentProcessingFiles] = useState([]);
  const [openBulkUpload, setBulkUploadOpen] = useState(false);
  const fetcher = useFetcher();
  const { user, retailerBrand } = useApp();
  const { shouldOpenPaywall, bulk_upload_included } = useYourProductContext();
  const { openPaywall } = usePaywallContext();
  const [validationErrors, setValidationErrors] = useState([]);
  const genericErrorMsg = 'Please try again or contact support@noviconnect.com';

  const uploadFileDirectly = (file, retailerConsumerBrandId) => {
    let formdata = new FormData();

    formdata.append('file', file);

    return fetcher(
      `/api/v4/upload_skus/upload_file?retailer_consumer_brand_id=${retailerConsumerBrandId}&file_name=${file.name}`,
      {
        method: 'POST',
        body: formdata,
      }
    );
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const csvValidationConfig = getCsvValidationConfig({
        ingredientsOptional:
          retailerBrand?.retailer?.allows_products_without_ingredients,
      });
      const csvErrors = await validateCSV(acceptedFiles, csvValidationConfig);
      setValidationErrors(csvErrors);
      if (csvErrors.length) {
        return;
      }

      const file = acceptedFiles[0];
      const { last_selected_retailer_brand_id } = user;

      try {
        await uploadFileDirectly(file, last_selected_retailer_brand_id);
      } catch (e) {
        enqueueSnackbar(
          `An unexpected error occurred when uploading your file. ${genericErrorMsg}`,
          { variant: 'error' }
        );
        Sentry.captureException(e);
      }

      // Update session storage and internal state
      try {
        const filesProcessing =
          JSON.parse(sessionStorage.getItem('fileNames')) || [];
        const filesArray = filesProcessing.concat(file.name);
        const allProcessing = JSON.stringify(filesArray);
        sessionStorage.setItem('fileNames', allProcessing);
        setCurrentProcessingFiles(filesArray);
        setBulkUploadOpen(false);
      } catch (e) {
        enqueueSnackbar(`An unexpected error occurred. ${genericErrorMsg}`, {
          variant: 'error',
        });
        console.error(
          'Unexpected erro occurred when updating session storage:',
          e
        );
        Sentry.captureException(e);
        return;
      }

      enqueueSnackbar(
        'File uploaded. You will receive an email when it is finished processing.',
        { variant: 'success' }
      );
    },
    [fetcher, user, retailerBrand]
  );

  useEffect(() => {
    setCurrentProcessingFiles(
      JSON.parse(sessionStorage.getItem('fileNames')) || []
    );
  }, []);
  const context = useMemo(() => {
    return {
      currentProcessingFiles,
      onDrop,
      shouldOpenPaywall,
      setBulkUploadOpen: () =>
        shouldOpenPaywall || !bulk_upload_included
          ? openPaywall()
          : setBulkUploadOpen(true),
      validationErrors,
    };
  }, [
    currentProcessingFiles,
    onDrop,
    setBulkUploadOpen,
    shouldOpenPaywall,
    openPaywall,
    bulk_upload_included,
    validationErrors,
  ]);

  return (
    <UploadSkuContext.Provider value={context}>
      {children}
      <Dialog open={openBulkUpload} onClose={() => setBulkUploadOpen(false)}>
        <SkuUploadSteps />
      </Dialog>
    </UploadSkuContext.Provider>
  );
};
