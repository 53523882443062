import { RetailerProductsProvider } from '../context';
import RetailerSkuMetricsDashboard from '../components/metrics-dashboard';

function RetailerSkuMetrics() {
  return (
    <RetailerProductsProvider>
      <RetailerSkuMetricsDashboard />
    </RetailerProductsProvider>
  );
}

export default RetailerSkuMetrics;
