import {
  NoData,
  TextElement,
  TextKind,
  Text,
  ButtonKind,
  Button,
  ButtonIconPosition,
  PillarOverviewCard,
  Pill,
  PillColor,
} from 'design-system/components';
import { BrandPolicySubmissionStatus, Color } from 'design-system/data';
import styles from './policies.module.scss';
import { array, bool, func } from 'prop-types';

const hasNonEmptyRequirements = (requirementGroup) =>
  requirementGroup?.totalRequirements !== 0;

const RequirementGroups = ({
  loading,
  policies,
  error,
  handleReview,
  handleApprove,
  handleReject,
}) => {
  const RGNoData = () => (
    <NoData
      hasErrorOccurred={!!error}
      errorMessage="Something went wrong while loading your submissions. Please check back later"
      noContentMessage="Nothing to see here yet!"
      description="No SKUs have been uploaded and the brand has not started the attestation process"
      isLoading={loading}
    />
  );

  if (!policies || loading) {
    return <RGNoData />;
  }

  const orderedPolicies = policies.sort((a, b) => {
    if (a.totalRequirements === 0) {
      return 1;
    }
    if (b.totalRequirements === 0) {
      return -1;
    }
    return 0;
  });

  return (
    !!orderedPolicies.length &&
    orderedPolicies.map((policy) => {
      return (
        <div key={policy.id} className={styles.policy}>
          <div className={styles.title}>
            <Text element={TextElement.H2} kind={TextKind.DisplayXSMedium}>
              {policy.name}
            </Text>
            {policy.totalRequirements !== 0 && (
              <div className={styles['action-buttons']}>
                {(policy.submissionStatus ===
                  BrandPolicySubmissionStatus.Pending ||
                  policy.submissionStatus ===
                    BrandPolicySubmissionStatus.Ready) && (
                  <>
                    <Button
                      disabled={
                        policy.submissionStatus ===
                        BrandPolicySubmissionStatus.Pending
                      }
                      kind={ButtonKind.Error}
                      usePhosphorIcon
                      iconName="X"
                      iconSize={20}
                      iconColor={Color.Red700}
                      iconPosition={ButtonIconPosition.Right}
                      onClick={() => handleReject(policy.policySubmission?.id)}
                    >
                      Reject brand
                    </Button>
                    <Button
                      disabled={
                        policy.submissionStatus ===
                        BrandPolicySubmissionStatus.Pending
                      }
                      kind={ButtonKind.Primary}
                      usePhosphorIcon
                      iconName="Check"
                      iconSize={20}
                      iconPosition={ButtonIconPosition.Right}
                      onClick={() => handleApprove(policy.policySubmission?.id)}
                    >
                      Accept brand
                    </Button>
                  </>
                )}
                {policy.submissionStatus ===
                  BrandPolicySubmissionStatus.Approved && (
                  <Pill color={PillColor.Green}>
                    <Text kind={TextKind.TextSMBold} element={TextElement.Span}>
                      Accepted
                    </Text>
                  </Pill>
                )}
                {policy.submissionStatus ===
                  BrandPolicySubmissionStatus.Rejected && (
                  <Pill color={PillColor.Red}>
                    <Text kind={TextKind.TextSMBold} element={TextElement.Span}>
                      Rejected
                    </Text>
                  </Pill>
                )}
              </div>
            )}
          </div>
          {policy.totalRequirements !== 0 &&
            policy.retailerPolicyRequirementGroups
              .filter(hasNonEmptyRequirements)
              .map((group) => {
                const progress = [
                  { label: 'Complete', percentage: group.percentComplete },
                ];
                return (
                  <PillarOverviewCard
                    key={group.id}
                    id={group.id}
                    title={group.name}
                    description={group.description}
                    imagePath={group.imagePath}
                    progress={progress}
                    screeningStatus={group.screeningResult}
                    onClick={handleReview}
                    buttonKind={ButtonKind.Alternate}
                    buttonText="Review"
                  />
                );
              })}

          {policy.totalRequirements === 0 && <RGNoData />}
        </div>
      );
    })
  );
};

RequirementGroups.propTypes = {
  loading: bool.isRequired,
  policies: array.isRequired,
  error: bool.isRequired,
  handleReview: func.isRequired,
  handleApprove: func.isRequired,
  handleReject: func.isRequired,
};

export default RequirementGroups;
