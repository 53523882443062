import { createContext, useContext, useMemo, ReactNode, FC } from 'react';

interface CreateProductContextType {
  // Add any properties/functions that are part of the context here.
}

const CreateProductContext = createContext<CreateProductContextType | null>(
  null
);

export const useCreateProductContext = () => {
  const ctx = useContext(CreateProductContext);
  if (!ctx) {
    throw new Error('Must be used within a CreateProductContext');
  }
  return ctx;
};

interface CreateProductProviderProps {
  children: ReactNode;
}

export const CreateProductProvider: FC<CreateProductProviderProps> = ({
  children,
}) => {
  const context = useMemo((): CreateProductContextType => {
    return {
      // Add any values for the context here
    };
  }, []);

  return (
    <CreateProductContext.Provider value={context}>
      {children}
    </CreateProductContext.Provider>
  );
};
