import {
  isValidUniqueId,
  isValidProductName,
  isValidIntendedUse,
  isValidIntendedApplication,
  isValidProductFormat,
  isValidAdditionalProductDetails,
  isValidFlavor,
  isValidFragrance,
  isValidIngredients,
} from './utils';
import {
  validIntendedApplications,
  productFormatOptions,
  additionalProductDetailsOptions,
} from './constants';

const getCsvValidationConfig = ({ ingredientsOptional = false } = {}) => ({
  headers: [
    {
      name: 'Unique ID',
      inputName: 'Unique ID',
      validate: function (uniqueId) {
        return isValidUniqueId(uniqueId);
      },
      validateError: function (headerName, rowNumber) {
        return `Row ${rowNumber}, ${headerName} is not valid. Please enter a Unique ID between 1 and 60 characters. Only letters, numbers, and dashes are allowed.`;
      },
    },
    {
      name: 'Product Name',
      inputName: 'Product Name',
      validate: function (productName) {
        return isValidProductName(productName);
      },
      validateError: function (headerName, rowNumber) {
        return `Row ${rowNumber}, ${headerName} is not valid. Please enter a Product Name between 1 and 1000 characters.`;
      },
    },
    {
      name: 'Intended Use',
      inputName: 'Intended Use',
      validate: function (intendedUse) {
        return isValidIntendedUse(intendedUse);
      },
      validateError: function (headerName, rowNumber) {
        return `Row ${rowNumber}, ${headerName} column value is not valid. Please enter either "Leave on" or "Rinse off."`;
      },
    },
    {
      name: 'Intended Application',
      inputName: 'Intended Application',
      validate: function (intendedApplication) {
        return isValidIntendedApplication(intendedApplication);
      },
      validateError: function (headerName, rowNumber) {
        const quotedOptions = validIntendedApplications.map(
          (option) => `"${option}"`
        );
        return `Row ${rowNumber}, ${headerName} column value is not valid. Please enter one or more of the following:
          ${quotedOptions.join(', ')}
        `;
      },
    },
    {
      name: 'Product Format',
      inputName: 'Product Format',
      validate: function (productFormat) {
        return isValidProductFormat(productFormat);
      },
      validateError: function (headerName, rowNumber) {
        const quotedOptions = productFormatOptions.map(
          (option) => `"${option}"`
        );
        return `Row ${rowNumber}, ${headerName} column value is not valid. Please enter one of the following:
          ${quotedOptions.join(', ')}
        `;
      },
    },
    {
      name: 'Additional Product Details',
      inputName: 'Additional Product Details',
      optional: true,
      validate: function (additionalProductDetails) {
        return isValidAdditionalProductDetails(additionalProductDetails);
      },
      validateError: function (headerName, rowNumber) {
        const quotedOptions = additionalProductDetailsOptions.map(
          (option) => `"${option}"`
        );
        return `Row ${rowNumber}, optional ${headerName} column value is not valid. Please enter one of the following, or leave blank:
          ${quotedOptions.join(', ')}
        `;
      },
    },
    {
      name: 'Flavor',
      inputName: 'Flavor',
      validate: function (flavor) {
        return isValidFlavor(flavor);
      },
      validateError: function (headerName, rowNumber) {
        return `Row ${rowNumber}, ${headerName} column value is not valid. Please enter either "Yes" or "No"`;
      },
    },
    {
      name: 'Fragrance',
      inputName: 'Fragrance',
      validate: function (fragrance) {
        return isValidFragrance(fragrance);
      },
      validateError: function (headerName, rowNumber) {
        return `Row ${rowNumber}, ${headerName} column value is not valid. Please enter either "Yes" or "No"`;
      },
    },
    {
      name: 'Ingredients',
      inputName: 'Ingredients',
      validate: function (ingredients) {
        return ingredientsOptional || isValidIngredients(ingredients);
      },
      validateError: function (headerName, rowNumber) {
        return `Row ${rowNumber}, ${headerName} column value is not valid. Please enter a list of comma separated ingredients.`;
      },
    },
  ],
});

export default getCsvValidationConfig;
