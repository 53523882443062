export const DASHBOARD_NEXT_STEPS_DEFAULT_TEXT = {
  signPillars:
    'The pillar agreements are legal documents from your retailer, sent to your email from contracts@noviconnect.com. These agreements must be signed as part of your work in Novi. Once signed, you can review your pillar agreements in Documents.',
  attestations:
     'You must complete product verification questions, or attestations, for each SKU that you would like to make claims for.',
  payment:
    'Please complete payment for your Novi Connect subscription to fully enroll in this program. If you have not already received an invoice or if you have any questions, please reach out to us at support@noviconnect.com.',
};

export const DASHBOARD_NEXT_STEPS_HEADINGS = {
  signPillars: 'Sign the pillar agreements',
  attestations: 'Input your attestations',
  payment: 'Complete payment',
};

export const DASHBOARD_NEXT_STEPS_CTA_TEXT = {
  signPillars: 'Review',
  attestations: 'Attest',
  payment: 'Get in touch',
  
};
