import { UrlParam } from 'design-system/data';
import { convertSnakeToCamelCase } from 'design-system/utils';

import { useApp } from 'context/AppContext';
import useQueryParams from 'hooks/useQueryParams';
import { TableDataResponseRaw, TableDataResponse } from '../data/types';

const useSKUEligibilityResults = (
  retailerId: number,
  brandId: number,
  policyId: number
): { data: TableDataResponse; loading?: boolean; error?: any } => {
  const { useApi } = useApp();
  const params = useQueryParams(Object.values(UrlParam));
  const endpoint = `/api/v4/retailers/${retailerId}/consumer_brands/${brandId}/policies/${policyId}/sku_eligibility_results`;

  const { data, error, loading } = useApi(endpoint, {
    enabled: Boolean(retailerId && brandId && policyId),
    param: {
      ...(params ? (params as any)._asValues() : {}),
    },
  });

  const typedData = data as TableDataResponseRaw;

  return {
    data: convertSnakeToCamelCase(typedData),
    error,
    loading,
  };
};

export default useSKUEligibilityResults;
