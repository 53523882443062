import { useMemo } from 'react';
import emojis from 'constants/emoji';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const Emoji = styled(function Emoji(props) {
  const { children, name, axis, space, size, margin, ...rest } = props;
  const label = name ? `${name.replace('_', ' ')} emoji` : undefined;
  const emoji = name ? emojis[name] : null;

  const style = useMemo(() => {
    return {
      '--space': typeof space === 'number' ? `${space}px` : space,
      '--size': typeof size === 'number' ? `${size}px` : size,
      '--margin': typeof margin === 'number' ? `${margin}px` : margin,
    };
  }, [space, size, margin]);

  return (
    <Span
      role="img"
      aria-label={label}
      data-axis={axis}
      style={style}
      {...rest}
    >
      {emoji}
      {children}
    </Span>
  );
})`
  font-size: var(--size);
  &[data-axis='horizontal'] {
    padding-right: var(--space);
    padding-left: var(--space);
    margin-right: var(--margin, 0.25em);
  }
  &[data-axis='vertical'] {
    padding-top: var(--space);
    padding-bottom: var(--space);
    margin-bottom: var(--margin, 0.25em);
  }
`;

const Span = styled('span')``;

Emoji.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  axis: PropTypes.oneOf(['horizontal', 'vertical']),
  space: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Emoji.defaultProps = {
  children: null,
  name: undefined,
  axis: 'horizontal',
  space: '.25em',
  size: 'inherit',
};

export default Emoji;
