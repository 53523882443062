import { Switch, Route } from 'react-router-dom';
import { PATHS } from 'constants/index';
import { useApp } from 'context/AppContext';
import PolicySelectionPage from './PolicySelectionPage';
import SKURequirementsPage from './SKURequirementsPage';
import SKULevelStatusPage from './SKULevelStatusPage';

const RetailerBrandEligibility = () => {
  const { user } = useApp();
  const hasSKURequirementsPage = user?.hasFF('sku_requirements_page');
  return (
    <Switch>
      <Route
        path={PATHS.retailerBrandEligibility}
        component={PolicySelectionPage}
        secure
        exact
      />
      <Route
        path={PATHS.retailerBrandSKULevelStatus}
        component={SKULevelStatusPage}
        secure
        exact
      />
      {hasSKURequirementsPage && (
        <Route
          path={PATHS.retailerBrandSKURequirements}
          component={SKURequirementsPage}
          secure
          exact
        />
      )}
    </Switch>
  );
};

export default RetailerBrandEligibility;
