import { RetailerBrandsProvider } from '../context';
import RetailerBrandMetricsDashboard from '../components/metrics-dashboard';

function RetailerBrandMetrics() {
  return (
    <RetailerBrandsProvider>
      <RetailerBrandMetricsDashboard />
    </RetailerBrandsProvider>
  );
}

export default RetailerBrandMetrics;
