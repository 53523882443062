import { PillColor } from '../components/atoms/pill/constants';

export const SubmissionStatus = {
  Pending: 'pending',
  InProgress: 'in_progress',
  Approved: 'approved',
  Rejected: 'rejected',
  All: 'all',
} as const;

export type SubmissionStatusType = keyof typeof SubmissionStatus;

export const SubmissionStatusLabel: Record<SubmissionStatusType, string> = {
  Pending: 'Pending review',
  InProgress: 'In progress',
  Approved: 'Accepted',
  Rejected: 'Rejected',
  All: 'All',
};

export interface PillData {
  label: string;
  color: string;
}

export const SubmissionStatusPillData: Record<SubmissionStatusType, PillData> =
  {
    Pending: {
      label: SubmissionStatusLabel.Pending,
      color: PillColor.Orange,
    },
    InProgress: {
      label: SubmissionStatusLabel.InProgress,
      color: PillColor.Default,
    },
    Approved: {
      label: SubmissionStatusLabel.Approved,
      color: PillColor.Green,
    },
    Rejected: {
      label: SubmissionStatusLabel.Rejected,
      color: PillColor.Red,
    },
    All: {
      label: SubmissionStatusLabel.All,
      color: PillColor.Purple,
    },
  };
