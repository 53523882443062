export const LinkKind = {
  Internal: 'internal',
  External: 'external',
};

export const LinkVariant = {
  NewDefault: 'new-default',
  Default: 'default',
  Primary: 'primary',
  Secondary: 'secondary',
  Tertiary: 'tertiary',
  Pill: 'pill',
  Alternate: 'alternate',
  Success: 'success',
  Error: 'error',
};

export const LinkErrorMsg = {
  HREF: 'Link kind external requires an `href` value.',
  To: 'Link kind internal requires a `to` value.',
  LinkKind: 'Link kind must be specified as internal or external',
};
