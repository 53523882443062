import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  ButtonKind,
  IconName,
  Icon,
  BrandViewHeader,
  Modal,
  ToastManager,
  ToastKind,
} from 'design-system/components';
import { BrandPolicySubmissionStatus } from 'design-system/data';

import { useApp } from 'context/AppContext';
import { reviewBrand, usePolicies } from './usePolicies';
import ReviewModalContent from '../../components/ReviewModalContent';
import RequirementGroups from './RequirementGroups';
import { generateBrandPolicyExport } from '../BrandSubmissions/useBrandSubmissions';

function Policies() {
  const { brand_id } = useParams();
  const { data, loading, error } = usePolicies(brand_id);
  const history = useHistory();
  const { user } = useApp();
  const [toastData, setToastData] = useState([]);
  const [noteValue, setNoteValue] = useState();
  const [submissionId, setSubmissionId] = useState();
  const consumerBrand = data?.consumerBrand;
  const policies = data?.policies;
  const breadcrumbItems = [
    {
      text: 'Brand Submissions',
      link: '/retailer/brands/submissions',
    },
    {
      text: consumerBrand?.name,
      link: history.location.pathname,
    },
  ];
  const [openApproveModal, setOpenApprovalModal] = useState(false);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const ApproveIcon = <Icon name={IconName.CheckGreenRound} size={48} />;
  const RejectIcon = <Icon name={IconName.CancelCircleFilled} size={48} />;

  const handleReview = (requirement_group_id) =>
    history.push(
      `/retailer/brands/submissions/${brand_id}/requirement_groups/${requirement_group_id}/retailer_requirements`
    );

  const userHasExportEnabled = user.hasFF('policy_export_button_visible');

  const brandInProgress =
    policies?.every(({ policySubmission }) => {
      return !policySubmission || policySubmission.id === null;
    }) ?? true;

  const canSeeExport = userHasExportEnabled && !brandInProgress;

  const retailerId = user?.organization?.in_orgable_id;
  const onExportPolicy = () => {
    const onSuccess = () => {
      setToastData((prevToastData) => [
        ...prevToastData,
        {
          kind: ToastKind.Success,
          title: 'Success',
          message:
            'Policies export started. You should receive an email shortly.',
          autoDismiss: true,
        },
      ]);
    };
    const onFailure = () => {
      setToastData((prevToastData) => [
        ...prevToastData,
        {
          kind: ToastKind.Error,
          title: 'Error',
          message: 'Policy export failed. Please try again or contact support.',
          autoDismiss: true,
        },
      ]);
    };
    generateBrandPolicyExport({
      onSuccess,
      onFailure,
      retailerId,
      consumerBrandId: brand_id,
    });
  };

  return (
    <div>
      <Modal
        show={openApproveModal}
        title={ApproveIcon}
        showActionButtons
        onDone={async () => {
          await reviewBrand(
            submissionId,
            BrandPolicySubmissionStatus.Approved,
            noteValue
          );
          setToastData((prevToastData) => [
            ...prevToastData,
            {
              kind: ToastKind.Success,
              title: 'Success',
              message: `You have accepted this brand! Refresh your page to see the updated submission status.`,
              autoDismiss: false,
            },
          ]);
          setOpenApprovalModal(false);
        }}
        onCancel={() => setOpenApprovalModal(false)}
        onClick={() => setOpenApprovalModal(false)}
        allowDismiss
        doneCtaLabel="Accept"
        doneCtaKind={ButtonKind.Success}
      >
        <ReviewModalContent
          onChangeNote={(value) => setNoteValue(value)}
          title="Accept this brand?"
          content="Once you do this, the brand will be notified that you have accepted this submission."
        />
      </Modal>
      <Modal
        show={openRejectModal}
        title={RejectIcon}
        showActionButtons
        onDone={async () => {
          await reviewBrand(
            submissionId,
            BrandPolicySubmissionStatus.Rejected,
            noteValue
          );
          setToastData((prevToastData) => [
            ...prevToastData,
            {
              kind: ToastKind.Success,
              title: 'Success',
              message: `You have rejected this brand. Refresh your page to see the updated submission status.`,
              autoDismiss: false,
            },
          ]);
          setOpenRejectModal(false);
        }}
        onCancel={() => setOpenRejectModal(false)}
        onClick={() => setOpenRejectModal(false)}
        allowDismiss
        doneCtaLabel="Reject"
        doneCtaKind={ButtonKind.Error}
      >
        <ReviewModalContent
          onChangeNote={(value) => setNoteValue(value)}
          title="Reject this brand?"
          content="Once you do this, the brand will be notified that you have rejected this submission."
        />
      </Modal>
      <BrandViewHeader
        title={consumerBrand?.name}
        subTitle=""
        breadcrumbsItems={breadcrumbItems}
        showFilterButton={false}
        showExportButton={canSeeExport}
        onExportClick={onExportPolicy}
      />
      <RequirementGroups
        loading={loading}
        error={error}
        policies={policies}
        handleApprove={(id) => {
          setSubmissionId(id);
          setOpenApprovalModal(true);
        }}
        handleReject={(id) => {
          setSubmissionId(id);
          setOpenRejectModal(true);
        }}
        handleReview={handleReview}
      />
      <ToastManager data={toastData} />
    </div>
  );
}

export default Policies;
