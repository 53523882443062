import styles from './table-head-cell.module.scss';
import propTypes from 'prop-types';
import { Icon, IconName, OpenAxis, Tooltip } from 'design-system/components';
import { ClassName, SortDirection } from './constants';
import { Color } from 'design-system/data';

function TableHeadCell(props) {
  function ChildComponent() {
    return !props.tooltipDescription ? (
      <>{props.children}</>
    ) : (
      <div className={styles.tooltip}>
        {props.children}
        <Tooltip
          openAxis={OpenAxis.Y}
          triggerElement={
            <Icon name={IconName.Info} color={Color.Blue600} size={16} />
          }
        >
          {props.tooltipDescription}
        </Tooltip>
      </div>
    );
  }

  return props.enableSort ? (
    <th scope="col">
      <span
        tabIndex={0}
        aria-sort={props.active ? props.direction : null}
        onClick={() => props.onClick()}
        className={
          styles[props.active ? ClassName.SortableActive : ClassName.Sortable]
        }
        data-cy={props['data-cy']}
      >
        <ChildComponent />
        <span
          aria-hidden={true}
          className={
            props.direction === SortDirection.Descending
              ? styles[ClassName.IconDesc]
              : undefined
          }
        >
          <span className={styles.caret}>
            <Icon name={IconName.Caret} size={16}></Icon>
          </span>
        </span>
      </span>
    </th>
  ) : (
    <th scope="col" className={styles.static} data-cy={props['data-cy']}>
      <ChildComponent />
    </th>
  );
}

TableHeadCell.propTypes = {
  enableSort: propTypes.bool,
  active: propTypes.bool,
  direction: propTypes.oneOf(Object.values(SortDirection)),
  onClick: propTypes.func,
  tooltipDescription: propTypes.string,
  children: propTypes.any.isRequired,
  [`data-cy`]: propTypes.string,
};

export default TableHeadCell;
