export const MAX_PRODUCTS = 1000;
export const MAX_UNIQUE_ID_LENGTH = 60;
export const MAX_PRODUCT_NAME_LENGTH = 1000;
export const fileTypeError =
  'Upload failed. Please try again with a .csv file.';
export const multipleFileError = `Upload failed. Please upload only one .csv file at a time.`;
export const noDataError = 'CSV Format Error: No data found in the file.';
export const maxProductsError = `Please note: Your CSV file contains more than the allowed ${MAX_PRODUCTS} products. Please reduce the amount of products and try again or reach out directly to support to handle additional uploads.`;

// source of truth found here https://core.noviconnect.com/science_dashboard/product/uses
export const validIntendedApplications = [
  'Face',
  'Body',
  'Hair',
  'Nails',
  'Eye',
  'Lip',
  'Oral',
  'Mucosa (Other)',
  'Hands',
  'Feet',
  'Nasal',
  'Scalp',
  'Armpit',
  'Bath',
  'Home Care',
];
export const lowerCaseValidIntendedApplications = validIntendedApplications.map(
  (item) => item.toLowerCase()
);

// source of truth found here https://core.noviconnect.com/science_dashboard/product/forms
export const productFormatOptions = [
  'Cream/Gel/Lotion/Paste',
  'Pressed Powder',
  'Wax/Stick',
  'Liquid',
  'Foam/Mousse',
  'Bar/Solid',
  'Aerosol (Pressurized)',
  'Loose Powder',
  'Pump spray/mists',
  'Sheet Mask',
];
export const lowerCaseValidFormatOptions = productFormatOptions.map((item) =>
  item.toLowerCase()
);
// source of truth found here https://core.noviconnect.com/science_dashboard/product/claims
export const additionalProductDetailsOptions = [
  'Aftershave',
  'Baby Care',
  'Color Cosmetic',
  'Depilatory',
  'Over The Counter (OTC)',
  'Self-Tanning',
  'Sunscreen/SPF Claim',
  'Wound Care',
  'None',
];
export const lowerCaseAdditionalProductDetailsOptions =
  additionalProductDetailsOptions.map((item) => item.toLowerCase());
