import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  BrandViewContentSection,
  Breadcrumbs,
  Button,
  ButtonIconPosition,
  ButtonKind,
  SearchBar,
  Modal,
  RadioGroup,
  Radio,
  PaginationV2,
  BrandViewHeader,
  ToastKind,
  ToastManager,
} from 'design-system/components';

import { useInitialQueryParams } from 'design-system/utils';

import {
  generateBrandPolicyExport,
  setUrlSearchParameters,
  useBrandSubmissions,
} from './useBrandSubmissions';
import { SubmissionStatus, SubmissionStatusPillData } from 'design-system/data';

import SubmissionContent from './submission-content';
import styles from './brand-submissions.module.scss';
import { useApp } from 'context/AppContext';

function BrandSubmissions() {
  const history = useHistory();
  const { user } = useApp();
  const [toastData, setToastData] = useState([]);
  const [activeTab, setActiveTab] = useState(
    useInitialQueryParams('status', SubmissionStatus.Pending)
  );

  const [currentPage, setCurrentPage] = useState(
    useInitialQueryParams('page', 1)
  );
  const { data: submissions, error, loading } = useBrandSubmissions();
  const statusCounts = submissions?.statusCounts;
  const breadcrumbItems = [
    {
      text: 'Brand Submissions',
      link: '/retailer/brands/submissions',
    },
  ];

  const tabData = [
    {
      value: SubmissionStatus.Pending,
      quantity: statusCounts?.pending,
      label: SubmissionStatusPillData.Pending.label,
      pillColor: SubmissionStatusPillData.Pending.color,
    },
    {
      value: SubmissionStatus.InProgress,
      quantity: statusCounts?.inProgress,
      label: SubmissionStatusPillData.InProgress.label,
      pillColor: SubmissionStatusPillData.InProgress.color,
    },
    {
      value: SubmissionStatus.Approved,
      quantity: statusCounts?.approved,
      label: SubmissionStatusPillData.Approved.label,
      pillColor: SubmissionStatusPillData.Approved.color,
    },
    {
      value: SubmissionStatus.Rejected,
      quantity: statusCounts?.rejected,
      label: SubmissionStatusPillData.Rejected.label,
      pillColor: SubmissionStatusPillData.Rejected.color,
    },
    {
      value: SubmissionStatus.All,
      quantity: statusCounts?.total,
      label: SubmissionStatusPillData.All.label,
      pillColor: SubmissionStatusPillData.All.color,
    },
  ];

  const [options, setOptions] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const onSearch = (val) => {
    setOptions(
      val
        ? submissions?.consumerBrandSearchOptions.filter((opt) =>
            opt.name.toLowerCase().includes(val.toLowerCase())
          )
        : submissions?.consumerBrands
    );
  };

  const handleSearchClick = (result) => {
    setSearchValue(result.name);
    onSearch(result.name);
  };

  const sortOptions = [
    { value: null, label: 'Sort by', iconName: 'ArrowsDownUp' },
    { value: 'alpha_asc', label: 'A to Z', iconName: 'ArrowDown' },
    { value: 'alpha_desc', label: 'Z to A', iconName: 'ArrowUp' },
    {
      value: 'date_desc',
      label: 'Submit date (Most recent)',
      iconName: 'ArrowDown',
    },
    {
      value: 'date_asc',
      label: 'Submit date (Least recent)',
      iconName: 'ArrowUp',
    },
  ];

  const [showModal, setShowModal] = useState(false);

  const [sortOption, setSortOption] = useState(sortOptions[0]);

  useEffect(() => {
    setUrlSearchParameters({
      status: activeTab,
      currentPage,
      history,
      term: searchValue,
      sort: sortOption.value,
    });
  }, [history, currentPage, activeTab, searchValue, sortOption.value]);

  const handleSort = (value) => {
    setSortOption(sortOptions.find((opt) => opt.value === value));
  };

  const canSeeExport = user.hasFF('policy_export_button_visible');
  const retailerId = user?.organization?.in_orgable_id;
  const onExportPolicy = () => {
    const onSuccess = () => {
      setToastData((prevToastData) => [
        ...prevToastData,
        {
          kind: ToastKind.Success,
          title: 'Success',
          message:
            'Policies export started. This may take a while. You will receive an email soon',
          autoDismiss: true,
        },
      ]);
    };
    const onFailure = () => {
      setToastData((prevToastData) => [
        ...prevToastData,
        {
          kind: ToastKind.Error,
          title: 'Error',
          message: 'Policy export failed. Please try again or contact support.',
          autoDismiss: true,
        },
      ]);
    };
    generateBrandPolicyExport({
      onSuccess,
      onFailure,
      retailerId,
    });
  };

  return (
    <div>
      <Modal
        title="Sort by"
        show={showModal}
        onClick={() => setShowModal(false)}
      >
        <RadioGroup
          onChange={(value) => handleSort(value)}
          name="brand-submission-sort"
          value={sortOption.value}
          errorMessage="There has been an error!"
        >
          {sortOptions.map((option) => {
            if (option.value === null) return null;
            return (
              <Radio
                key={option.value}
                value={option.value}
                label={option.label}
              />
            );
          })}
        </RadioGroup>
      </Modal>
      <Breadcrumbs items={breadcrumbItems} />
      <BrandViewHeader
        title="Submissions"
        showExportButton={canSeeExport}
        exportButtonText="Export Results for Submitted Brands"
        onExportClick={onExportPolicy}
        showFilterButton={false}
      />
      <BrandViewContentSection
        tabData={tabData}
        onTabChange={(activeTab) => {
          setActiveTab(activeTab);
          setOptions(null);
          setCurrentPage(1);
        }}
        activeTabIndex={tabData.findIndex((tab) => tab.value === activeTab)}
        tabContents={
          <>
            <div className={styles.filters}>
              <SearchBar
                placeholder="Search"
                results={options}
                onChange={onSearch}
                onClick={handleSearchClick}
                displayValue={searchValue}
                data-cy={`retailer-brand-submission-search-bar`}
                activeIDs={options?.map((opt) => opt.name)}
              />
              <Button
                className={styles.sort}
                usePhosphorIcon
                iconName={sortOption.iconName || 'ArrowsDownUp'}
                kind={ButtonKind.Link}
                onClick={() => setShowModal(true)}
                iconPosition={ButtonIconPosition.Left}
              >
                {sortOption.label || 'Sort by'}
              </Button>
            </div>
            <SubmissionContent
              brandSubmissions={submissions?.consumerBrands}
              loading={loading}
              error={error}
              retailerPolicyBrandScreeningStatus={
                submissions?.retailerPolicyBrandScreeningStatus
              }
              activeTab={activeTab}
            />
          </>
        }
      />
      <PaginationV2
        onNextPage={() => setCurrentPage(Number(currentPage) + 1)}
        onPreviousPage={() => setCurrentPage(Number(currentPage) - 1)}
        onPageSelect={(page) => setCurrentPage(Number(page))}
        totalItemCount={submissions?.count}
        currentPage={currentPage}
        itemsPerPage={10}
      />
      <ToastManager data={toastData} />
    </div>
  );
}

export default BrandSubmissions;
