import { convertSnakeToCamelCase } from 'design-system/utils';
import useApi from 'services/api/useApi';

const useRetailerPolicies = (retailerId) => {
  const { data, loading, error } = useApi(
    `/api/v4/retailers/${retailerId}/policies`,
    {
      enabled: !!retailerId,
    }
  );

  return {
    data: convertSnakeToCamelCase(data),
    loading,
    error,
  };
};

export default useRetailerPolicies;

export const useRetailer = (retailerId) => {
  const { data, loading, error } = useApi(`/api/v4/retailers/${retailerId}`, {
    enabled: !!retailerId,
  });

  return {
    data: convertSnakeToCamelCase(data),
    loading,
    error,
  };
};
