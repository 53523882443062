import {
  CloudinaryImg,
  Text,
  TextElement,
  TextKind,
} from 'design-system/components';

import { useApp } from 'context/AppContext';
import { useRetailerProductsContext } from '../context';

import { isRetailerWithQuicksight } from 'utils/retailer';
import { useMetabaseDashboardData } from 'views/Retailer/Hooks/useMetabaseDashboardData.tsx';

import QuickSightDashboard from 'components/QuicksightDashboard';
import MetabaseDashboard from 'components/MetabaseDashboard';

import styles from './metrics-dashboard.module.scss';

function RetailerSkuMetricsDashboard() {
  const { retailerID } = useRetailerProductsContext();
  const { user } = useApp();

  const isLocal = process.env.REACT_APP_API_BASE === 'http://localhost:3000/';
  const dashboardType = 'sku-metrics';

  const prodImg = 'v1716419273/demo-retailer-dashboard_maznau.png';
  const nonProdImg = 'v1716418969/demo-retailer-dashboard_znptzj.png';
  const demoImg = isLocal ? nonProdImg : prodImg;

  const hasAccess = user.hasFF('metabase_dashboard_view');

  const { hasDash, embedUrl, loading } = useMetabaseDashboardData(
    dashboardType,
    retailerID,
    hasAccess
  );

  // once a user gets the FF enabled, they should no longer see quicksight even without a change to the code (remove id from list of retailers with quicksight access)
  // chore: once a user does have FF enabled, eventually their id should be removed from the quicksight list
  const showQuicksightDashboard =
    retailerID && isRetailerWithQuicksight(retailerID) && !hasAccess;

  // in order to see a metabase dashboard, the user needs the feature flag enabled AND a dashboard needs to be set up
  const showMetabaseDashboard = hasDash && hasAccess;

  return (
    <>
      <Text
        element={TextElement.H1}
        kind={TextKind.TextMDBold}
        className={styles['text']}
      >
        SKU Metrics
      </Text>
      {!(showQuicksightDashboard || showMetabaseDashboard) ? (
        <CloudinaryImg
          src={demoImg}
          alt="Retailer SKU Metrics Dashboard"
          className={styles.image}
          fallbackElement={<div />}
        />
      ) : (
        <>
          {showQuicksightDashboard && (
            <QuickSightDashboard
              dashboardType={dashboardType}
              retailerID={retailerID}
            />
          )}
          {showMetabaseDashboard && (
            <MetabaseDashboard embedUrl={embedUrl} loading={loading} />
          )}
        </>
      )}
    </>
  );
}

export default RetailerSkuMetricsDashboard;
