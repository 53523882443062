import * as Select from '@radix-ui/react-select';

import {
  PhosphorIcon,
  PhosphorIconWeight,
  Text,
  TextKind,
  TextElement,
} from 'design-system/components';
import { Color } from 'design-system/data';

import styles from './select-v2.module.scss';
import SelectV2Props, {
  RootProps,
  ValueProps,
  ContentProps,
  SelectV2GroupProps,
  SelectV2ItemProps,
} from './constants.js';

export const SelectV2Item = (props) => {
  const { asChild, children } = props;
  return (
    <Select.Item {...props} className={styles.item}>
      <Select.ItemText asChild={asChild}>{children}</Select.ItemText>
      <Select.ItemIndicator asChild={asChild}>
        <PhosphorIcon
          iconName="Check"
          size={16}
          weight={PhosphorIconWeight.Bold}
          color={Color.Neutral900.value}
        />
      </Select.ItemIndicator>
    </Select.Item>
  );
};

SelectV2Item.propTypes = SelectV2ItemProps;

export const SelectV2Group = ({ asChild, children, label }) => {
  return (
    <Select.Group asChild={asChild}>
      {label && (
        <Select.Label asChild={asChild} className={styles.label}>
          <Text kind={TextKind.TextMDBold} element={TextElement.Span}>
            {label}
          </Text>
        </Select.Label>
      )}
      {children}
    </Select.Group>
  );
};

SelectV2Group.propTypes = SelectV2GroupProps;

const selectProps = (props, propTypes) => {
  return Object.keys(propTypes).reduce((acc, key) => {
    if (props[key] !== undefined) {
      acc[key] = props[key];
    }
    return acc;
  }, {});
};

const SelectV2 = (props) => {
  const rootProps = selectProps(props, RootProps);
  const valueProps = selectProps(props, ValueProps);
  const contentProps = selectProps(props, ContentProps);
  const { asChild, children, placeholder, defaultValue, value, container } =
    props;

  // placeholder prop and data attribute not working as expected so hand rolling ourselves
  const computedValue = value || defaultValue;
  const displayValue = computedValue || placeholder;
  return (
    <Select.Root {...rootProps}>
      <Select.Trigger
        style={props.triggerStyle}
        asChild={asChild}
        className={styles.trigger}
      >
        <Select.Value {...valueProps}>
          <Text
            className={!computedValue && placeholder ? styles.placeholder : ''}
            kind={TextKind.TextMDMedium}
            element={TextElement.Span}
          >
            {displayValue}
          </Text>
        </Select.Value>
        <Select.Icon className={styles['carrot-down']} asChild={asChild}>
          <PhosphorIcon
            iconName="CaretDown"
            color={Color.Neutral500.value}
            size={16}
            weight={PhosphorIconWeight.Bold}
          />
        </Select.Icon>
      </Select.Trigger>

      <Select.Portal container={container}>
        <Select.Content {...contentProps} className={styles.content}>
          <Select.ScrollUpButton asChild={asChild} />
          <Select.Viewport asChild={asChild}>{children}</Select.Viewport>
          <Select.ScrollDownButton asChild={asChild} />
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
};

SelectV2.propTypes = SelectV2Props;

SelectV2.defaultProps = {
  position: 'popper',
};

export default SelectV2;
