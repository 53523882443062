import propTypes from 'prop-types';
import { Pill, PillColor } from 'design-system/components';
import { PLACEHOLDER, ScreeningStatus } from 'design-system/data';

const retailerPolicyStatus = {
  fail: {
    label: 'Does not meet',
    color: PillColor.Red,
  },
  needs_information: {
    label: 'Needs attention',
    color: PillColor.Orange,
  },
  pass: {
    label: 'Meets',
    color: PillColor.Green,
  },
  needs_attention: {
    label: 'Needs attention',
    color: PillColor.Orange,
  },
  not_applicable: {
    label: 'Not applicable',
    color: PillColor.Default,
  },
};

export function PolicyStatusPill({ status }) {
  if (!status) return PLACEHOLDER;
  return (
    <Pill color={retailerPolicyStatus[status].color}>
      {retailerPolicyStatus[status].label}
    </Pill>
  );
}

PolicyStatusPill.propTypes = {
  status: propTypes.oneOf(Object.values(ScreeningStatus)),
};

export function BooleanStatusPills({ type, status }) {
  const enrollmenPillData = {
    color: status ? PillColor.Green : PillColor.Red,
    label: status ? 'Complete' : 'Incomplete',
  };
  const completedPillData = {
    color: status ? PillColor.Green : PillColor.Red,
    label: status ? 'Yes' : 'No',
  };

  if (status === undefined || status === null) return PLACEHOLDER;
  switch (type) {
    case 'enrollment':
      return (
        <Pill color={enrollmenPillData.color}>{enrollmenPillData.label}</Pill>
      );
    case 'isComplete':
      return (
        <Pill color={completedPillData.color}>{completedPillData.label}</Pill>
      );
    default:
      return PLACEHOLDER;
  }
}

BooleanStatusPills.propTypes = {
  type: propTypes.oneOf(['enrollment', 'isComplete']).isRequired,
  status: propTypes.bool,
};
