import cn from 'classnames';
import {
  Accordion,
  Avatar,
  Button,
  ButtonKind,
  CloudinaryImg,
  Link,
  LinkKind,
  LinkVariant,
  Menu,
  MenuItem,
  OpenAxis,
  PhosphorIcon,
  Pill,
  Text,
  TextKind,
  TextElement,
  Tooltip,
  IconName,
} from 'design-system/components';
import { Color } from 'design-system/data';
import { PATHS } from 'constants/index';

import { NavDataReturnData } from './types';
import styles from './responsive-sidenav.module.scss';

interface ResponsiveSidenavProps {
  navData: NavDataReturnData;
  setHideMobileNav: (hide: boolean) => void;
}

const ResponsiveSidenav = ({
  navData,
  setHideMobileNav,
}: ResponsiveSidenavProps) => {
  const {
    onboardingData,
    contractManufacturerData,
    supplierData,
    sephoraData,
    legacyUltaBrandData,
    retailerBrandsData,
    retailerSkusData,
    formulatorData,
    workbenchData,
    inviteModalData,
    marketplaceData,
    moCRAPortalData,
    selectedRetailerBrandId,
    homeUrls,
    supportEmail,
    statusData,
    logout,
    isPathActive,
    generateHref,
    base,
    selectedRetailer,
    isUltaPortal,
    isInMoCRAPortal,
    username,
    email,
    hasUnpaidSubscriptions,
    displayRequestToPay,
    isBrand,
  } = navData;

  return (
    <div className={styles['responsive-sidenav']}>
      <div className={styles.content}>
        <div className={styles.menu}>
          <Menu noBorder>
            {/* Retailer and Retailer Brand links */}
            {(selectedRetailer || selectedRetailerBrandId || isUltaPortal) &&
              !isInMoCRAPortal && (
                <>
                  {/* Conditional rendering for retailer logo */}
                  {selectedRetailer && (
                    <Link
                      kind={LinkKind.Internal}
                      to="/"
                      onClick={() => setHideMobileNav(true)}
                    >
                      <CloudinaryImg
                        src={selectedRetailer?.image_path || ''}
                        alt={selectedRetailer?.name}
                        height={40}
                        className={styles['retailer-logo']}
                        fallbackElement={
                          <Text
                            kind={TextKind.DisplayXSBold}
                            element={TextElement.P}
                            className={styles['retailer-logo']}
                          >
                            {selectedRetailer?.name}
                          </Text>
                        }
                      />
                    </Link>
                  )}
                  {/* Home accordion */}
                  <Accordion
                    title="Home"
                    iconName="HouseSimple"
                    defaultOpen
                    active={
                      homeUrls?.base && homeUrls?.dashboard
                        ? isPathActive([homeUrls.base, homeUrls.dashboard])
                        : false
                    }
                  >
                    {/* Dashboard menu item */}
                    {homeUrls?.dashboard && (
                      <MenuItem
                        compact
                        href={homeUrls.dashboard}
                        onClick={() => setHideMobileNav(true)}
                      >
                        Dashboard
                      </MenuItem>
                    )}
                    {homeUrls?.reports && (
                      <MenuItem
                        compact
                        href={homeUrls.reports}
                        onClick={() => setHideMobileNav(true)}
                      >
                        Reports
                      </MenuItem>
                    )}
                    {/* Pillars menu item */}
                    {homeUrls?.pillars && (
                      <MenuItem
                        compact
                        href={homeUrls.pillars}
                        onClick={() => setHideMobileNav(true)}
                      >
                        Pillars
                      </MenuItem>
                    )}
                    {/* SKUs menu item */}
                    {homeUrls?.skus && (
                      <MenuItem
                        compact
                        href={homeUrls.skus}
                        onClick={() => setHideMobileNav(true)}
                      >
                        SKUs
                      </MenuItem>
                    )}
                    {homeUrls?.eligibility && (
                      <MenuItem
                        compact
                        href={homeUrls.eligibility}
                        onClick={() => setHideMobileNav(true)}
                      >
                        Eligibility
                      </MenuItem>
                    )}
                    {/* Legacy Status menu item */}
                    {homeUrls?.status && (
                      <MenuItem
                        compact
                        href={homeUrls.status}
                        onClick={() => setHideMobileNav(true)}
                      >
                        Your Status
                      </MenuItem>
                    )}
                    {/* Documents menu item */}
                    {homeUrls?.documents && (
                      <MenuItem
                        compact
                        href={homeUrls.documents}
                        onClick={() => setHideMobileNav(true)}
                      >
                        Documents
                      </MenuItem>
                    )}
                    {/* Submissions menu item */}
                    {homeUrls?.submissions && (
                      <MenuItem
                        compact
                        href={homeUrls.submissions}
                        onClick={() => setHideMobileNav(true)}
                      >
                        Submissions
                      </MenuItem>
                    )}
                  </Accordion>
                </>
              )}
            {/* moCRAPortalData accordions */}
            {isInMoCRAPortal && moCRAPortalData?.show && (
              <>
                <Link
                  kind={LinkKind.Internal}
                  to={moCRAPortalData?.base?.path}
                  onClick={() =>
                    moCRAPortalData?.base?.onClick &&
                    moCRAPortalData?.base?.onClick()
                  }
                >
                  <Text
                    kind={TextKind.DisplayXSBold}
                    element={TextElement.P}
                    className={styles['retailer-logo']}
                  >
                    {moCRAPortalData?.base?.text}
                  </Text>
                </Link>
                <Accordion
                  title="Home"
                  iconName="HouseSimple"
                  defaultOpen
                  active={isPathActive([base, generateHref('dashboard')])}
                >
                  {/* Dashboard menu item */}
                  <MenuItem
                    compact
                    href={generateHref('dashboard')}
                    onClick={() => {
                      moCRAPortalData?.dashboard?.onClick &&
                        moCRAPortalData.dashboard.onClick();
                    }}
                  >
                    {moCRAPortalData?.dashboard?.text}
                  </MenuItem>
                  {/* SKUs menu item */}
                  <MenuItem
                    compact
                    href={generateHref('product-registration')}
                    onClick={() => {
                      moCRAPortalData?.skus?.onClick &&
                        moCRAPortalData.skus.onClick();
                    }}
                  >
                    {moCRAPortalData?.skus?.text}
                  </MenuItem>
                  {/* Submissions menu item */}
                  <MenuItem
                    compact
                    href={generateHref('submissions')}
                    onClick={() => {
                      moCRAPortalData?.submissions?.onClick &&
                        moCRAPortalData.submissions.onClick();
                    }}
                  >
                    {moCRAPortalData?.submissions?.text}
                  </MenuItem>
                </Accordion>
              </>
            )}
            {/* Contract Manufacturer Home */}
            {contractManufacturerData?.show && (
              <MenuItem
                href={contractManufacturerData?.home?.path}
                isPhosphorIcon
                iconSize={24}
                iconName={contractManufacturerData?.home?.icon}
                onClick={() => {
                  contractManufacturerData?.home?.onClick &&
                    contractManufacturerData.home.onClick();
                }}
                forceNotActive={
                  window.location.pathname !==
                  contractManufacturerData?.home?.path
                }
              >
                {contractManufacturerData?.home?.text}
              </MenuItem>
            )}
            {/* workbenchData accordion */}
            {workbenchData?.show && (
              <Accordion
                title={workbenchData?.text}
                iconName={workbenchData?.icon}
                defaultOpen
                active={workbenchData?.active}
              >
                {/* Formulation Verification menu item */}
                <MenuItem
                  compact
                  href={workbenchData?.formulation?.path}
                  onClick={() => {
                    workbenchData?.formulation?.onClick &&
                      workbenchData.formulation.onClick();
                  }}
                >
                  {workbenchData?.formulation?.text}
                </MenuItem>
                {/* Ingredient Screener menu item */}
                {workbenchData?.ingredientScreener?.show && (
                  <MenuItem
                    compact
                    href={workbenchData?.ingredientScreener?.path}
                    onClick={() => {
                      workbenchData?.ingredientScreener?.onClick &&
                        workbenchData.ingredientScreener.onClick();
                    }}
                  >
                    {workbenchData?.ingredientScreener?.text}
                  </MenuItem>
                )}
              </Accordion>
            )}
            {/* retailerBrandsData accordion */}
            {retailerBrandsData?.show && (
              <Accordion
                title={retailerBrandsData?.text}
                iconName={retailerBrandsData?.icon}
                defaultOpen
                active={retailerBrandsData?.active}
              >
                <MenuItem
                  compact
                  href={retailerBrandsData?.metrics?.path}
                  onClick={() => {
                    retailerBrandsData?.metrics?.onClick &&
                      retailerBrandsData.metrics.onClick();
                  }}
                >
                  {retailerBrandsData?.metrics?.text}
                </MenuItem>
                <MenuItem
                  compact
                  href={retailerBrandsData?.submissions?.path}
                  isActive={retailerBrandsData?.submissions?.active}
                  onClick={() => setHideMobileNav(true)}
                >
                  {retailerBrandsData?.submissions?.text}
                </MenuItem>
              </Accordion>
            )}
            {/* SKUs accordion */}
            {retailerSkusData?.show && (
              <Accordion
                title={retailerSkusData?.text}
                iconName={retailerSkusData?.icon}
                defaultOpen
                active={retailerSkusData?.active}
              >
                <MenuItem
                  compact
                  href={retailerSkusData?.metrics?.path}
                  onClick={() => {
                    retailerSkusData.metrics?.onClick &&
                      retailerSkusData.metrics.onClick();
                  }}
                >
                  {retailerSkusData?.metrics?.text}
                </MenuItem>
              </Accordion>
            )}
            {/* formulatorData menu items */}
            {formulatorData?.show && (
              <>
                {formulatorData?.purchaseOrders?.show && (
                  <MenuItem
                    isPhosphorIcon
                    iconSize={24}
                    iconName={formulatorData?.purchaseOrders?.icon}
                    href={formulatorData?.purchaseOrders?.path}
                    onClick={() => {
                      formulatorData?.purchaseOrders?.onClick &&
                        formulatorData.purchaseOrders.onClick();
                    }}
                  >
                    {formulatorData?.purchaseOrders?.text}
                  </MenuItem>
                )}
                {formulatorData?.productRequests?.show && (
                  <MenuItem
                    isPhosphorIcon
                    iconSize={24}
                    iconName={formulatorData?.productRequests?.icon}
                    href={formulatorData?.productRequests?.path}
                    onClick={() => {
                      formulatorData?.productRequests?.onClick &&
                        formulatorData.productRequests.onClick();
                    }}
                  >
                    {formulatorData?.productRequests?.text}
                  </MenuItem>
                )}
              </>
            )}
            {/* sephoraData menu item */}
            {sephoraData?.show && (
              <MenuItem
                href={sephoraData?.path}
                iconName={sephoraData?.icon}
                iconSize={24}
                onClick={() => {
                  sephoraData?.onClick && sephoraData.onClick();
                }}
              >
                {sephoraData?.text}
              </MenuItem>
            )}
            {/* Legacy Ulta Brand menu item */}
            {legacyUltaBrandData?.show && (
              <>
                <MenuItem
                  href={legacyUltaBrandData?.ulta.path}
                  onClick={() => {
                    legacyUltaBrandData?.ulta.onClick &&
                      legacyUltaBrandData.ulta.onClick();
                  }}
                >
                  {legacyUltaBrandData?.ulta.text}
                </MenuItem>
                <MenuItem
                  href={legacyUltaBrandData?.mocra.path}
                  onClick={(e) => {
                    legacyUltaBrandData?.mocra.onClick &&
                      legacyUltaBrandData.mocra.onClick(e);
                  }}
                  forceNotActive
                >
                  {legacyUltaBrandData?.mocra.text}
                </MenuItem>
              </>
            )}
            {/* Sephora Retailer CM Flow */}
            {contractManufacturerData?.show &&
              contractManufacturerData.sephoraBrandResults?.show && (
                <MenuItem
                  href="/sephora/brands"
                  iconSize={24}
                  iconName={IconName.Sephora}
                  onClick={() => {
                    contractManufacturerData?.sephoraBrandResults?.onClick &&
                      contractManufacturerData.sephoraBrandResults.onClick();
                  }}
                >
                  {contractManufacturerData?.sephoraBrandResults?.text}
                </MenuItem>
              )}
            {supplierData?.show && (
              <>
                <MenuItem
                  isPhosphorIcon
                  iconSize={24}
                  iconName={supplierData?.listings?.icon}
                  href={supplierData?.listings?.path}
                  onClick={() => {
                    supplierData?.listings.onClick &&
                      supplierData.listings.onClick();
                  }}
                >
                  {supplierData?.listings?.text}
                </MenuItem>
              </>
            )}
            {/* Marketplace menu item */}
            {marketplaceData?.show && (
              <MenuItem
                isPhosphorIcon
                iconSize={24}
                iconName={marketplaceData?.icon}
                href={marketplaceData?.path}
                search={marketplaceData?.search}
                onClick={() => {
                  marketplaceData?.onClick && marketplaceData.onClick();
                }}
              >
                {marketplaceData?.text}
              </MenuItem>
            )}
          </Menu>
        </div>
        <div className={styles.lower}>
          {/*  Team Invite */}
          {inviteModalData?.show && (
            <Button
              kind={ButtonKind.Link}
              onClick={() => {
                inviteModalData?.onClick && inviteModalData.onClick();
              }}
              className={styles.cta}
            >
              <div className={styles['first-level-link']}>
                <PhosphorIcon
                  iconName="PaperPlaneTilt"
                  color={Color.Neutral500.value}
                  size={24}
                />
                <Text
                  color={Color.Neutral500}
                  kind={TextKind.TextMDSemibold}
                  element={TextElement.Span}
                >
                  {inviteModalData?.text}
                </Text>
              </div>
            </Button>
          )}
          {/* Support link */}
          <Link kind={LinkKind.External} href="mailto:support@noviconnect.com">
            <div className={cn([styles['first-level-link'], styles.cta])}>
              <PhosphorIcon
                iconName="Question"
                color={Color.Neutral500.value}
                size={24}
              />
              <Text
                color={Color.Neutral500}
                kind={TextKind.TextMDSemibold}
                element={TextElement.Span}
              >
                Support
              </Text>
            </div>
          </Link>
          {/* Settings link */}
          {isBrand ? (
            <div className={styles['settings-accordion']}>
              <Accordion
                title="Settings"
                iconName="GearSix"
                defaultOpen={
                  isPathActive([
                    PATHS.userSettings,
                    PATHS.brandProfileSettings,
                  ]) || false
                }
                active={isPathActive([
                  PATHS.userSettings,
                  PATHS.brandProfileSettings,
                ])}
              >
                <MenuItem
                  compact
                  href={PATHS.brandProfileSettings}
                  onClick={() => setHideMobileNav(true)}
                >
                  Brand Profile
                </MenuItem>
                <MenuItem
                  compact
                  href={PATHS.userSettings}
                  onClick={() => setHideMobileNav(true)}
                >
                  User Preferences
                </MenuItem>
              </Accordion>
            </div>
          ) : (
            <Link
              kind={LinkKind.Internal}
              to={PATHS.userSettings}
              onClick={() => setHideMobileNav(true)}
            >
              <div
                className={cn([styles['first-level-link'], styles.settings])}
              >
                <PhosphorIcon
                  iconName="GearSix"
                  color={Color.Neutral500.value}
                  size={24}
                />
                <Text
                  color={Color.Neutral500}
                  kind={TextKind.TextMDSemibold}
                  element={TextElement.Span}
                >
                  Settings
                </Text>
              </div>
            </Link>
          )}
          {/* Subscription Status */}
          {selectedRetailerBrandId && !isUltaPortal && (
            <div className={styles['lower-mid']}>
              <Text kind={TextKind.TextSMSemibold} element={TextElement.Span}>
                Subscription Status
              </Text>
              <Pill color={statusData.Color}>{statusData.Text}</Pill>
              {hasUnpaidSubscriptions && (
                <div>
                  {displayRequestToPay && (
                    <Text
                      className={styles['request-to-pay']}
                      kind={TextKind.TextSM}
                      element={TextElement.P}
                    >
                      Your invoice is due. Please{' '}
                      {onboardingData?.invoiceLink
                        ? 'complete payment below'
                        : 'pay'}{' '}
                      to avoid losing any account capabilities. If you need
                      support, please email{' '}
                      <Link
                        kind={LinkKind.External}
                        href={`mailto:${supportEmail}`}
                      >
                        {supportEmail}
                      </Link>
                    </Text>
                  )}
                  {onboardingData?.invoiceLink ? (
                    <Link
                      kind={LinkKind.External}
                      href={onboardingData?.invoiceLink}
                      target="_blank"
                      variant={LinkVariant.NewDefault}
                    >
                      Complete Payment
                    </Link>
                  ) : (
                    <Tooltip
                      openAxis={OpenAxis.Y}
                      triggerElement={
                        <Link
                          kind={LinkKind.External}
                          href={`mailto:${supportEmail}`}
                          target="_blank"
                          variant={LinkVariant.NewDefault}
                        >
                          Complete Payment
                        </Link>
                      }
                    >
                      <Text kind={TextKind.TextSM} element={TextElement.Span}>
                        Please contact support to access your invoice
                      </Text>
                    </Tooltip>
                  )}
                </div>
              )}
            </div>
          )}
          {/* User Information and Logout */}
          <div className={styles['lower-bottom']}>
            <Avatar initials={username} />
            <div className={styles['lower-bottom-text']}>
              <Text kind={TextKind.TextSMSemibold} element={TextElement.Span}>
                {username}
              </Text>
              <Text
                className={styles.email}
                kind={TextKind.TextXSMedium}
                element={TextElement.Span}
              >
                {email}
              </Text>
            </div>
            <div className={styles.logout}>
              <Tooltip
                openAxis={OpenAxis.Y}
                triggerElement={
                  <div
                    role="button"
                    onClick={() => {
                      logout();
                      setHideMobileNav(true);
                    }}
                  >
                    <PhosphorIcon
                      iconName="SignOut"
                      size={24}
                      color={Color.Neutral500.value}
                    />
                  </div>
                }
              >
                <Text kind={TextKind.TextSM} element={TextElement.Span}>
                  Log out
                </Text>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResponsiveSidenav;
