import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import styles from './toast.module.scss';
import cn from 'classnames';
import propTypes from 'prop-types';
import { ToastKind } from './constants';
import {
  Button,
  ButtonKind,
  Icon,
  IconName,
  Text,
  TextElement,
  TextKind,
} from 'design-system/components';
import { Color } from 'design-system/data';

const Toast = forwardRef((props, ref) => {
  const visualTimerBarDuration = 5000;
  const [timer, setTimer] = useState();
  const [triggerToast, setTriggerToast] = useState(false);

  function dismissToast() {
    clearTimeout(timer);
    setTriggerToast(false);
  }

  function displayToast() {
    if (!triggerToast) {
      setTriggerToast(true);
      if (props.autoDismiss) {
        setTimer(
          setTimeout(() => {
            setTriggerToast(false);
          }, visualTimerBarDuration)
        );
      }
    }
  }

  useImperativeHandle(ref, () => ({
    launchToast() {
      displayToast();
    },
    dismissToast() {
      dismissToast();
    },
  }));

  // Keep empty dependency array to ensure this is only triggered once
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => props.autoLaunch && displayToast(), []);

  return (
    <div
      className={cn(
        styles[triggerToast ? `container-${props.kind}` : 'hide'],
        props.autoDismiss && styles['auto-dismiss']
      )}
      style={props.autoLaunch ? { position: 'initial' } : {}}
      role="status"
      aria-live="polite"
    >
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.title}>
            {props.kind === ToastKind.Success ? (
              <Icon
                name={IconName.Check}
                size={12}
                color={Color.Green500}
              ></Icon>
            ) : (
              <Icon name={IconName.Close} size={18} color={Color.Red500}></Icon>
            )}
            <Text kind={TextKind.TextMDBold} element={TextElement.H5}>
              {props.title}
            </Text>
          </div>
          <Button
            kind={ButtonKind.Tertiary}
            iconName={IconName.Close}
            onClick={dismissToast}
            onlyIcon={true}
          ></Button>
        </div>
        <div>{props.children}</div>
      </div>
      {props.autoDismiss && <div className={styles.bar}></div>}
    </div>
  );
});

Toast.propTypes = {
  kind: propTypes.oneOf(Object.values(ToastKind)).isRequired,
  title: propTypes.string.isRequired,
  children: propTypes.any.isRequired,
  autoLaunch: propTypes.bool,
  autoDismiss: propTypes.bool,
};

Toast.defaultProps = {
  autoDismiss: true,
};

export default Toast;
