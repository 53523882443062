import {
  Text,
  TextKind,
  TextElement,
  Link,
  LinkKind,
  LinkVariant,
} from 'design-system/components';
import UltaDashboardEligibilityStatusTable from './UltaDashboardEligibilityStatusTable';
import {
  useEligibilityContext,
  EligibilityContextType,
} from './EligibilityContext';
import styles from './eligibility-status-table.module.scss';

function UltaDashboardEligibility() {
  const { containedPolicies, formulationPolicies } =
    useEligibilityContext() as EligibilityContextType;

  if (containedPolicies?.length + formulationPolicies?.length < 1) return null;

  return (
    <div className={styles['ulta-dashboard-container']}>
      <div className={styles['ulta-dashboard-heading']}>
        <Text kind={TextKind.DisplayXSMedium} element={TextElement.H2}>
          Your eligibility status
        </Text>
        <Link
          kind={LinkKind.Internal}
          variant={LinkVariant.NewDefault}
          to="/retailer-brand/eligibility/298/sku_level_status"
        >
          Review
        </Link>
      </div>
      <UltaDashboardEligibilityStatusTable />
    </div>
  );
}

export default UltaDashboardEligibility;
