import { useHistory } from 'react-router-dom';
import { array, bool } from 'prop-types';

import {
  Button,
  ButtonIconPosition,
  ButtonKind,
  IconDirection,
  LoadingOverlay,
  NoData,
  Pill,
  Text,
  TextElement,
  TextKind,
} from 'design-system/components';

import { formatDate, formatToShortUTC12hr } from 'services/api/date.js';
import styles from './brand-submissions.module.scss';
import RequirementStatusPill from 'components/ScreeningStatusPill';

import { SubmissionStatus } from 'design-system/data';

const policyIsSubmitted = (policyId, submittedPolicyIds) =>
  submittedPolicyIds.includes(Number(policyId));

const SubmissionContent = ({
  loading,
  brandSubmissions,
  retailerPolicyBrandScreeningStatus,
  activeTab,
  error,
}) => {
  const history = useHistory();
  if (loading) return <LoadingOverlay show={loading} />;
  if (!brandSubmissions?.length) {
    return (
      <NoData
        hasErrorOccurred={error}
        errorMessage="Something went wrong while loading your submissions. Please check back later"
        noContentMessage="There are currently no submissions to review!"
      />
    );
  }
  return brandSubmissions?.map((brand) => {
    // const hasBeenViewed = !!brand.viewedAt;
    const submittedPolicyIds = brand.policySubmissions.map((policy) =>
      Number(policy.policyId)
    );

    let policyIdsToShow;
    switch (activeTab) {
      case SubmissionStatus.InProgress:
        policyIdsToShow = Object.keys(
          retailerPolicyBrandScreeningStatus?.[brand.retailerConsumerBrandId]
        ).filter(
          (policyId) => !policyIsSubmitted(policyId, submittedPolicyIds)
        );
        break;
      case SubmissionStatus.All:
        policyIdsToShow = Object.keys(
          retailerPolicyBrandScreeningStatus?.[brand.retailerConsumerBrandId]
        );
        break;
      default:
        policyIdsToShow = Object.keys(
          retailerPolicyBrandScreeningStatus?.[brand.retailerConsumerBrandId]
        ).filter((policyId) => policyIsSubmitted(policyId, submittedPolicyIds));
        break;
    }

    return (
      <div className={styles['brand-card']} key={brand.id}>
        <div>
          <div className={styles.title}>
            <Text kind={TextKind.Display2XSMedium} element={TextElement.H2}>
              {brand.name}
            </Text>
            {brand.productCount && <Pill>{brand.statusCounts.total} SKUs</Pill>}
            {/* Descoped: 
            <Pill color={hasBeenViewed ? PillColor.Green : PillColor.Red}>
              {hasBeenViewed ? 'Viewed' : 'Not viewed'}
            </Pill> */}
          </div>
          <ul>
            {policyIdsToShow.map((policyId) => {
              const policy =
                retailerPolicyBrandScreeningStatus?.[
                  brand.retailerConsumerBrandId
                ][policyId];
              const policySubmission = brand.policySubmissions.find(
                (submission) => Number(submission.policyId) === Number(policyId)
              );
              const formattedDatetime = formatToShortUTC12hr(
                policySubmission?.submittedAt
              );
              const formattedDate = formatDate(policySubmission?.submittedAt);
              const policyScreeningStatus = policy.screeningResult;
              return (
                <li key={policy.id}>
                  <div>
                    <Text
                      kind={TextKind.TextSMMedium}
                      element={TextElement.Span}
                    >
                      {policy.name}
                    </Text>{' '}
                    {formattedDatetime && formattedDate && (
                      <Text
                        kind={TextKind.TextSM}
                        element={TextElement.Span}
                        className={styles['submitted-text']}
                      >
                        submitted on {formattedDate} at {formattedDatetime}
                      </Text>
                    )}
                  </div>
                  {policyScreeningStatus && (
                    <RequirementStatusPill status={policyScreeningStatus} />
                  )}
                </li>
              );
            })}
          </ul>
        </div>
        {/* TODO Make Link */}
        <Button
          kind={ButtonKind.Primary}
          usePhosphorIcon
          iconName="ArrowRight"
          iconSize={20}
          iconDirection={IconDirection.Right}
          iconPosition={ButtonIconPosition.Right}
          onClick={() =>
            history.push(
              `/retailer/brands/submissions/${brand.id}/requirement_groups`
            )
          }
        >
          Review
        </Button>
      </div>
    );
  });
};

SubmissionContent.propTypes = {
  loading: bool,
  brandSubmissions: array,
  error: bool,
};

export default SubmissionContent;
