import { useMemo } from 'react';
import { useHistory, Link } from 'react-router-dom';

import {
  Grid,
  GridItem,
  Text,
  TextKind,
  TextElement,
  Button,
  ButtonKind,
  AvatarKind,
  Avatar,
  NoviLogo,
} from 'design-system/components';
import {
  determineInitials,
  convertSnakeToCamelCase,
} from 'design-system/utils';

import { useApp } from 'context/AppContext';
import withLineBreaks from 'utils/withLineBreaks';
import styles from './retailerBrandWelcome.module.scss';
import useRetailerPolicies from './useRetailerPolicies';
import { getTitleCopy } from './utils';
import { PATHS } from 'constants/index';

function PillarTitleGroup({ showPillarTitle, policy }) {
  return (
    <GridItem span={4} responsiveSpan={{ tablet: 8, desktop: 12 }}>
      {showPillarTitle && (
        <Text element={TextElement.H2} kind={TextKind.Display2XSMedium}>
          {policy.name}
        </Text>
      )}
      <Grid nestedCols>
        {policy?.retailerPolicyRequirementGroups?.map((pillar, i) => {
          return (
            <GridItem
              key={pillar?.id || i}
              span={4}
              responsiveSpan={{ tablet: 4, desktop: 6 }}
            >
              <div className={styles['policy-box']}>
                <div className={styles['title-group']}>
                  {pillar?.imagePath && (
                    <Avatar
                      initials={determineInitials(pillar.name)}
                      kind={AvatarKind.Large}
                      src={pillar.imagePath}
                      name={pillar.name}
                    />
                  )}
                  <Text element={TextElement.H3} kind={TextKind.Display2XS}>
                    {pillar.name}
                  </Text>
                </div>
                {pillar?.description && (
                  <div className={styles.description}>
                    <Text element={TextElement.P} kind={TextKind.TextSM}>
                      {withLineBreaks(pillar.description)}
                    </Text>
                  </div>
                )}
              </div>
            </GridItem>
          );
        })}
      </Grid>
    </GridItem>
  );
}

const RetailerBrandWelcome = () => {
  const history = useHistory();
  const { retailerBrand } = useApp();
  const retailerData = convertSnakeToCamelCase(retailerBrand);

  const { data: policyData } = useRetailerPolicies(retailerData?.retailer?.id);

  const prodImg =
    'https://res.cloudinary.com/novi-connect/image/upload/c_pad,b_auto:predominant,fl_preserve_transparency/v1706562377/default-brand-onboarding-bg_gxuqvg.jpg?_s=public-apps';
  const devImg =
    'https://res.cloudinary.com/dxik7mk2v/image/upload/v1704479656/welcome-background_rwscx9.png';

  const isLocal = process.env.REACT_APP_API_BASE === 'http://localhost:3000/';
  const fallbackBackgroundImage = isLocal ? devImg : prodImg;
  const backgroundImage =
    retailerData?.retailer?.backgroundImagePath || fallbackBackgroundImage;

  const titleCopy = useMemo(
    () => getTitleCopy(policyData?.policies || []),
    [policyData?.policies]
  );

  const hasMultiplePolicies = useMemo(
    () => policyData?.policies?.length > 1,
    [policyData?.policies]
  );

  const hasMuliplePillars = (policy) =>
    policy?.retailerPolicyRequirementGroups?.length > 1;

  const processedData = useMemo(() => {
    const [manyPillars, singlePillars] = [[], []];
    policyData?.policies.map((policy) => {
      const hasManyPillars = hasMuliplePillars(policy);
      if (hasManyPillars) {
        return manyPillars.push(policy);
      }
      return singlePillars.push(policy);
    });
    return { singlePillars, manyPillars };
  }, [policyData]);

  const handleClick = () => {
    const url = PATHS.retailerBrandDash;
    history.push(url);
  };

  return (
    <div
      className={styles.container}
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <Link to="/portal/selection" className={styles.logo}>
        <NoviLogo />
      </Link>
      <div className={styles.content}>
        <Grid>
          <GridItem span={12} responsiveSpan={{ tablet: 8, desktop: 12 }}>
            <div className={styles.banner}>
              {retailerData?.retailer?.imagePath && (
                <Avatar
                  src={retailerData.retailer.imagePath}
                  initials={determineInitials(retailerData?.retailer?.name)}
                  name={retailerData.retailer.name}
                  kind={AvatarKind.Large}
                />
              )}
              <Text kind={TextKind.DisplaySMMedium} element={TextElement.H1}>
                {titleCopy}
              </Text>
              <Text
                kind={TextKind.TextMD}
                element={TextElement.H2}
                className={styles['sub-title']}
              >
                In order to be verified, you will complete a set of requirements
                within each of these pillars.
              </Text>
            </div>
          </GridItem>
        </Grid>
        <Grid>
          {!hasMultiplePolicies &&
            policyData?.policies?.map((policy, i) => (
              <PillarTitleGroup
                key={policy?.id || i}
                showPillarTitle={false}
                policy={policy}
              />
            ))}
          {hasMultiplePolicies && (
            <>
              {processedData?.singlePillars?.map((policy, i) => {
                if (!policy?.retailerPolicyRequirementGroups?.length) {
                  return null;
                }
                return (
                  <GridItem
                    key={policy?.id || i}
                    span={4}
                    responsiveSpan={{ tablet: 8, desktop: 12 }}
                  >
                    <div className={styles['policy-box']}>
                      <div className={styles['title-group']}>
                        {policy?.retailerPolicyRequirementGroups[0]
                          ?.imagePath && (
                          <Avatar
                            initials={determineInitials(policy.name)}
                            kind={AvatarKind.Large}
                            src={
                              policy?.retailerPolicyRequirementGroups[0]
                                ?.imagePath
                            }
                            name={policy.name}
                          />
                        )}
                        <Text
                          element={TextElement.H3}
                          kind={TextKind.Display2XS}
                        >
                          {policy?.retailerPolicyRequirementGroups[0]?.name}
                        </Text>
                      </div>
                    </div>
                  </GridItem>
                );
              })}
              {processedData?.manyPillars?.map((policy, i) => {
                return (
                  <PillarTitleGroup
                    key={policy?.id || i}
                    showPillarTitle={true}
                    policy={policy}
                  />
                );
              })}
            </>
          )}
        </Grid>
        <Grid>
          <GridItem
            span={4}
            responsiveSpan={{ tablet: 1, desktop: 1 }}
            responsiveOffset={{ tablet: 12, desktop: 12 }}
          >
            <Button
              kind={ButtonKind.Primary}
              onClick={handleClick}
              usePhosphorIcon
              iconSize={20}
              iconPosition="right"
              iconName="ArrowRight"
            >
              Continue
            </Button>
          </GridItem>
        </Grid>
      </div>
    </div>
  );
};

export default RetailerBrandWelcome;
