import PropTypes from 'prop-types';
import {
  Text,
  TextKind,
  TextElement,
  Icon,
  IconVersion,
  IconDirection,
  PhosphorIcon,
} from 'design-system/components';
import { ButtonKind, ButtonIconPosition } from './constants';
import classnames from 'classnames';
import styles from './button.module.scss';
import { Color } from 'design-system/data';

function Button(props) {
  const { kind = ButtonKind.Primary, className: customClassName } = props;
  const iconDirection = props.iconDirection || IconDirection.Up;
  let rootClassName =
    props.iconPosition === ButtonIconPosition.Right
      ? 'button-root-reverse'
      : 'button-root';

  if (props.onlyIcon && props.iconName) {
    rootClassName = 'root-icon';
  }

  const Children = () => (
    <>
      {props.iconName &&
        (props.usePhosphorIcon ? (
          <PhosphorIcon
            iconName={props.iconName}
            weight={props.iconWeight}
            size={props.iconSize || 16}
            color={props.iconColor?.value}
          />
        ) : (
          <Icon
            name={props.iconName}
            version={IconVersion.Small}
            size={props.iconSize || 16}
            direction={iconDirection}
            color={props.iconColor}
          />
        ))}
      {!props.onlyIcon && props.children && (
        <Text kind={TextKind.TextSMSemibold} element={TextElement.Span}>
          {props.children}
        </Text>
      )}
    </>
  );

  return (
    <button
      onClick={props.onClick ? props.onClick : () => {}}
      onKeyDown={props.onKeyDown}
      className={classnames(
        styles[rootClassName],
        styles[kind],
        customClassName
      )}
      style={props.style}
      disabled={props.disabled}
      aria-disabled={props.disabled}
      aria-label={props[`aria-label`]}
      data-cy={props[`data-cy`]}
      id={props.id}
      type={props.type}
      aria-current={props['aria-current']}
      ref={props.refProp}
      tabIndex={props.tabIndex}
    >
      <Children />
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  kind: PropTypes.oneOf(Object.values(ButtonKind)),
  iconName: PropTypes.string,
  iconPosition: PropTypes.oneOf(Object.values(ButtonIconPosition)),
  iconDirection: PropTypes.oneOf(Object.values(IconDirection)),
  iconColor: PropTypes.oneOf(Object.values(Color)),
  iconSize: PropTypes.number,
  usePhosphorIcon: PropTypes.bool,
  iconWeight: PropTypes.string,
  onlyIcon: PropTypes.bool,
  disabled: PropTypes.bool,
  [`aria-label`]: PropTypes.string,
  [`data-cy`]: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  style: PropTypes.object, // escape hatch
  refProp: PropTypes.object,
  tabIndex: PropTypes.number,
};

export default Button;
