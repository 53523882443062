import {
  Menu,
  MenuItem,
  SidebarLayout,
  IconName,
} from 'design-system/components';
import { PATHS } from 'constants/index';

function RetailerMenu() {
  return (
    <Menu>
      <MenuItem
        iconName={IconName.Cluster}
        iconSize={16}
        href={PATHS.retailerBrandsView}
      >
        Brands
      </MenuItem>
      <MenuItem
        iconName={IconName.Node}
        iconSize={16}
        href={PATHS.retailerProductsView}
      >
        Products
      </MenuItem>
      <MenuItem
        iconName={IconName.Document}
        iconSize={16}
        href={PATHS.retailerDocumentsView}
      >
        Documents
      </MenuItem>
    </Menu>
  );
}

function RetailerNavLayout({ children }) {
  return <SidebarLayout menu={RetailerMenu}>{children}</SidebarLayout>;
}

export default RetailerNavLayout;
