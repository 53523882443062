const legacyRetailerIds = [
  37, // Ulta Beauty retailer ID, remove with ENG-4826
];

// For Brands that have access to the new retailer brand experience under some retailers, but other
// retailers still use the legacy retailer portal.
export const isSelectedRetailerInLegacyPortal = (retailerId, user) => {
  if (user?.hasFF('tmp_disable_ulta_v1')) {
    return false;
  }

  return legacyRetailerIds.includes(retailerId);
};
