import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './checkbox.module.scss';
import { CheckboxSize } from './constants';
import { Icon, IconName, IconVersion } from '../..';
import { Color } from 'design-system/data';

function Checkbox(props) {
  const size = props.size ? props.size : CheckboxSize.SMALL;
  const rootClassName =
    size === CheckboxSize.LARGE ? styles['root-lg'] : styles.root;

  return (
    <div
      onClick={props.onClick}
      className={cn(
        rootClassName,
        props.isActive ? styles.active : '',
        props.disabled ? styles.disabled : ''
      )}
      role="checkbox"
      aria-checked={props.isActive}
      disabled={props.disabled} // todo - disabled not valid div attr.
      data-cy={props['data-cy']}
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          return props.onClick ? props.onClick() : null;
        }
      }}
      {...props}
    >
      {props.isActive && (
        <Icon
          size={size === CheckboxSize.SMALL ? 8 : 16}
          color={Color.White}
          version={IconVersion.Small}
          name={IconName.Check}
        />
      )}
    </div>
  );
}

Checkbox.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  ariaLabelledBy: PropTypes.string.isRequired,
  size: PropTypes.oneOf(Object.values(CheckboxSize)),
  'data-cy': PropTypes.string,
  disabled: PropTypes.bool,
};

export default Checkbox;
