import { UrlParam } from 'design-system/data';
import { appendParameter, sortDirection } from 'design-system/utils';
import { debounce } from 'lodash';
import fetcher from 'services/api/fetcher';

export const getActiveTabIndex = (tabData) => {
  const params = new URLSearchParams(window.location.search);
  const status = params.get(UrlParam.Status) || 'all';
  return tabData.map((tab) => tab.value).indexOf(status);
};

export const buildFilters = (filterData) => {
  const filters = [];
  function toTitleCase(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  Object.entries(filterData).forEach((entry) => {
    filters.push({
      name: toTitleCase(entry[0]),
      value: entry[0],
      isLoading: filterData[entry[0]].isLoading,
      isDynamic: filterData[entry[0]].isDynamic || false,
      toggleOptions: filterData[entry[0]].toggleOptions || null,
      options: filterData[entry[0]]?.results?.length
        ? filterData[entry[0]]?.results
            .sort((option) => option.name)
            .map((option) => ({
              id: option.id.toString(),
              name: option.name,
            }))
        : [],
    });
  });
  return filters;
};

export const handleFetchProductOptionsByName = debounce(
  async (searchTerm, brandID, retailerId, stateSetter) => {
    if (!searchTerm) {
      return stateSetter((prevState) => ({
        ...prevState,
        products: {
          ...prevState.products,
          isLoading: false,
        },
      }));
    }
    const term = searchTerm.trim();
    const PRODUCTS_ENDPOINT = `api/product_design/v1/consumer_products?consumer_brand_id=${brandID}`;

    async function fetchResource(endpoint) {
      const search = new URLSearchParams({
        term,
        ...(Boolean(retailerId) && { retailer_id: retailerId }),
      });
      const url = `${endpoint}?${search.toString()}`;
      return fetcher(url);
    }

    const setSearchResultsState = (resourceType, results) =>
      stateSetter((prevState) => ({
        ...prevState,
        [resourceType]: {
          ...prevState[resourceType],
          isLoading: false,
          results,
        },
      }));
    const products = await fetchResource(PRODUCTS_ENDPOINT);
    const simplifiedProducts = products.consumer_products.map((product) => ({
      id: product.id,
      name: product.name,
    }));
    return setSearchResultsState('products', simplifiedProducts);
  },
  1000
);

export const handleFetchProductOptionsBySku = debounce(
  async (searchTerm, brandID, retailerId, stateSetter) => {
    if (!searchTerm) {
      return stateSetter((prevState) => ({
        ...prevState,
        products: {
          ...prevState.products,
          isLoading: false,
        },
      }));
    }
    const term = searchTerm.trim();
    const SKU_ENDPOINT = `api/v4/consumer_brands/${brandID}/sku_ids`;

    async function fetchResource(endpoint) {
      const search = new URLSearchParams({
        term,
        ...(Boolean(retailerId) && { retailer_id: retailerId }),
      });
      const url = `${endpoint}?${search.toString()}`;
      return fetcher(url);
    }

    const setSearchResultsState = (resourceType, results) =>
      stateSetter((prevState) => ({
        ...prevState,
        [resourceType]: {
          ...prevState[resourceType],
          isLoading: false,
          results,
        },
      }));
    const skus = await fetchResource(SKU_ENDPOINT);
    const productSkus = skus?.sku_ids?.map((sku) => ({
      id: sku,
      name: sku,
    }));
    return setSearchResultsState('products', productSkus);
  },
  1000
);

export const encodeIds = (records) => {
  if (records) {
    return records.map((record) => record.id);
  }
};

export function setUrlSearchParameters(
  tableState,
  currentPage,
  itemsPerPage,
  status,
  history,
  filterState
) {
  const skuSearch = isSkuSearch(filterState.products);
  const params = new URLSearchParams();
  appendParameter(params, UrlParam.Direction, sortDirection(tableState));
  appendParameter(params, UrlParam.Page, currentPage);
  appendParameter(params, UrlParam.Items, itemsPerPage);
  appendParameter(params, UrlParam.Status, status);
  appendParameter(params, UrlParam.Sort, tableState.sortBy);
  appendParameter(
    params,
    UrlParam.FormulationPolicyIds,
    encodeIds(filterState?.pillars)
  );
  if (skuSearch) {
    appendParameter(params, UrlParam.SkuId, encodeIds(filterState?.products));
  } else {
    appendParameter(
      params,
      UrlParam.ConsumerProductIdsArray,
      encodeIds(filterState?.products)
    );
  }
  history.push({ search: params?.toString() });
}

export const buildSearchParamsObject = (searchParams) => {
  const searchParamsObject = {};
  searchParams.forEach((val, key) => (searchParamsObject[key] = val));
  return {
    searchParamsObject,
    searchParamsInstance: searchParams,
    searchParamsString: searchParams.toString(),
  };
};

export const isSkuSearch = (searchResults) => {
  if (!searchResults?.length) return false;
  return searchResults.every((result) => result.id === result.name);
};
