import { useMemo, useState } from 'react';
import cn from 'classnames';
import { useSnackbar } from 'notistack';

import {
  Button,
  ButtonKind,
  Link,
  LinkKind,
  LinkVariant,
  Modal,
  ModalType,
  PhosphorIcon,
  Progress,
  ProgressSize,
  Text,
  TextKind,
  TextElement,
  ToastKind,
  ToastManager,
  Tooltip,
  OpenAxis,
} from 'design-system/components';
import { BrandPolicySubmissionStatus, Color } from 'design-system/data';

import checklistSvg from 'assets/illustrations/checklist.svg';
import rocketSvg from 'assets/illustrations/rocket.svg';
import chatSvg from 'assets/illustrations/chat.svg';
import planetSvg from 'assets/illustrations/planet.svg';
import { PATHS } from 'constants/index';
import fetcher from 'services/api/fetcher';
import styles from './retailerBrandDashboard.module.scss';

const NextSteps = ({
  policy,
  retailerName = 'the retailer',
  retailerId,
  loading,
  canSubmit,
  supportEmail,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [toastData, setToastData] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);
  const { name, percentComplete, submissionStatus, id } = policy;

  const handleSubmit = async () => {
    const url = `/api/v4/retailers/${retailerId}/policies/${id}/policy_submissions`;
    try {
      await fetcher(url, {
        method: 'post',
      });
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    } finally {
      setToastData((prevToastData) => [
        ...prevToastData,
        {
          kind: ToastKind.Success,
          title: 'Success',
          message: `Your attestation responses have been successfully submitted to ${retailerName}! Refresh your page to see the updated submission status.`,
          autoDismiss: false,
        },
      ]);
      setModalOpen(false);
    }
  };
  // compute state to set proper data
  const policyState = useMemo(() => {
    if (submissionStatus === BrandPolicySubmissionStatus.NeedsInformation) {
      return {
        svg: checklistSvg,
        alt: 'checklist',
        links: [
          {
            to: PATHS.retailerBrandProducts,
            text: 'Finish uploading your SKUs',
          },
          {
            to: PATHS.retailerBrandProducts,
            text: 'View your SKUs',
          },
          {
            to: PATHS.retailerBrandRequirementGroups,
            text: 'Complete the attestation process',
          },
        ],
        description: '',
        title: `Next steps${name ? ` for ${name} ` : ''}`,
        titleKind: TextKind.DisplayXSMedium,
      };
    }
    if (submissionStatus === BrandPolicySubmissionStatus.Pass) {
      return {
        svg: rocketSvg,
        alt: 'rocket',
        links: null,
        description: `Would you like to submit your verification to ${retailerName} for review? If any requirements are still in a “pending” state, ${retailerName} will review them to ensure that they pass.`,
        title: `You completed all the required verifications for ${
          name ? `${name} at ` : ''
        }${retailerName}!`,
        titleKind: TextKind.Display2XSMedium,
        showSubmitButton: true,
      };
    }
    if (submissionStatus === BrandPolicySubmissionStatus.Fail) {
      return {
        svg: checklistSvg,
        alt: 'checklist',
        subtitle: 'Next steps',
        links: [
          {
            to: PATHS.retailerBrandRequirementGroups,
            text: 'Return to the attestation process',
          },
        ],
        description: `You didn’t pass all of the ${name} requirements. You will need to address this before you can submit your responses to ${retailerName}.`,
        title: `Something’s not quite right…`,
        titleKind: TextKind.DisplaySMMedium,
      };
    }
    if (
      submissionStatus === BrandPolicySubmissionStatus.Pending ||
      submissionStatus === BrandPolicySubmissionStatus.Ready
    ) {
      return {
        svg: chatSvg,
        alt: 'chat',
        links: null,
        description: `You will receive a notification when ${retailerName} has finished reviewing your responses.`,
        title: `Your attestations for ${name} are currently in review.`,
        titleKind: TextKind.Display2XSMedium,
      };
    }
    if (submissionStatus === BrandPolicySubmissionStatus.Approved) {
      return {
        svg: planetSvg,
        alt: 'planet',
        subtitle: 'Next steps',
        links: [
          {
            to: PATHS.retailerBrandSubmissions,
            text: `Read ${retailerName}’s comments`,
          },
          {
            to: PATHS.retailerBrandProducts,
            text: 'View your SKUs',
          },
        ],
        description: `You have completed all the requirements for ${name} and your submission to ${retailerName} has been approved.`,
        title: `Congratulations! You’re approved for ${name}.`,
        titleKind: TextKind.DisplayXSMedium,
      };
    }
    if (submissionStatus === BrandPolicySubmissionStatus.Rejected) {
      return {
        svg: checklistSvg,
        alt: 'checklist',
        subtitle: 'Next steps',
        links: [
          {
            to: PATHS.retailerBrandSubmissions,
            text: `View ${retailerName}’s comments`,
          },
        ],
        description: `You didn’t pass all of the ${name} requirements. Here’s what to do next.`,
        title: `Something’s not right….`,
        titleKind: TextKind.DisplaySMMedium,
      };
    }
  }, [submissionStatus, name, retailerName]);

  const submitButton = (
    <Button
      kind={ButtonKind.Primary}
      onClick={() => setModalOpen(true)}
      className={styles.button}
      usePhosphorIcon
      iconSize={20}
      iconPosition="right"
      iconName="ArrowRight"
      iconWeight="bold"
      disabled={!canSubmit}
    >
      Submit to {retailerName}
    </Button>
  );

  return (
    <>
      <Modal
        show={modalOpen}
        title=""
        type={ModalType.Small}
        onClick={() => setModalOpen(false)}
        showActionButtons
        cancelCtaLabel="Make changes"
        cancelCtaKind={ButtonKind.Tertiary}
        onCancel={() => setModalOpen(false)}
        doneCtaLabel="Submit"
        doneCtaKind={ButtonKind.Primary}
        dataCyRoot="attestation-modal"
        onDone={handleSubmit}
      >
        <div className={styles['ring-container']}>
          <div className={styles['ring-one']}>
            <div className={styles['warning-icon-container']}>
              <PhosphorIcon
                iconName="Warning"
                color={Color.Orange600.value}
                size={24}
              />
              <span className={styles['ring-two']} />
              <span className={styles['ring-three']} />
              <span className={styles['ring-four']} />
            </div>
          </div>
        </div>
        <div className={styles['modal-content']}>
          <Text kind={TextKind.TextMDSemibold} element={TextElement.H2}>
            Are you sure?
          </Text>
          <Text kind={TextKind.TextSM} element={TextElement.P}>
            Once you submit your attestations to {retailerName} you will not be
            able to edit your responses. After {retailerName} has reviewed your
            responses you will be alerted of your status within the {name}{' '}
            Program on your Novi dashboard.
          </Text>
        </div>
      </Modal>
      <div className={cn([styles['next-steps'], styles.panel])}>
        <div className={styles['next-steps-left']}>
          <div className={styles['next-steps-left']}>
            <img
              src={policyState.svg}
              alt={policyState.alt}
              onError={() => <span className={styles.fallback} />}
            />
          </div>
          <div className={styles['next-steps-center']}>
            <Text
              className={styles['next-steps-header']}
              kind={policyState.titleKind}
              element={TextElement.H2}
            >
              {policyState.title}
            </Text>
            {policyState.description && (
              <Text
                className={styles.description}
                element={TextElement.P}
                kind={TextKind.TextMD}
              >
                {policyState.description}
              </Text>
            )}
            {policyState.subtitle && (
              <Text element={TextElement.P} kind={TextKind.DisplayXSMedium}>
                {policyState.subtitle}
              </Text>
            )}
            {policyState.links?.length &&
              policyState.links.map((link, index) => (
                <Link
                  key={index}
                  kind={LinkKind.Internal}
                  variant={LinkVariant.NewDefault}
                  to={link.to}
                >
                  {link.text}
                </Link>
              ))}
            {policyState?.showSubmitButton &&
              (canSubmit ? (
                submitButton
              ) : (
                <Tooltip openAxis={OpenAxis.Y} triggerElement={submitButton}>
                  <Text kind={TextKind.TextSM} element={TextElement.P}>
                    You will be able to submit to retailer after paying your
                    invoice. If you need support, please email{' '}
                    <Link
                      kind={LinkKind.External}
                      href={`mailto:${supportEmail}`}
                    >
                      {supportEmail}
                    </Link>
                  </Text>
                </Tooltip>
              ))}
          </div>
        </div>
        {submissionStatus !== BrandPolicySubmissionStatus.Rejected && (
          <div className={styles.progress}>
            <Progress
              percentage={percentComplete || 0}
              loading={loading}
              size={ProgressSize.Lg}
              color={
                percentComplete === 100
                  ? Color.Green600.value
                  : Color.Purple600.value
              }
            />
          </div>
        )}
      </div>
      <ToastManager data={toastData} />
    </>
  );
};

export default NextSteps;
