import CSVFileValidator from 'csv-file-validator';

import {
  MAX_PRODUCTS,
  MAX_UNIQUE_ID_LENGTH,
  MAX_PRODUCT_NAME_LENGTH,
  lowerCaseValidFormatOptions,
  lowerCaseValidIntendedApplications,
  lowerCaseAdditionalProductDetailsOptions,
  fileTypeError,
  multipleFileError,
  noDataError,
  maxProductsError,
} from './constants';

export const isValidUniqueId = (uniqueId) => {
  // Max length: 60, min 1
  // Allowed: letters, numbers, dashes
  const isValidLength =
    uniqueId.length > 0 && uniqueId.length <= MAX_UNIQUE_ID_LENGTH;
  const regex = /^[a-zA-Z0-9-]+$/;
  const isValidString = regex.test(uniqueId);
  return isValidLength && isValidString;
};

export const isValidProductName = (productName) => {
  // Max length: 1000 characters
  // Min length: 1 character
  return (
    productName.length > 0 && productName.length <= MAX_PRODUCT_NAME_LENGTH
  );
};

export const isValidIntendedUse = (intendedUse) => {
  const input = intendedUse.toLowerCase().trim();
  return input === 'leave on' || input === 'rinse off';
};

export const isValidIntendedApplication = (intendedApplication) => {
  // Must select one or more
  const inputArray = intendedApplication
    .toLowerCase()
    .split(',')
    .map((item) => item.trim());
  return (
    Boolean(inputArray) &&
    inputArray.every((item) =>
      lowerCaseValidIntendedApplications.includes(item)
    )
  );
};

export const isValidProductFormat = (productFormat) => {
  // Must select one
  const input = productFormat.toLowerCase().trim();
  return lowerCaseValidFormatOptions.includes(input);
};

export const isValidAdditionalProductDetails = (additionalProductDetails) => {
  // Optional, can select one or more
  if (!additionalProductDetails) return true;
  const inputArray = additionalProductDetails
    .toLowerCase()
    .split(',')
    .map((item) => item.trim());
  return inputArray.every((item) =>
    lowerCaseAdditionalProductDetailsOptions.includes(item)
  );
};

export const isYesOrNo = (input) => {
  const trimmedInput = input.toLowerCase().trim();
  return trimmedInput === 'yes' || trimmedInput === 'no';
};

export const isValidFlavor = (intendedUse) => {
  return isYesOrNo(intendedUse);
};

export const isValidFragrance = (intendedUse) => {
  return isYesOrNo(intendedUse);
};

export const isValidIngredients = (ingredients) => {
  // Must be present and not empty whitespace.
  return Boolean(ingredients && ingredients.trim() !== '');
};

export const getProblematicData = (data, error) => {
  const { rowIndex, columnIndex } = error;
  // Row index is based on 1-based raw csv file with headers, data arg is scrubbed artifact only returning 0 based row data sans headers, thus off by 2.
  const row = data[rowIndex - 2];
  const headers = Object.keys(data[0]);
  const columnKey = headers[columnIndex - 1];
  if (!rowIndex || !columnIndex) {
    return null;
  }
  return { row, header: columnKey, value: row[columnKey] };
};

export const validateCSV = async (acceptedFiles, csvValidationConfig) => {
  let csvErrors = [];
  if (acceptedFiles.length === 0) {
    // Drag and drop error
    csvErrors.push([fileTypeError]);
    return csvErrors;
  }
  if (acceptedFiles.length > 1) {
    csvErrors.push([multipleFileError]);
    return csvErrors;
  }
  const file = acceptedFiles[0];
  if (file?.type !== 'text/csv') {
    csvErrors.push([fileTypeError]);
    return csvErrors;
  }
  await CSVFileValidator(file, csvValidationConfig).then((csvData) => {
    // Handle empty file error
    if (csvData.data?.length === 0 || !csvData.data) {
      csvErrors.push([noDataError]);
      return;
    }
    // Handle too many products error
    if (csvData.data?.length > MAX_PRODUCTS) {
      csvErrors.push([maxProductsError]);
      return;
    }
    if (csvData.inValidData.length && csvData.data.length) {
      for (const error of csvData.inValidData) {
        const rowNumber = error.rowIndex;
        // Handle header errors
        if (rowNumber === 1) {
          csvErrors.push([`CSV Header Format Error: ${error?.message}`]);
          return;
        }
        const problematicData = getProblematicData(csvData.data, error);
        // Handle untraced value error
        if (!problematicData) {
          csvErrors.push([`CSV ${error?.message}`]);
        }
        // Handle traced value error
        if (problematicData) {
          const formattedErrorData = [
            `Product: ${problematicData.row['Product Name']}`,
            `Column: ${problematicData.header}`,
            `Value: ${problematicData.value}`,
            `CSV ${error?.message}`,
          ];
          csvErrors.push(formattedErrorData);
        }
      }
    }
  });
  return csvErrors;
};
