import { useEffect, useState } from 'react';
import cn from 'classnames';

import {
  Button,
  ButtonKind,
  FileUpload,
  Text,
  TextElement,
  TextKind,
} from 'design-system/components';
import { Color } from 'design-system/data';

import {
  AttestationActionType,
  RequirementActionContentType,
} from 'views/Brands/RetailerBrandRequirementSummary/constants';

import styles from '../../cta-modals.module.scss';
import ScreeningStatusPill from 'components/ScreeningStatusPill';

function DocumentUpload({
  onChange,
  condition,
  triggerValidation,
  onValidate,
  initialFiles = [],
  isRetailer,
  submitRetailerResponse,
  canAttest,
  setHasChanged,
}) {
  const [files, setFiles] = useState(initialFiles);
  const [error, setError] = useState(null);
  const retailerResponse = condition?.retailerResponse;
  const attestationProofRetailerResponse = {
    notes: condition?.attestationProof?.retailerNotes,
    status: condition?.attestationProof?.status,
  };

  // setup responses for submit if already present.
  useEffect(() => {
    if (files?.length > 0) {
      onChange(
        condition?.conditionId,
        AttestationActionType.Documentation,
        {
          value: files,
          noteValue: null,
          conditionType: RequirementActionContentType.DocumentUpload,
        },
        condition?.policyRequirementId
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (triggerValidation) {
      const isValid = condition?.isRequired ? files?.length > 0 : true;
      setError(isValid ? null : 'Please upload a file');
      onValidate(condition?.conditionId, isValid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerValidation, condition?.isRequired]);

  useEffect(() => {
    // check if files length has changed
    if (files?.length !== initialFiles?.length) {
      setHasChanged();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files?.length, initialFiles?.length]);

  /// Retailer Specific Logic
  const isPendingRetailerAction = condition?.isPendingRetailerAction;
  const [retailerResponseInput, setRetailerResponseInput] = useState(
    retailerResponse?.notes || attestationProofRetailerResponse?.notes
  );
  const [retailerNoteError, setRetailerNoteError] = useState(null);

  const handleRetailerResponseSubmit = (isAccepted) => {
    if (!retailerResponseInput) {
      setRetailerNoteError('You must enter a note.');
    } else {
      setRetailerNoteError(null);
      submitRetailerResponse.call(
        condition?.conditionId,
        condition?.attestationProof?.id,
        isAccepted,
        retailerResponseInput
      );
    }
  };

  const downloadFile = (file) => {
    fetch(file.path)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file.name);
        document.body.appendChild(link);
        link.click();
        // Cleanup
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error('Error downloading file:', error);
      });
  };

  return (
    <div>
      <Text
        kind={TextKind.TextSMBold}
        element={TextElement.P}
        className={styles['section-header-text']}
      >
        File Upload
      </Text>
      <FileUpload
        onChange={(file) => {
          if (isRetailer || !canAttest) {
            return;
          }
          setHasChanged();
          setFiles([file]);
          onChange(
            condition?.conditionId,
            AttestationActionType.Documentation,
            {
              value: [file],
              noteValue: null,
              conditionType: RequirementActionContentType.DocumentUpload,
            },
            condition?.policyRequirementId
          );
        }}
        disableFileUpdates={isRetailer || !canAttest}
        files={files}
        setFiles={setFiles}
        onClick={(file) => downloadFile(file)}
      />
      {error && (
        <Text
          kind={TextKind.TextSM}
          element={TextElement.P}
          color={Color.Red600}
        >
          {error}
        </Text>
      )}
      {/* Retailer Response Section */}
      {(isRetailer ||
        retailerResponse?.notes ||
        attestationProofRetailerResponse?.notes) && (
        <div
          className={cn([styles['notes-section'], styles['retailer-response']])}
        >
          <Text
            kind={TextKind.TextSMBold}
            element={TextElement.P}
            className={styles['section-header-text']}
          >
            Retailer Response{' '}
            {isRetailer && isPendingRetailerAction && '(Required)'}{' '}
            {(retailerResponse?.status ||
              attestationProofRetailerResponse?.status) && (
              <ScreeningStatusPill
                status={
                  retailerResponse?.status ||
                  attestationProofRetailerResponse?.status
                }
              />
            )}
          </Text>
          <textarea
            rows={5}
            className={styles['textarea']}
            placeholder="Retailer response..."
            value={retailerResponseInput}
            readOnly={!isRetailer || !isPendingRetailerAction}
            onChange={(e) => setRetailerResponseInput(e.target.value)}
          />
          {retailerNoteError && (
            <Text
              kind={TextKind.TextSM}
              element={TextElement.P}
              color={Color.Red600}
            >
              Note is required
            </Text>
          )}
          {isRetailer && !isPendingRetailerAction && (
            <Text
              kind={TextKind.TextSM}
              element={TextElement.P}
              color={Color.Gray600}
            >
              No retailer response needed at this time.
            </Text>
          )}
          {isRetailer && isPendingRetailerAction && (
            <div className={styles['action-buttons']}>
              <Button
                kind={ButtonKind.Error}
                onClick={() => handleRetailerResponseSubmit(false)}
                disabled={submitRetailerResponse.loading}
              >
                {submitRetailerResponse.loading ? 'Submitting…' : 'Reject'}
              </Button>
              <Button
                kind={ButtonKind.Success}
                onClick={() => handleRetailerResponseSubmit(true)}
                disabled={submitRetailerResponse.loading}
              >
                {submitRetailerResponse.loading ? 'Submitting…' : 'Accept'}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default DocumentUpload;
