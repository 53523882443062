import propTypes from 'prop-types';
import {
  Avatar,
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Link,
  LinkKind,
} from 'design-system/components';
import Tooltip from './tooltip';
import { determineInitials, sortDirection } from 'design-system/utils';
import styles from '../status.module.scss';
import { PLACEHOLDER } from 'design-system/data';
import { useParams } from 'react-router';
import { BRAND_PATH } from 'constants/index';
import { PolicyStatusPill } from './status-pills';

const tableHeaders = [
  {
    text: 'SKU',
    value: 'extracted_sku_id',
    tooltipMessage: null,
    sortable: true,
  },
  {
    text: 'Product Name',
    value: 'product_name',
    tooltipMessage: null,
    sortable: false,
  },
  {
    text: 'Pillar',
    value: 'pillar',
    tooltipMessage: 'Indicates status for SKU-level pillars',
    sortable: false,
  },
];

function SkuTable({ data, sortClick, tableState }) {
  const { brand_id } = useParams();
  return (
    <div className={styles['sku-table']}>
      <Table>
        <TableHead>
          <TableRow>
            {tableHeaders.map(({ text, tooltipMessage, sortable, value }) => (
              <TableHeadCell
                key={value}
                enableSort={sortable}
                onClick={sortClick}
                direction={sortDirection(tableState, value)}
              >
                <div className={styles['table-head-cell']}>
                  {text}
                  {tooltipMessage && (
                    <Tooltip tooltipMessage={tooltipMessage} />
                  )}
                </div>
              </TableHeadCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.consumerProducts?.map((product, index) => {
            const productUrl = `${BRAND_PATH}/${brand_id}/product/${product.id}/ingredients`;
            return (
              <TableRow key={`${product.id}-${index}`}>
                <TableBodyCell>
                  <div>{product.skuId || PLACEHOLDER}</div>
                </TableBodyCell>
                <TableBodyCell>
                  <Link kind={LinkKind.Internal} to={productUrl}>
                    {product.name}
                  </Link>
                </TableBodyCell>
                <TableBodyCell>
                  <div className={styles['policy-cell']}>
                    {product.formulationPolicies.map((policy) => (
                      <div className={styles['policy']} key={policy.id}>
                        <div className={styles['policy-avatar']}>
                          <Avatar
                            initials={determineInitials(policy.name)}
                            src={policy.imagePath}
                          />
                          {policy.name}
                        </div>
                        <PolicyStatusPill status={policy.status} />
                      </div>
                    ))}
                  </div>
                </TableBodyCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

SkuTable.propTypes = {
  data: propTypes.shape({
    currentPage: propTypes.number,
    perPage: propTypes.number,
    status: propTypes.string,
    totalEntries: propTypes.number,
    consumerProducts: propTypes.arrayOf(
      propTypes.shape({
        id: propTypes.number,
        name: propTypes.string,
        skuId: propTypes.number,
        formulationPolicies: propTypes.arrayOf(
          propTypes.shape({
            id: propTypes.number,
            name: propTypes.string,
            status: propTypes.string,
            imagePath: propTypes.string,
          })
        ),
      })
    ),
  }),
};

export default SkuTable;
