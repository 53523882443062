import { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import NotchedCircle from './NotchedCircle.js';
import styles from './phosphor-icon.module.scss';
import { PhosphorIconWeight } from './constants.js';

// Load icon as needed rather than load entire library.
const loadIcon = async (iconName) => {
  const module = await import('@phosphor-icons/react');
  let name = iconName;
  if (module[iconName] === undefined) {
    console.error(`Icon ${iconName} not found in @phosphor-icons/react`);
    name = 'CircleDashed';
  }
  return { default: module[name] };
};

const PhosphorIcon = ({ iconName, size, ...props }) => {
  const LazyIcon = lazy(() => loadIcon(iconName));
  return (
    <Suspense
      fallback={
        <span className={styles.loading}>
          <NotchedCircle size={size} />
        </span>
      }
    >
      {LazyIcon && <LazyIcon {...props} size={size} />}
    </Suspense>
  );
};

PhosphorIcon.propTypes = {
  iconName: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  color: PropTypes.string,
  weight: PropTypes.oneOf(Object.values(PhosphorIconWeight)),
  title: PropTypes.string,
  style: PropTypes.object,
};

PhosphorIcon.defaultProps = {
  size: 18,
};

export default PhosphorIcon;
