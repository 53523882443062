import {
  // UserList,
  CloudinaryImg,
  Link,
  LinkKind,
  LinkVariant,
  PhosphorIcon,
  Text,
  TextKind,
  TextElement,
} from 'design-system/components';

import { PATHS } from 'constants/index';
import { PolicyEligibilityCardProps } from './types';
import styles from './policy-eligibility-selection.module.scss';

const PolicyEligibilityCard = ({ policy }: PolicyEligibilityCardProps) => {
  // Pending new tickets requirements.
  // const userListInfo = policy.retailerPolicyRequirementGroups.map((pillar) => {
  //   return { name: pillar.name, imagePath: pillar.imagePath };
  // });

  return (
    <div className={styles['policy-card']}>
      {policy.imagePath && (
        <div className={styles['policy-icon']}>
          <CloudinaryImg
            src={policy.imagePath}
            alt={policy.name}
            width={64}
            height={64}
            fallbackElement={<PhosphorIcon iconName="ShieldCheck" size={48} />}
          />
        </div>
      )}
      <div className={styles['policy-content']}>
        <div className={styles['policy-header']}>
          <Text kind={TextKind.Display2XSMedium} element={TextElement.H2}>
            {policy.name}
          </Text>
        </div>
        {/* Pending new tickets requirements. */}
        {/* {policy?.description && (
          <Text kind={TextKind.TextSM} element={TextElement.P}>
            {policy.description}
          </Text>
        )} */}
        {/* {userListInfo.length > 0 && (
          <div className={styles['policy-pillars']}>
            <Text kind={TextKind.TextSMSemibold} element={TextElement.P}>
              Eligible for{' '}
            </Text>
            <UserList users={userListInfo} title="Pillars" />
          </div>
        )} */}
      </div>
      <div className={styles['policy-cta']}>
        <Link
          kind={LinkKind.Internal}
          variant={LinkVariant.Primary}
          to={PATHS.retailerBrandSKULevelStatus.replace(
            ':policy_id',
            policy.id.toString()
          )}
        >
          <span className={styles.cta}>
            Review
            <span className={styles['icon-container']}>
              <PhosphorIcon iconName="ArrowRight" size={20} />
            </span>
          </span>
        </Link>
      </div>
    </div>
  );
};

export default PolicyEligibilityCard;
