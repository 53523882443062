import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { PolicyRequirementType } from 'design-system/data';

import { useApp } from 'context/AppContext';
import SlideoutModal from 'views/Brands/RetailerBrandPolicyRequirements/components/SlideoutModal';
import Disclaimer from 'views/Brands/shared/Disclaimer';
import { useRequirementActions } from 'views/Brands/shared/RequirementActionModalContents/context';
import { tabStatuses } from './data/constants';
import Header from './components/Header';
import TabsAndTable from './components/TabsAndTable';
import BrandRequirementNote from './components/BrandRequirementNote';
import useSKURequirements from './hooks/useSKURequirements';

const SKURequirementsPageView = () => {
  const { policy_id, product_id } = useParams();
  const { retailerBrand, user } = useApp();
  const retailerId = retailerBrand?.retailer?.id;
  const brandId = user?.organization?.in_orgable_id;
  const [currentRow, setCurrentRow] = useState(null);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const response = useSKURequirements(
    retailerId,
    brandId,
    policy_id,
    product_id,
    tabStatuses[activeTabIndex]
  );

  const [previousActive, setPreviousActive] = useState(false);
  const [nextActive, setNextActive] = useState(false);

  const {
    setModalOpen,
    modalOpen,
    setSelectedRequirement,
    setCurrentProductId,
    selectedRequirement,
  } = useRequirementActions();

  useEffect(() => {
    // selectedRequirement is un-nullable, so setting current product id to null on mount instead to prevent actions api from being called.
    // prioritizing keeping context logic more stable.
    (setCurrentProductId as Dispatch<SetStateAction<any>>)(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentFilteredData = response?.data?.requirements;
  const modalNavNextLogic = (i: number): void => {
    setNextActive(i < currentFilteredData?.length - 1);
  };

  const modalNavPrevLogic = (i: number): void => {
    setPreviousActive(i > 0);
  };

  return (
    <main>
      <SlideoutModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        currentRow={currentRow}
        setCurrentRow={setCurrentRow}
        currentFilteredData={currentFilteredData}
        modalNavPrevLogic={modalNavPrevLogic}
        modalNavNextLogic={modalNavNextLogic}
        previousActive={previousActive}
        nextActive={nextActive}
        totalItemCount={currentFilteredData?.length}
        expectedRequirementType={undefined}
        hideSubmit={
          (selectedRequirement as any)?.requirementType ===
          PolicyRequirementType.Brand
        }
        hideSubmitNote={<BrandRequirementNote />}
      />
      <Header
        policyName={response?.data?.retailerPolicyName}
        policyId={policy_id}
        skuName={response?.data?.consumerProductName}
        status={response?.data?.screeningStatus}
        pillars={response?.data?.pillars}
      />
      <TabsAndTable
        response={response}
        setSelectedRequirement={setSelectedRequirement}
        setModalOpen={setModalOpen}
        setCurrentRow={setCurrentRow}
        setCurrentProductId={setCurrentProductId}
        modalNavPrevLogic={modalNavPrevLogic}
        modalNavNextLogic={modalNavNextLogic}
        currentRow={currentRow}
        modalOpen={modalOpen}
        activeTabIndex={activeTabIndex}
        setActiveTabIndex={setActiveTabIndex}
      />
      {retailerBrand?.retailer?.disclaimer && (
        <Disclaimer text={retailerBrand?.retailer?.disclaimer || ''} />
      )}
    </main>
  );
};

export default SKURequirementsPageView;
