import PropTypes from 'prop-types';
import cn from 'classnames';
import { BannerKind, BannerSize } from './constants';
import styles from './banner.module.scss';

function Banner(props) {
  return (
    <div className={cn(styles.root, styles[props.kind], styles[props.size])}>
      <div className={styles.container}>{props.children}</div>
    </div>
  );
}

Banner.propTypes = {
  kind: PropTypes.oneOf(Object.values(BannerKind)),
  size: PropTypes.oneOf(Object.values(BannerSize)),
  children: PropTypes.node.isRequired,
};

Banner.defaultProps = {
  kind: BannerKind.Default,
  size: BannerSize.Small,
};

export default Banner;
