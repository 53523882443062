import PropTypes from 'prop-types';
import styled from 'styled-components';
import { layout, flexbox, border, compose, typography } from 'styled-system';
import Icon from 'components/Icon';

const BaseInput = styled('input')`
  border: 1px solid #ececf2;
  background: white;
  font-family: var(--font-sans-serif);
  padding: 10px 16px;
  outline: none;
  border-radius: 4px;
  &:focus {
    outline: none;
    box-shadow: 0 0 1px 1px var(--brand-color);
    background-color: hsla(
      var(--brand-color-h),
      var(--brand-color-s),
      var(--brand-color-l),
      0.05
    );
    border-color: transparent;
  }
  &:hover {
    border-color: #444444;
  }
  &:hover:focus {
    border-color: transparent;
  }
  ${compose(layout, flexbox, border, typography)}
`;

function ClearableInput(props) {
  const {
    className,
    children,
    onClear,
    Input,
    Button,
    CloseIcon,
    propsInput,
    ...rest
  } = props;
  return (
    <Container {...rest}>
      <BaseInput
        className={className}
        width="100%"
        height="100%"
        {...propsInput}
      />
      <Button type="button" onClick={onClear}>
        <CloseIcon />
      </Button>
    </Container>
  );
}

const Container = styled('div')`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  ${compose(layout, flexbox, border)}
`;

const ClearButton = styled('button')`
  cursor: pointer;
  position: absolute;
  background: none;
  border: none;
  right: 1em;
  display: flex;
`;

export { ClearableInput };
export default BaseInput;

ClearableInput.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onClear: PropTypes.func,
  Input: PropTypes.elementType,
  Button: PropTypes.elementType,
  CloseIcon: PropTypes.elementType,
};

ClearableInput.defaultProps = {
  className: null,
  children: null,
  onClear: () => null,
  Input: BaseInput,
  Button: ClearButton,
  CloseIcon: (props) => <Icon name="Close" size={16} {...props} />,
};
