import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  Icon,
  IconName,
  Text,
  TextKind,
  TextElement,
  IconDirection,
} from 'design-system/components';
import { useClickoff } from 'design-system/utils/hooks';
import { handleKeyboardEvent, KeyboardCode } from 'design-system/utils';
import { Color, PLACEHOLDER } from 'design-system/data';

import { SelectKind } from './constants';
import styles from './select.module.scss';
import Option from '../../atoms/option';

function Select({
  children,
  kind,
  value,
  options,
  onChange,
  disabled,
  hasError,
  errorMessage,
  id,
  placeholder,
  disableCloseOnClick,
  enableSearch,
  onSearchInput,
  searchPlaceholder,
  compactVersion,
}) {
  const defaultOption = { display: PLACEHOLDER, value: PLACEHOLDER };
  const computedKind = kind ? kind : SelectKind.Pill;
  const selectRef = useRef(null);
  const searchInputRef = useRef(null);
  const [showSelectOptions, setShowSelectOptions] = useState(false);
  const handleCloseOptions = () => {
    if (!showSelectOptions) return;
    setShowSelectOptions(!showSelectOptions);
  };
  useClickoff(selectRef, handleCloseOptions);

  const handleKeyboardEvents = (event) => {
    if (disabled) return;
    if (!showSelectOptions) {
      handleKeyboardEvent(event, [KeyboardCode.Space, KeyboardCode.Enter], () =>
        setShowSelectOptions(true)
      );
    }
    if (showSelectOptions) {
      handleKeyboardEvent(
        event,
        [KeyboardCode.Escape, KeyboardCode.Space],
        () => setShowSelectOptions(false)
      );
    }
  };

  return (
    <>
      <div
        id={id}
        role="listbox"
        className={cn([
          styles[`root-${computedKind}`],
          hasError ? styles.error : '',
          disabled ? styles.disabled : '',
          compactVersion ? styles.compact : '',
        ])}
        onClick={() => {
          if (disabled) {
            return;
          }
          if (!showSelectOptions || !disableCloseOnClick) {
            setShowSelectOptions(!showSelectOptions);
          }
          if (enableSearch) {
            searchInputRef.current.focus();
          }
        }}
        onKeyDown={handleKeyboardEvents}
        tabIndex={disabled ? -1 : 0}
        ref={selectRef}
        data-cy="select-input"
      >
        <div className={styles.placeholder}>
          {(!enableSearch || placeholder) && (
            <Text kind={TextKind.TextMDBold} element={TextElement.Span}>
              {placeholder && !value
                ? placeholder || ''
                : options?.find((option) => option.value === value)?.display ||
                  value ||
                  defaultOption.display}
            </Text>
          )}
          {enableSearch && (
            <input
              ref={searchInputRef}
              className={styles.search}
              placeholder={searchPlaceholder}
              onKeyDown={onSearchInput}
              onChange={onSearchInput}
            />
          )}
        </div>
        {showSelectOptions && (
          <div
            className={styles['options-container']}
            data-testid="options-list-container"
          >
            {children
              ? children
              : options.map((option) => (
                  <Option
                    key={option.value}
                    ariaSelected={option.value === value}
                    onClick={() => onChange(option.value)}
                    dataCy="select-input-option"
                  >
                    <Text kind={TextKind.TextMD} element={TextElement.Span}>
                      {option.display}
                    </Text>
                  </Option>
                ))}
          </div>
        )}
        <Icon
          name={IconName.Chevron}
          size={12}
          direction={showSelectOptions ? IconDirection.Up : IconDirection.Down}
        />
      </div>
      {hasError && (
        <div
          className={cn([styles.error, styles['error-message']])}
          role="alert"
          id={`error`}
          aria-live="polite"
        >
          <Icon name={IconName.Alert} size={16} color={Color.Orange800} />
          <Text
            kind={TextKind.TextSM}
            element={TextElement.Span}
            color={Color.Orange800}
          >
            {errorMessage}
          </Text>
        </div>
      )}
    </>
  );
}

Select.propTypes = {
  kind: PropTypes.oneOf(Object.values(SelectKind)),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      display: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  children: PropTypes.node,
  id: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disableCloseOnClick: PropTypes.bool,
  enableSearch: PropTypes.bool,
  onSearchInput: PropTypes.func,
  searchPlaceholder: PropTypes.string,
  compactVersion: PropTypes.bool,
};

export default Select;
