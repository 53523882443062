import { useContext, createContext, useMemo } from 'react';
import { useApp } from 'context/AppContext';
import useQueryParams from 'hooks/useQueryParams';
import { convertSnakeToCamelCase } from 'design-system/utils';
import { UrlParam } from 'design-system/data';

export function RetailerBrandsProvider(props) {
  const { children } = props;
  const { useApi, user } = useApp();

  const params = useQueryParams(Object.values(UrlParam));
  const brandIDs = useQueryParams(UrlParam.ConsumerBrandIds);

  const retailerID = user.organization.in_orgable_id;

  const buildParams = (searchParams) => {
    let params = { ...searchParams._asValues() };
    if (brandIDs.value) {
      params = { ...params, consumer_brand_ids: brandIDs };
    }
    return params;
  };

  const { data, loading, error } = useApi(
    `/api/v4/retailers/${retailerID}/brands`,
    {
      enabled: !!retailerID,
      param: {
        ...buildParams(params),
      },
    }
  );

  const brandData = useMemo(() => {
    return loading || error ? data : convertSnakeToCamelCase(data);
  }, [data, loading, error]);

  const context = useMemo(
    () => ({
      data: brandData,
      loading,
      error,
      retailerID,
    }),
    [brandData, loading, error, retailerID]
  );

  return (
    <RetailerBrandsContext.Provider value={context}>
      {children}
    </RetailerBrandsContext.Provider>
  );
}

const RetailerBrandsContext = createContext(null);
export const useRetailerBrandsContext = () => {
  const ctx = useContext(RetailerBrandsContext);
  if (!ctx) {
    throw new Error('Must be used with a RetailerBrandsProvider');
  }
  return ctx;
};
