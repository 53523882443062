import { BrandViewHeader, NoContent } from 'design-system/components';
import { convertSnakeToCamelCase } from 'design-system/utils';
import { useApp } from 'context/AppContext';
import PolicyEligibilityCard from './PolicyEligibilityCard';
import { PolicyProps } from './types';
import Disclaimer from 'views/Brands/shared/Disclaimer';

const PolicySelectionPage = () => {
  const breadcrumbItems = [
    {
      text: 'Eligibility',
      link: '',
    },
  ];
  const { useApi, user, retailerBrand } = useApp();
  const retailer = retailerBrand?.retailer;
  const brandId = user?.organization?.in_orgable_id;

  const url = `/api/v4/retailers/${retailer?.id}/consumer_brands/${brandId}/policies`;

  const { data, loading, error } = useApi(url, {
    enabled: !!retailer?.id && !!brandId,
  });

  const policyData = convertSnakeToCamelCase(data);
  const hasContent = policyData?.policies?.length > 0;

  return (
    <main>
      <BrandViewHeader
        title="Your eligibility"
        subTitle={`View your eligibility status for ${
          (retailer as any)?.name
        } programs here.`}
        showExportButton={false}
        showFilterButton={false}
        breadcrumbsItems={breadcrumbItems}
      />
      {loading && <NoContent loading />}
      {!loading && (error || !hasContent) && (
        <NoContent>
          {error &&
            'An error occurred while fetching eligibility data. Please try again later or reach out to support.'}
          {!hasContent &&
            !error &&
            'No eligibility data found at this time. Please try again later or reach out to support.'}
        </NoContent>
      )}
      {hasContent &&
        policyData.policies.map((policy: PolicyProps) => (
          <PolicyEligibilityCard key={policy.id} policy={policy} />
        ))}
      {retailerBrand?.retailer?.disclaimer && (
        <Disclaimer text={retailer?.disclaimer || ''} />
      )}
    </main>
  );
};

export default PolicySelectionPage;
