import { useContext, createContext, useMemo } from 'react';
import { convertSnakeToCamelCase } from 'design-system/utils';
import { useApp } from 'context/AppContext';
import useAsyncCall from 'services/api/useAsyncCall';
import fetcher from 'services/api/fetcher';

const CertificationContext = createContext(null);

export function YourStatusProvider({ children }) {
  const { user, useApi, selectedRetailerId, hasRetailerBrandResponseFinished } =
    useApp();
  const brandID = useMemo(
    () => user?.organization?.in_orgable_id,
    [user?.organization?.in_orgable_id]
  );

  const policyEnrollmentStatuses = useApi(
    `/api/v4/consumer_brands/${brandID}/policy_enrollment_statuses`,
    {
      enabled: Boolean(brandID && hasRetailerBrandResponseFinished),
      param: { retailer_id: selectedRetailerId },
    }
  );

  const fetchBrandPillars = useAsyncCall(async (brandId, retailerId, cb) => {
    const PILLARS_ENDPOINT = `/api/v4/consumer_brands/${brandId}/formulation_policies`;
    const search = new URLSearchParams({
      ...(Boolean(retailerId) && { retailer_id: retailerId }),
    });
    const url = `${PILLARS_ENDPOINT}?${search.toString()}`;
    const pillars = await fetcher(url, {
      enabled: Boolean(brandId),
      preventAbort: true,
    });

    return cb(
      convertSnakeToCamelCase(pillars).sort((a, b) => (a.ordering = b.ordering))
    );
  });

  const fetchSkuTableDataByStatus = useAsyncCall(
    async (brandID, retailerId, params, cb) => {
      const SKU_ENDPOINT = `/api/v4/consumer_brands/${brandID}/sku_pillar_statuses`;
      const search = new URLSearchParams({
        ...params,
        ...(Boolean(retailerId) && { retailer_id: retailerId }),
      });
      const url = `${SKU_ENDPOINT}?${search.toString()}`;
      const result = await fetcher(url, {
        enabled: Boolean(brandID),
        preventAbort: true,
      });

      return cb(convertSnakeToCamelCase(result));
    }
  );

  const context = useMemo(
    () => ({
      policyEnrollmentStatuses: convertSnakeToCamelCase(
        policyEnrollmentStatuses
      ),
      brandID,
      fetchBrandPillars,
      fetchSkuTableDataByStatus,
    }),
    [
      policyEnrollmentStatuses,
      brandID,
      fetchSkuTableDataByStatus,
      fetchBrandPillars,
    ]
  );

  return (
    <CertificationContext.Provider value={context}>
      {children}
    </CertificationContext.Provider>
  );
}

export function useYourStatusContext() {
  const ctx = useContext(CertificationContext);
  if (!ctx) {
    throw new Error('Must be used with a YourStatusProvider');
  }
  return ctx;
}
