import { useEffect, useState, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { isEmpty, isEqual } from 'lodash';
import {
  Text,
  TextKind,
  TextElement,
  NoData,
  BrandViewContentSection,
  Pagination,
  FilterModal,
  buildFilterModalState,
  TabsAndFilterHeader,
  FilterModalTypes,
} from 'design-system/components';
import {
  TableReducerAction,
  useTableSort,
  useFilters,
} from 'design-system/utils';
import { UrlParam } from 'design-system/data';

import { useApp } from 'context/AppContext';
import { isSelectedRetailerInLegacyPortal } from '../PortalSelection/util';
import { useYourStatusContext } from './context';
import styles from './status.module.scss';
import PillarTable from './components/pillar-table';
import SkuTable from './components/sku-table';
import {
  getActiveTabIndex,
  buildFilters,
  setUrlSearchParameters,
  encodeIds,
  buildSearchParamsObject,
  handleFetchProductOptionsByName,
  handleFetchProductOptionsBySku,
  isSkuSearch,
} from './utils';

const filterModalState = buildFilterModalState([
  { value: 'pillars', isDynamic: false },
  {
    value: 'products',
    isDynamic: true,
    toggleOptions: ['Name', 'SKU'],
  },
]);

function YourStatusView() {
  const { selectedRetailerId, hasRetailerBrandResponseFinished, user } =
    useApp();
  const isSelectedRetailerInLegacy = isSelectedRetailerInLegacyPortal(
    selectedRetailerId,
    user
  );
  const filteredRetailerId = useMemo(
    () => (isSelectedRetailerInLegacy ? selectedRetailerId : null),
    [isSelectedRetailerInLegacy, selectedRetailerId]
  );

  const location = useLocation();
  const history = useHistory();
  const {
    policyEnrollmentStatuses,
    fetchBrandPillars,
    fetchSkuTableDataByStatus,
    brandID,
  } = useYourStatusContext();

  const params = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  const [status, setStatus] = useState(params.get(UrlParam.Status) || 'all');
  const [searchResults, setSearchResults] = useState(filterModalState);
  const [filterState, filterStateDispatch] = useFilters();

  const filters = useMemo(() => {
    return searchResults ? buildFilters(searchResults) : [];
  }, [searchResults]);

  const [tableState, dispatch] = useTableSort({
    initialSortByValue: 'extracted_sku_id',
  });

  const initialPageParams = {
    items: params.get(UrlParam.Items) || 10,
    page: params.get(UrlParam.Page) || 1,
    direction: 'asc',
    status: 'all',
  };

  const [skuParams, setSkuParams] = useState(initialPageParams);
  const [pillarData, setPillarData] = useState(null);

  useEffect(() => {
    if (hasRetailerBrandResponseFinished) {
      fetchBrandPillars.call(brandID, filteredRetailerId, setPillarData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandID, filteredRetailerId, hasRetailerBrandResponseFinished]);
  const [areInitialParamsSet, setInitialParams] = useState(false);
  const [skuData, setSkuData] = useState(null);
  useEffect(() => {
    const fetchSkuData = async () => {
      await fetchSkuTableDataByStatus.call(
        brandID,
        filteredRetailerId,
        skuParams,
        setSkuData
      );
    };
    if (areInitialParamsSet && hasRetailerBrandResponseFinished) {
      fetchSkuData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filteredRetailerId,
    skuParams,
    brandID,
    hasRetailerBrandResponseFinished,
    areInitialParamsSet,
  ]);

  useEffect(() => {
    setSearchResults((prevState) => {
      return {
        ...prevState,
        pillars: {
          results: pillarData,
        },
      };
    });
    if (!areInitialParamsSet) {
      const { searchParamsObject } = buildSearchParamsObject(params);
      setSkuParams((prev) => {
        return {
          ...prev,
          ...searchParamsObject,
        };
      });
      setInitialParams(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandID, filteredRetailerId, pillarData]);

  const isPillarDataAvailable = !isEmpty(policyEnrollmentStatuses?.data);
  const showSkuData = !!skuData?.consumerProducts?.length;

  const skuTabData = useMemo(
    () => [
      {
        label: 'All',
        value: 'all',
        quantity: skuData?.screeningStatusCounts?.total || null,
      },
      {
        label: 'Needs attention',
        value: 'needs_information',
        quantity: skuData?.screeningStatusCounts?.needsInformation || null,
      },
      {
        label: 'Meets',
        value: 'pass',
        quantity: skuData?.screeningStatusCounts?.pass || null,
      },
      {
        label: 'Does not meet',
        value: 'fail',
        quantity: skuData?.screeningStatusCounts?.fail || null,
      },
      {
        label: 'Updating',
        value: 'updating',
        quantity: skuData?.screeningStatusCounts?.updating || null,
      },
    ],
    [skuData]
  );

  const activeTabIndex = getActiveTabIndex(skuTabData);

  useEffect(() => {
    setUrlSearchParameters(
      tableState,
      skuParams.page,
      skuParams.items,
      status,
      history,
      filterState
    );
  }, [
    filterState,
    tableState,
    skuParams.items,
    skuParams.page,
    history,
    status,
  ]);

  const handleSetActiveTab = (status) => {
    setSkuParams((prev) => {
      return {
        ...prev,
        page: 1,
      };
    });
    setStatus(status);
  };

  const handleFetch = (value, filterValue, toggleValue) => {
    setSearchResults((prevState) => ({
      ...prevState,
      [filterValue]: {
        ...prevState[filterValue],
        isLoading: true,
        results: [],
      },
    }));
    if (toggleValue === 'Name') {
      return handleFetchProductOptionsByName(
        value,
        brandID,
        filteredRetailerId,
        setSearchResults
      );
    }
    return handleFetchProductOptionsBySku(
      value,
      brandID,
      filteredRetailerId,
      setSearchResults
    );
  };

  const handleModalClose = async (newFilterState) => {
    if (newFilterState) {
      filterStateDispatch(newFilterState);
      if (!isEqual(filterState, newFilterState?.filters)) {
        if (!newFilterState?.filters) {
          setSkuParams(initialPageParams);
        }
        const newParams = {};
        const entries = newFilterState?.filters
          ? Object.entries(newFilterState?.filters)
          : [];
        if (entries?.length) {
          entries?.forEach((entry) => {
            const skuSearch = isSkuSearch(entry[1]);
            if (entry[0] === 'pillars') {
              return (newParams[UrlParam.FormulationPolicyIds] = encodeIds(
                entry[1]
              ));
            }
            if (skuSearch) {
              return (newParams[UrlParam.SkuId] = encodeIds(entry[1]));
            }
            return (newParams[UrlParam.ConsumerProductIds] = encodeIds(
              entry[1]
            ));
          });
        }
        setSkuParams((prev) => {
          return {
            ...prev,
            ...newParams,
          };
        });
      }
    }
  };

  return (
    <div className={styles.container}>
      <Text kind={TextKind.DisplaySMBold} element={TextElement.H1}>
        Your Status
      </Text>
      <BrandViewContentSection
        header="Pillar-level view"
        subheader="This view summarizes your pillar participation and compliance within Conscious Beauty at Ulta Beauty®"
        tabData={[]}
        tabContents={
          isPillarDataAvailable ? (
            <PillarTable data={policyEnrollmentStatuses?.data} />
          ) : (
            <NoData
              isLoading={policyEnrollmentStatuses?.loading}
              hasErrorOccurred={!!policyEnrollmentStatuses?.error}
              noContentMessage="Stay tuned, we're under construction!"
            />
          )
        }
      />
      <>
        <TabsAndFilterHeader
          title="SKU-level view"
          subtitle="Check the Clean Ingredient and Vegan compliance status of your SKUs here"
          tabData={skuTabData}
          activeTabIndex={activeTabIndex}
          onTabClick={(activeStatus) => {
            handleSetActiveTab(activeStatus);
            setSkuParams((prev) => {
              return {
                ...prev,
                status: activeStatus,
              };
            });
          }}
        >
          <FilterModal
            filters={filters}
            state={filterState}
            onModalClose={(data) => handleModalClose(data)}
            dataCyRoot="sku-level-filter"
            onFetch={handleFetch}
            type={FilterModalTypes.Search}
            searchResults={searchResults}
          />
        </TabsAndFilterHeader>
        {showSkuData ? (
          <>
            <SkuTable
              data={skuData}
              tableState={tableState}
              sortClick={() => {
                dispatch({
                  type: TableReducerAction.LabelClick,
                  sortBy: 'extracted_sku_id',
                });
                setSkuParams((prev) => {
                  return {
                    ...prev,
                    direction: skuParams.direction === 'asc' ? 'desc' : 'asc',
                  };
                });
              }}
            />
            <Pagination
              itemsPerPage={Number(skuParams.items)}
              currentPage={Number(skuParams.page)}
              totalItemCount={skuData?.screeningStatusCounts?.total}
              onNextPage={() => {
                const newPage = skuData?.currentPage + 1;
                setSkuParams((prev) => {
                  return {
                    ...prev,
                    page: newPage,
                  };
                });
              }}
              onPreviousPage={() => {
                const newPage = skuData?.currentPage - 1;
                setSkuParams((prev) => {
                  return {
                    ...prev,
                    page: newPage,
                  };
                });
              }}
              onPageSelect={(page) => {
                setSkuParams((prev) => {
                  return { ...prev, page };
                });
              }}
              onChangePageSize={(items) => {
                setSkuParams((prev) => {
                  return { ...prev, items };
                });
              }}
            />
          </>
        ) : (
          <NoData
            isLoading={
              fetchSkuTableDataByStatus?.loading ||
              !hasRetailerBrandResponseFinished
            }
            noContentMessage="There are no products to review"
          />
        )}
      </>
    </div>
  );
}

export default YourStatusView;
