import YourProductsTable from './components/your-products-table';
import { useYourProductContext } from './context';
import styles from './your-products.module.scss';
import { SkuEmptyState } from './components/SkuEmptyState';
import { useApp } from 'context/AppContext';
import { isSelectedRetailerInLegacyPortal } from '../PortalSelection/util';
import { TablePageHeader } from './components/TablePageHeader';

function YourProductsContainer() {
  const { products, tableCounts, loading, error } = useYourProductContext();
  const { user, selectedRetailerId } = useApp();

  const isSelectedRetailerInLegacy = isSelectedRetailerInLegacyPortal(
    selectedRetailerId,
    user
  );

  return (
    <div className={styles.root}>
      <TablePageHeader isEmpty={tableCounts?.all < 1} />
      {user.hasFF('upload_skus') &&
      tableCounts?.all < 1 &&
      !isSelectedRetailerInLegacy ? (
        <SkuEmptyState />
      ) : (
        <YourProductsTable
          productsData={products}
          loading={loading}
          error={error}
        />
      )}
    </div>
  );
}

export default YourProductsContainer;
