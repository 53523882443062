import YourProductsContainer from './YourProductsContainer';
import { CreateProductProvider } from './context/CreateProductContext';

function YourProducts() {
  return (
    <CreateProductProvider>
      <YourProductsContainer />
    </CreateProductProvider>
  );
}

export default YourProducts;
